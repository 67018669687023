import { Injectable } from '@angular/core';
import { selectGeneralState } from '../../_state/general/general.selectors';
import { GeneralState } from '../../_state/app.state';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/_services';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private generalState: GeneralState;

	constructor(private store: Store, private authService: AuthenticationService) {}

	public async submitCWRequest(method: 'POST' | 'GET', path: string, data: Record<string, unknown>) {
		if (!this.generalState) {
			this.store
				.select(selectGeneralState)
				.pipe(take(1))
				.subscribe(generalState => (this.generalState = generalState));
		}

		const { cloudWorkersUrl, cloudWorkersHeaders } = this.generalState;

		const idToken = await this.authService.getIdToken();

		const updatedHeaders = {
			...cloudWorkersHeaders,
			Authorization: idToken,
		};

		const response = await fetch(`${cloudWorkersUrl}/${path}`, {
			method,
			headers: updatedHeaders,
			body: JSON.stringify(data),
		});
		return response;
	}
}
