import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule, Router } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { EntityResolverService } from '../_resolvers/entity-resolver.service';

const routes: Routes = [
	{
		path: '',
		component: ThemeComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				canActivate: [AuthGuard],
				loadChildren: () => import('./pages/default/iglo/iglo.module').then(m => m.IgloModule),
				resolve: {
					entity: EntityResolverService,
				},
			},
		],
	},
	{
		path: '**',
		redirectTo: '/404',
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ThemeRoutingModule {}
