import { createReducer, on } from '@ngrx/store';

import { setUserEntities } from './user-entity.actions';

export const initialState = [];

export const userEntitiesReducer = createReducer(
	initialState,
	on(setUserEntities, (_state, { userEntities }) => userEntities)
);
