import { AccountProperty } from './../models/account.property';
import { AccountUser } from './../models/account.user';
import { BatchTemplate } from './../models/batchTemplate';
import { Observable, Subject, combineLatest } from 'rxjs';
import { IncomeStatementAccount } from './../models/incomestatement.account';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, Query } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

import { Account } from '../models/account';
import { Batch } from '../models/batch';
import { BatchEntry } from '../models/batch.entry';
import { AccountEntry } from '../models/account.entry';
import { AuthenticationService } from '../../../../../../auth/_services/authentication.service';
import { merge, map, take } from 'rxjs/operators';
import { AccountRegOwner } from '../models/account.registered-owner';
import { Property } from '@plex/property_models';
import { environment } from '../../../../../../../environments/environment';
import { Entity } from '../../entities/entities.model';
import { User } from '../../users/user.model';
import { AuditLogService } from '../../audit-log/audit-log.service';
import { Audit } from '../../audit-log/audit.model';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';
import { PropertiesService } from '../../properties/properties.service';
import { UsersService } from '../../users/users.service';
import { RegisteredOwnersService } from '../../registered-owners/registered-owners.service';
import { RegisteredOwner } from '../../reports/models/registeredOwner.model';
import { getOneBasedMonth } from '@shared/dates';
import { RegOwner } from '@plex/registered-owners/registered-owner.model';

declare var toastr: any;

interface DebtorWithPropertiesUsersOwners extends Account {
	registeredOwners: RegisteredOwner[];
	users: User[];
	properties: Property[];
}

type AddDebtorAccountRequest = {
	accountName?: string;
	entityId: string;
	userId?: string;
	propertyId?: string;
	regOwnerId?: string;
	account?: Account;
	currentUser: User;
	sendEmail: boolean;
	environment: EnvDetails;
	accountId?: string;
};

@Injectable()
export class FinService {
	entityId;
	account = new Subject<Account>();
	currentAccount: Account;
	accountsCollection: AngularFirestoreCollection<Account>;
	accountDoc: AngularFirestoreDocument<Account>;
	batchDoc: AngularFirestoreDocument<Batch>;
	batchesCollection: AngularFirestoreCollection<Batch>;
	finEmailSendCollection: AngularFirestoreCollection<any>;
	accounts: Observable<Account[]>;
	batches: Observable<Batch[]>;
	accountEntriesCollection: AngularFirestoreCollection<AccountEntry>;
	accountEntries: Observable<AccountEntry[]>;
	batchEntriesCollection: AngularFirestoreCollection<[BatchEntry]>;
	loggedInUser: string;
	currentUser: User;
	loggedInEmail: string;
	setNewBatchId: string;
	entityYearEndMonth: number;
	incomeStatementAccounts: Observable<IncomeStatementAccount[]>;
	monthsLookup: {};
	monthsIndexLookup: {};

	constructor(
		public afStore: AngularFirestore,
		private auth: AuthenticationService,
		private auditLogService: AuditLogService,
		private store: Store,
		private usersService: UsersService,
		private propertiesService: PropertiesService,
		private registeredOwnersService: RegisteredOwnersService,
		private functions: AngularFireFunctions
	) {
		this.monthsLookup = {
			Jan: 1,
			Feb: 2,
			Mar: 3,
			Apr: 4,
			May: 5,
			Jun: 6,
			Jul: 7,
			Aug: 8,
			Sep: 9,
			Oct: 10,
			Nov: 11,
			Dec: 12,
		};
		this.monthsIndexLookup = {
			1: 'Jan',
			2: 'Feb',
			3: 'Mar',
			4: 'Apr',
			5: 'May',
			6: 'Jun',
			7: 'Jul',
			8: 'Aug',
			9: 'Sep',
			10: 'Oct',
			11: 'Nov',
			12: 'Dec',
		};
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));

		this.auth.user.subscribe(userDetails => {
			this.loggedInUser = userDetails.uid;
			this.loggedInEmail = userDetails.email;
			this.currentUser = userDetails;
		});
	}

	getAccountsForEntity() {
		this.accountsCollection = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('accounts')
			.collection('list', ref => ref.orderBy('name', 'asc'));
		this.accounts = this.accountsCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Account;
					data.id = a.payload.doc.id;
					data.value = a.payload.doc.id; // FOR DROPDOWNS
					data.label = data.name; // FOR DROPDOWNS
					return data;
				});
			})
		);

		return this.accounts;
	}

	getActiveAccountsForEntity() {
		this.accountsCollection = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('accounts')
			.collection('list', ref => ref.orderBy('name', 'asc').where('active', '==', true));
		this.accounts = this.accountsCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Account;
					data.id = a.payload.doc.id;
					data.value = a.payload.doc.id; // FOR DROPDOWNS
					data.label = data.name; // FOR DROPDOWNS
					return data;
				});
			})
		);

		return this.accounts;
	}

	getDataForExpensesGraph() {
		const accountsRef = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('accounts')
			.collection('list', ref => ref.orderBy('balance', 'desc').where('type', '==', 'Expense').limit(3));
		return accountsRef.valueChanges();
	}

	getEntityYearEndMonth() {
		if (this.entityYearEndMonth !== undefined) {
			return Promise.resolve(this.entityYearEndMonth);
		} else {
			return this.afStore
				.collection('entities')
				.doc(this.entityId)
				.ref.get()
				.then(entity => {
					const entityData = entity.data() as Entity;
					this.entityYearEndMonth = this.getMonthOfYearFromString(entityData.financial_year_end);
					return Promise.resolve(this.entityYearEndMonth);
				});
		}
	}

	getEntityYearStartMonth() {
		return new Promise(res => {
			this.getEntityYearEndMonth().then(yearEnd => {
				const yearStart = yearEnd + 1;
				res(yearStart);
			});
		});
	}

	getExpenseAccountsForFinancialYear(entityYearEndMonth) {
		return this.getReportExpenseAccounts(entityYearEndMonth);
	}

	getBatchesForEntity(entityId) {
		this.batchesCollection = this.afStore
			.collection('entities')
			.doc(entityId)
			.collection('fin')
			.doc('batches')
			.collection('list', ref => ref.orderBy('created', 'desc').where('active', '==', true));
		this.batches = this.batchesCollection.valueChanges({ idField: 'id' });

		return this.batches;
	}

	getAccount(accountId) {
		return this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('accounts').collection('list').doc(accountId).valueChanges({ idField: 'id' });
	}
	//FETCH ACCOUNT DETAILS
	fetchAccountDetails(accountId: string) {
		this.accountDoc = this.afStore.doc(`entities/${this.entityId}/fin/accounts/list/${accountId}`);
		return this.accountDoc.valueChanges();
	}
	//UPDATE ACCOUNT DETAILS
	updateAccount(account: Account, debitor?: boolean) {
		const userInfo = this.auth.userDetails;
		account.updatedBy = userInfo.uid;
		account.updatedByName = `${userInfo.firstname} ${userInfo.surname}`;
		account.sendNotifications = true;
		account.updatedByUser = this.currentUser;
		let category = 'accounts';
		if (debitor) {
			category = 'debtors';
		}

		this.accountDoc = this.afStore.collection('entities').doc(this.entityId).collection('fin').doc(category).collection('list').doc(account.id);
		return this.accountDoc.update(account).then(() => {
			toastr.success('Account has been updated!');
		});
	}

	public accountLogAudit$(entityId: string, accountId: string) {
		return this.afStore
			.collection<Audit>(`entities/${entityId}/auditTrail`, ref => {
				return ref.orderBy('created', 'desc').where('id', '==', accountId).where('auditType', '==', 'sendStatement');
			})
			.valueChanges();
	}

	public async fetchAccountStatementSentLog(accountId: string, entityId: string) {
		const accountStatementLogs = await this.afStore.collection(`/entities/${entityId}/fin/debtors/list/${accountId}/statementSentLogs`).ref.orderBy('created', 'desc').get();

		const statementLogData = accountStatementLogs.docs.map(logData => logData.data());

		return statementLogData;
	}

	public debtorAccountAudit$(enId: string, accId: string): Observable<Audit[]> {
		return this.afStore
			.collection<Audit>(`entities/${enId}/auditTrail`, ref => {
				return ref.orderBy('created', 'desc').where('id', '==', accId);
			})
			.valueChanges()
			.pipe(
				map(audits => {
					return audits.filter(({ auditType }) => {
						return auditType !== 'sendStatement';
					});
				})
			);
	}

	getRecentEntriesForEntity(entityId) {
		return this.afStore.collection('entities').doc(entityId).collection('fin').doc('recent-entries').valueChanges();
	}

	getRecentBatchesForEntity(entityId) {
		const batchesRef = this.afStore
			.collection('entities')
			.doc(entityId)
			.collection('fin')
			.doc('batches')
			.collection('list', ref => ref.orderBy('created', 'asc').limit(5));
		return batchesRef.valueChanges({ idField: 'id' });
	}

	accountExistsCheck(accountName: string, entityId: string) {
		let collref = this.afStore.collection('entities').doc(entityId).collection('fin').doc('accounts').collection('list').ref;
		let queryref = collref.where('name', '==', accountName);
		return queryref.get().then(snapShot => {
			if (snapShot.empty) {
				return Promise.resolve();
			} else {
				return Promise.reject('exists');
			}
		});
	}

	addAccount(entityId, account: Account) {
		// console.log("account.type :", account.type, account.name);
		const accountsCollection = this.afStore.collection('entities').doc(entityId).collection('fin').doc('accounts').collection('list');
		account.active = true;
		account.createdBy = this.loggedInUser;
		account.created = Date.now();
		account.balance = 0;
		account.category = this.getAccountCategory(account.type);

		return accountsCollection.add(account);
	}
	getAccountCategory(accountType) {
		const balanceSheetAccounts = ['Liabilities', 'Equity', 'BankAndCash', 'Assets', 'BalanceSheet'];
		const reserveFundAccounts = ['ReserveFund', 'ReserveFundIncome', 'ReserveFundExpense'];
		const expenseAccounts = ['Expense'];
		const incomeAccountsAccounts = ['Income'];
		let category = 'Unknown';
		if (accountType === 'Owner') category = 'Debtor';
		if (accountType === 'Debtor') category = 'Debtor';
		if (balanceSheetAccounts.includes(accountType)) category = 'BalanceSheet';
		if (reserveFundAccounts.includes(accountType)) category = 'IncomeStatement';
		if (expenseAccounts.includes(accountType)) category = 'IncomeStatement';
		if (incomeAccountsAccounts.includes(accountType)) category = 'IncomeStatement';
		return category;
	}

	sendAccountAddedNotification(account) {
		if (account.type === 'Debtor' && environment.product === 'whitfields') {
			const pendingAdminAddedNotificationRef = this.afStore.collection('pending');
			const userInfo = this.auth.userDetails;

			pendingAdminAddedNotificationRef.add({
				request: 'emailAccountAddedNotification',
				accountType: account.type,
				accountName: account.name,
				entityId: this.entityId,
				environmentAdmin: environment.admin,
				product: environment.product,
				addedBy: `${userInfo.firstname} ${userInfo.surname}`,
			});
		}
	}

	getAccountEntriesForAccount(accountId) {
		this.accountEntriesCollection = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('accounts')
			.collection('list')
			.doc(accountId)
			.collection('entries', ref => ref.orderBy('txdate', 'desc'));
		this.accountEntries = this.accountEntriesCollection.valueChanges({ idField: 'id' });
		// this.accountEntries = this.accountEntriesCollection.snapshotChanges().pipe(map(changes => {
		//     return changes.map(a => {
		//         const data = a.payload.doc.data() as AccountEntry;
		//         data.id = a.payload.doc.id;
		//         return data;
		//     });
		// }));
		return this.accountEntries;
	}

	getFilteredEntries(accountId, startDate, endDate) {
		const entityEntriesRef = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('accounts')
			.collection('list')
			.doc(accountId)
			.collection('entries', ref => ref.where('txdate', '>', startDate).where('txdate', '<', endDate));
		return entityEntriesRef.valueChanges();
	}

	setAccount(accountId, accountName) {
		this.setCurrentAccountId(accountId);
		this.setCurrentAccountName(accountName);
	}

	sendLedger(accountId, accountName, recipient, startDate, endDate, type) {
		const entityName = sessionStorage.getItem('entity_name');
		this.finEmailSendCollection = this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('emails').collection('list');
		return this.finEmailSendCollection.add({
			recipient: recipient,
			accountId: accountId,
			entityName: entityName,
			accountName: accountName,
			startDate: startDate,
			endDate: endDate,
			type: type,
		});
	}

	setCurrentAccountId(accountId) {
		sessionStorage.setItem('account_id', accountId);
	}

	getCurrentAccountId() {
		return sessionStorage.getItem('account_id');
	}

	setCurrentAccountName(accountName) {
		sessionStorage.setItem('account_name', accountName);
	}

	getCurrentAccountName() {
		return sessionStorage.getItem('account_name');
	}

	//BATCHES
	addBatch(entityId, batch: Batch, entries) {
		// console.log("Adding new batch for ", entityId, batch);
		const batchesCollection = this.afStore.collection('entities').doc(entityId).collection('fin').doc('batches').collection('list');
		batch.createdBy = this.loggedInUser;
		batch.created = Date.now();
		const originalPost = batch.postNow;
		batch.postNow = false;
		batch.isPending = true;
		batch.isPosted = false;
		batch.referenceNumber = 'Pending';
		batch.active = true;
		return batchesCollection.add(batch).then(batchResult => {
			batchesCollection.doc(batchResult.id).set({ id: batchResult.id }, { merge: true });
			// console.log("Added the batch.", batchResult);
			let promiseList = [];
			entries.forEach(entryDetails => {
				// console.log("Adding entries for " + entityId, entryDetails.accountId, batchResult.id);
				entryDetails.batchId = batchResult.id;
				var entitiesBatchEntriesRef = batchesCollection.doc(batchResult.id).collection('entries');
				promiseList.push(
					entitiesBatchEntriesRef.add(entryDetails).then(() => {
						// console.log("Entry Added");
					})
				);
			});

			return Promise.all(promiseList).then(() => {
				// console.log(" Promise all has returned all promises");
				if (originalPost === true) {
					this.postBatch(entityId, batchResult.id).then(() => {
						return Promise.resolve(batchResult.id);
					});
				} else {
					return Promise.resolve(batchResult.id);
				}
			});
		});
	}

	postBatch(entityId, batchId) {
		// console.log("Posting Batch for : ", entityId, batchId);
		return this.afStore.collection('entities').doc(entityId).collection('fin').doc('batches').collection('list').doc(batchId).update({ postNow: true });
	}
	//FETCH BATCH DETAILS
	fetchBatchDetails(batchId: string) {
		this.batchDoc = this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('batches').collection('list').doc(batchId);
		// console.log("document: ", batchId);
		return this.batchDoc.valueChanges();
	}

	//FETCH BATCH ENTRIES FOR BATCH
	fetchBatchEntries(batchId: string) {
		this.batchEntriesCollection = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('batches')
			.collection('list')
			.doc(batchId)
			.collection('entries', ref => ref.orderBy('created', 'asc'));

		return this.batchEntriesCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data();
					return data;
				});
			})
		);
	}

	//UPDATE BATCH
	updateBatch(batchId: string, batch: Batch, entries) {
		this.batchDoc = this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('batches').collection('list').doc(batchId);
		return this.batchDoc
			.update(batch)
			.then(() => {
				//DELETE ALL THE PREVIOUS ENTRIES
				let entitiesBatchEntriesRef = this.batchDoc.collection('entries').ref;
				entitiesBatchEntriesRef
					.get()
					.then(snapshot => {
						if (!snapshot.empty) {
							// console.log("delete entries :");
							let oldEntriesPromiseList = [];
							snapshot.docs.forEach(doc => {
								oldEntriesPromiseList.push(doc.ref.delete());
							});
							return Promise.all(oldEntriesPromiseList).then(() => {
								Promise.resolve();
							});
						} else {
							return Promise.resolve();
						}
					})
					.then(() => {
						if (entries.length > 0) {
							let promiseList = [];
							entries.forEach(entryDetails => {
								// console.log("Adding entries for " + entityId, entryDetails.accountId, batchId);
								entryDetails.batchId = batchId;
								promiseList.push(entitiesBatchEntriesRef.add(entryDetails));
							});
							return Promise.all(promiseList).then(() => {
								Promise.resolve();
							});
						} else {
							return Promise.resolve();
						}
					});
			})
			.catch(error => {
				return Promise.reject('error' + error);
			});
	}

	//UPDATE BATCH
	deleteBatch(batchId) {
		if (!batchId) {
			return Promise.reject('BatchId Not specified as paramter');
		}
		const batchRef = this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('batches').collection('list').doc(batchId);

		return batchRef
			.collection('entries')
			.ref.get()
			.then(snapshot => {
				if (!snapshot.empty) {
					let oldEntriesPromiseList = [];
					snapshot.docs.forEach(doc => {
						oldEntriesPromiseList.push(doc.ref.delete());
					});
					return Promise.all(oldEntriesPromiseList).then(() => {
						Promise.resolve();
					});
				} else {
					return Promise.resolve();
				}
			})
			.then(() => {
				return batchRef.delete();
			})
			.catch(error => {
				return Promise.reject('Could no delete batch' + error);
			});
	}

	//SAVE BATCH TEMPLATE
	saveBatchTemplate(templateName: string, batchId: string) {
		// console.log("Setting batch to save as template Batch for : ", templateName, batchId);
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('batches')
			.collection('batchesToTemplate')
			.add({ batchId: batchId, templateName: templateName, createdBy: this.loggedInUser });
	}

	getBatchTemplates() {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('batches')
			.collection('templates')
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						// console.log("a :", a);
						const data = a.payload.doc.data() as BatchTemplate;
						// console.log("data :", data);
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
	}

	getBatchTemplateDetails(templateId: string) {
		return this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('batches').collection('templates').doc(templateId).valueChanges();
	}

	getBatchTemplateEntries(templateId: string) {
		return this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('batches').collection('templates').doc(templateId).collection('entries').valueChanges();
	}

	fetchUserCustomAccounts() {
		const userId = sessionStorage.getItem('userId');
		const userAccountsRef = this.afStore.doc(`users/${userId}/entities/${this.entityId}/fin/custom`);

		return userAccountsRef.valueChanges();
	}

	updateUserCustomAccounts(accounts) {
		const userId = sessionStorage.getItem('userId');
		const userAccountsRef = this.afStore.doc(`users/${userId}/entities/${this.entityId}/fin/custom`);

		return userAccountsRef.set(
			{
				account1: accounts.account1,
				account2: accounts.account2,
				account3: accounts.account3,
			},
			{ merge: true }
		);
	}

	fetchEntries() {
		const entriesRef = this.afStore.collection(`entities/${this.entityId}/fin/entries/list`, ref => ref.orderBy('created', 'desc'));
		return entriesRef.valueChanges();
	}

	//GET INCOME STATEMENT DATA
	getAvailableFinancialYears() {
		const yearsRef = this.afStore.collection(`entities/${this.entityId}/fin/reports/years`);
		return yearsRef.valueChanges({ idField: 'id' });
		// return yearsRef.snapshotChanges().pipe(map(changes => {
		//     return changes.map(a => {
		//         const data = a.payload.doc.data() as any;
		//         console.log("data :", data);
		//         data.id = a.payload.doc.id;
		//         return data;
		//     });
		// }));
	}
	getReportIncomeAccounts(financialYear) {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('reports')
			.collection('years')
			.doc(financialYear)
			.collection('incomestatement', ref => ref.where('type', '==', 'Income').where('active', '==', true).orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	calculateFinancialYearsForEntity(entityYearEndMonth) {
		const d = new Date();
		const currentMonth = getOneBasedMonth(d).toString();
		const currentYear = d.getFullYear().toString();
		let currentFinancialYearStart = new Date();
		let currentFinancialYearEnd = new Date();
		let nextFinancialYearStart = new Date();
		let nextFinancialYearEnd = new Date();
		let previousFinancialYearStart = new Date();
		let previousFinancialYearEnd = new Date();
		// console.log("current month  ", currentMonth, entityYearEndMonth);
		if (parseInt(currentMonth) > entityYearEndMonth) {
			currentFinancialYearStart = new Date(parseInt(currentYear), entityYearEndMonth, 1);
			currentFinancialYearEnd = new Date(parseInt(currentYear) + 1, entityYearEndMonth, 0);
			nextFinancialYearStart = new Date(parseInt(currentYear) + 1, entityYearEndMonth, 1);
			nextFinancialYearEnd = new Date(parseInt(currentYear) + 2, entityYearEndMonth, 0);
			previousFinancialYearStart = new Date(parseInt(currentYear) - 1, entityYearEndMonth, 1);
			previousFinancialYearEnd = new Date(parseInt(currentYear), entityYearEndMonth, 0);
		} else if (parseInt(currentMonth) < entityYearEndMonth) {
			currentFinancialYearStart = new Date(parseInt(currentYear), entityYearEndMonth, 1);
			currentFinancialYearEnd = new Date(parseInt(currentYear) + 1, entityYearEndMonth, 0);
			nextFinancialYearStart = new Date(parseInt(currentYear) + 1, entityYearEndMonth, 1);
			nextFinancialYearEnd = new Date(parseInt(currentYear) + 2, entityYearEndMonth, 0);
			previousFinancialYearStart = new Date(parseInt(currentYear) - 1, entityYearEndMonth, 1);
			previousFinancialYearEnd = new Date(parseInt(currentYear), entityYearEndMonth, 0);
		}

		const financialYearEnds = {
			previousYear: previousFinancialYearEnd.getFullYear(),
			currentYear: currentFinancialYearEnd.getFullYear(),
			nextYear: nextFinancialYearEnd.getFullYear(),
			previousYearEndDate: previousFinancialYearEnd,
			currentYearEndDate: currentFinancialYearEnd,
			nextYearEndDate: nextFinancialYearEnd,
		};
		return financialYearEnds;
	}

	getReportExpenseAccounts(financialYear) {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('reports')
			.collection('years')
			.doc(financialYear)
			.collection('incomestatement', ref => ref.where('type', '==', 'Expense').where('active', '==', true).orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	getReportReserveAccounts(financialYear) {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('reports')
			.collection('years')
			.doc(financialYear)
			.collection('incomestatement', ref => ref.orderBy('name', 'asc').where('category', '==', 'ReserveFund'))
			.valueChanges();
	}

	getReportBalanceSheetAccounts(financialYear) {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('reports')
			.collection('years')
			.doc(financialYear)
			.collection('incomestatement', ref => ref.orderBy('name', 'asc').where('category', '==', 'BalanceSheet'))
			.valueChanges();
	}

	getReportIncomeExpenseTotals(recoveryAccounts, expenseAccounts) {
		let totals = [];
		const recoveryTotals = {
			name: 'Monthly Recoveries',
			accruals: 0,
			budget_1: 0,
			budget_2: 0,
			budget_3: 0,
			budget_4: 0,
			budget_5: 0,
			budget_6: 0,
			budget_7: 0,
			budget_8: 0,
			budget_9: 0,
			budget_10: 0,
			budget_11: 0,
			budget_12: 0,
			totalBudget: 0,
			ytd: 0,
			remainingBudget: 0,
			actual: 0,
			variance: 0,
			totalsColumnOne: 'O/Bal Retained Income',
			totalsColumnTwo: 0,
		};
		const expenseTotals = {
			name: 'Monthly Expenses',
			accruals: 0,
			budget_1: 0,
			budget_2: 0,
			budget_3: 0,
			budget_4: 0,
			budget_5: 0,
			budget_6: 0,
			budget_7: 0,
			budget_8: 0,
			budget_9: 0,
			budget_10: 0,
			budget_11: 0,
			budget_12: 0,
			totalBudget: 0,
			ytd: 0,
			remainingBudget: 0,
			actual: 0,
			variance: 0,
			totalsColumnOne: 'Actual P/(L) prior year',
			totalsColumnTwo: 0,
		};
		const netDiffTotals = {
			name: 'Net Difference',
			accruals: 0,
			budget_1: 0,
			budget_2: 0,
			budget_3: 0,
			budget_4: 0,
			budget_5: 0,
			budget_6: 0,
			budget_7: 0,
			budget_8: 0,
			budget_9: 0,
			budget_10: 0,
			budget_11: 0,
			budget_12: 0,
			totalBudget: 0,
			ytd: 0,
			remainingBudget: 0,
			actual: 0,
			variance: 0,
			totalsColumnOne: 'Budgeted Est Surplus',
			totalsColumnTwo: 0,
		};
		const accDiffTotals = {
			name: 'Accumulated Diff',
			accruals: 0,
			budget_1: 0,
			budget_2: 0,
			budget_3: 0,
			budget_4: 0,
			budget_5: 0,
			budget_6: 0,
			budget_7: 0,
			budget_8: 0,
			budget_9: 0,
			budget_10: 0,
			budget_11: 0,
			budget_12: 0,
			actual: 0,
			variance: 0,
			totalsColumnOne: 'Accumulated Surplus',
			totalsColumnTwo: 0,
		};
		totals[0] = recoveryTotals;
		totals[1] = expenseTotals;
		totals[2] = netDiffTotals;
		totals[3] = accDiffTotals;
		if (recoveryAccounts) {
			let newRecoveryTotals = recoveryTotals;
			this.resetBalancesForAccount(newRecoveryTotals);
			let newNetDiffTotals = netDiffTotals;
			this.resetBalancesForAccount(newNetDiffTotals);
			let newAccDiffTotals = accDiffTotals;
			this.resetBalancesForAccount(newAccDiffTotals);
			this.calculateNewBalancesForAccountBudget(newRecoveryTotals, recoveryAccounts, recoveryTotals, expenseTotals, newNetDiffTotals, newAccDiffTotals);
		}
		if (expenseAccounts) {
			let newExpenseTotals = expenseTotals;
			this.resetBalancesForAccount(newExpenseTotals);
			let newNetDiffTotals = netDiffTotals;
			this.resetBalancesForAccount(newNetDiffTotals);
			let newAccDiffTotals = accDiffTotals;
			this.resetBalancesForAccount(newAccDiffTotals);
			this.calculateNewBalancesForAccountBudget(newExpenseTotals, expenseAccounts, recoveryTotals, expenseTotals, newNetDiffTotals, newAccDiffTotals);
		}
		return totals;
	}

	resetBalancesForAccount(account) {
		for (let i = 1; i <= 12; i++) {
			account['month' + i] = 0;
		}
		account['ytd'] = 0;
		account['totalBudget'] = 0;
		account['remainingBudget'] = 0;
		account['actual'] = 0;
		account['variance'] = 0;
		return account;
	}

	calculateNewBalancesForAccountBudget(newRecoveryTotals, data, incomeTotals, expenseTotals, newNetDiffTotals, newAccDiffTotals) {
		data.forEach((account, i) => {
			newRecoveryTotals.budget_1 = newRecoveryTotals.month1 + parseInt(account.budget_1);
			newRecoveryTotals.budget_2 = newRecoveryTotals.month2 + parseInt(account.budget_2);
			newRecoveryTotals.budget_3 = newRecoveryTotals.month3 + parseInt(account.budget_3);
			newRecoveryTotals.budget_4 = newRecoveryTotals.month4 + parseInt(account.budget_4);
			newRecoveryTotals.budget_5 = newRecoveryTotals.month5 + parseInt(account.budget_5);
			newRecoveryTotals.budget_6 = newRecoveryTotals.month6 + parseInt(account.budget_6);
			newRecoveryTotals.budget_7 = newRecoveryTotals.month7 + parseInt(account.budget_7);
			newRecoveryTotals.budget_8 = newRecoveryTotals.month8 + parseInt(account.budget_8);
			newRecoveryTotals.budget_9 = newRecoveryTotals.month9 + parseInt(account.budget_9);
			newRecoveryTotals.budget_10 = newRecoveryTotals.month10 + parseInt(account.budget_10);
			newRecoveryTotals.budget_11 = newRecoveryTotals.month11 + parseInt(account.budget_11);
			newRecoveryTotals.budget_12 = newRecoveryTotals.month12 + parseInt(account.budget_12);
			newRecoveryTotals.ytd = newRecoveryTotals.ytd + parseInt(account.ytd);
			newRecoveryTotals.totalBudget = newRecoveryTotals.totalBudget + parseInt(account.totalBudget);
			newRecoveryTotals.actual = newRecoveryTotals.month12 + parseInt(account.actual);
			newRecoveryTotals.variance = newRecoveryTotals.variance + parseInt(account.variance);
			newRecoveryTotals.remainingBudget = newRecoveryTotals.remainingBudget + parseInt(account.remainingBudget);
		});
		newNetDiffTotals.budget_1 = incomeTotals.budget_1 - expenseTotals.budget_1;
		newNetDiffTotals.budget_2 = incomeTotals.budget_2 - expenseTotals.budget_2;
		newNetDiffTotals.budget_3 = incomeTotals.budget_3 - expenseTotals.budget_3;
		newNetDiffTotals.budget_4 = incomeTotals.budget_4 - expenseTotals.budget_4;
		newNetDiffTotals.budget_5 = incomeTotals.budget_5 - expenseTotals.budget_5;
		newNetDiffTotals.budget_6 = incomeTotals.budget_6 - expenseTotals.budget_6;
		newNetDiffTotals.budget_7 = incomeTotals.budget_7 - expenseTotals.budget_7;
		newNetDiffTotals.budget_8 = incomeTotals.budget_8 - expenseTotals.budget_8;
		newNetDiffTotals.budget_9 = incomeTotals.budget_9 - expenseTotals.budget_9;
		newNetDiffTotals.budget_10 = incomeTotals.budget_10 - expenseTotals.budget_10;
		newNetDiffTotals.budget_11 = incomeTotals.budget_11 - expenseTotals.budget_11;
		newNetDiffTotals.budget_12 = incomeTotals.budget_12 - expenseTotals.budget_12;
		newNetDiffTotals.ytd = incomeTotals.ytd - expenseTotals.ytd;
		newNetDiffTotals.totalBudget = incomeTotals.totalBudget - expenseTotals.totalBudget;
		newNetDiffTotals.remainingBudget = '';
		newNetDiffTotals.actual = 0;
		newNetDiffTotals.variance = 0;

		newAccDiffTotals.budget_1 = newAccDiffTotals.accruals + newNetDiffTotals.budget_1;
		newAccDiffTotals.budget_2 = newAccDiffTotals.budget_1 + newNetDiffTotals.budget_2;
		newAccDiffTotals.budget_3 = newAccDiffTotals.budget_2 + newNetDiffTotals.budget_3;
		newAccDiffTotals.budget_4 = newAccDiffTotals.budget_3 + newNetDiffTotals.budget_4;
		newAccDiffTotals.budget_5 = newAccDiffTotals.budget_4 + newNetDiffTotals.budget_5;
		newAccDiffTotals.budget_6 = newAccDiffTotals.budget_5 + newNetDiffTotals.budget_6;
		newAccDiffTotals.budget_7 = newAccDiffTotals.budget_6 + newNetDiffTotals.budget_7;
		newAccDiffTotals.budget_8 = newAccDiffTotals.budget_7 + newNetDiffTotals.budget_8;
		newAccDiffTotals.budget_9 = newAccDiffTotals.budget_8 + newNetDiffTotals.budget_9;
		newAccDiffTotals.budget_10 = newAccDiffTotals.budget_9 + newNetDiffTotals.budget_10;
		newAccDiffTotals.budget_11 = newAccDiffTotals.budget_10 + newNetDiffTotals.budget_11;
		newAccDiffTotals.budget_12 = newAccDiffTotals.budget_11 + newNetDiffTotals.budget_12;
		newAccDiffTotals.ytd = '';
		newAccDiffTotals.totalBudget = incomeTotals.totalBudget + expenseTotals.totalBudget;
		newAccDiffTotals.remainingBudget = '';
		newAccDiffTotals.actual = 0;
		newAccDiffTotals.variance = 0;
	}

	getBudgetIncomeExpenseTotals(recoveryAccounts, expenseAccounts) {
		let totals = [];
		const recoveryTotals = {
			name: 'Monthly Recoveries',
			accruals: 0,
			budget_1: 0,
			budget_2: 0,
			budget_3: 0,
			budget_4: 0,
			budget_5: 0,
			budget_6: 0,
			budget_7: 0,
			budget_8: 0,
			budget_9: 0,
			budget_10: 0,
			budget_11: 0,
			budget_12: 0,
			totalBudget: 0,
			ytd: 0,
			remainingBudget: 0,
			actual: 0,
			variance: 0,
			totalsColumnOne: 'O/Bal Retained Income',
			totalsColumnTwo: 0,
		};
		const expenseTotals = {
			name: 'Monthly Expenses',
			accruals: 0,
			budget_1: 0,
			budget_2: 0,
			budget_3: 0,
			budget_4: 0,
			budget_5: 0,
			budget_6: 0,
			budget_7: 0,
			budget_8: 0,
			budget_9: 0,
			budget_10: 0,
			budget_11: 0,
			budget_12: 0,
			totalBudget: 0,
			ytd: 0,
			remainingBudget: 0,
			actual: 0,
			variance: 0,
			totalsColumnOne: 'Actual P/(L) prior year',
			totalsColumnTwo: 0,
		};
		const netDiffTotals = {
			name: 'Net Difference',
			accruals: 0,
			budget_1: 0,
			budget_2: 0,
			budget_3: 0,
			budget_4: 0,
			budget_5: 0,
			budget_6: 0,
			budget_7: 0,
			budget_8: 0,
			budget_9: 0,
			budget_10: 0,
			budget_11: 0,
			budget_12: 0,
			totalBudget: 0,
			ytd: 0,
			remainingBudget: 0,
			actual: 0,
			variance: 0,
			totalsColumnOne: 'Budgeted Est Surplus',
			totalsColumnTwo: 0,
		};
		const accDiffTotals = {
			name: 'Accumulated Diff',
			accruals: 0,
			budget_1: 0,
			budget_2: 0,
			budget_3: 0,
			budget_4: 0,
			budget_5: 0,
			budget_6: 0,
			budget_7: 0,
			budget_8: 0,
			budget_9: 0,
			budget_10: 0,
			budget_11: 0,
			budget_12: 0,
			actual: 0,
			variance: 0,
			totalsColumnOne: 'Accumulated Surplus',
			totalsColumnTwo: 0,
		};
		totals[0] = recoveryTotals;
		totals[1] = expenseTotals;
		totals[2] = netDiffTotals;
		totals[3] = accDiffTotals;
		if (recoveryAccounts) {
			let newRecoveryTotals = recoveryTotals;
			this.resetBalancesForAccount(newRecoveryTotals);
			let newNetDiffTotals = netDiffTotals;
			this.resetBalancesForAccount(newNetDiffTotals);
			let newAccDiffTotals = accDiffTotals;
			this.resetBalancesForAccount(newAccDiffTotals);
			this.calculateNewBalancesForAccountBudget(newRecoveryTotals, recoveryAccounts, recoveryTotals, expenseTotals, newNetDiffTotals, newAccDiffTotals);
		}
		if (expenseAccounts) {
			let newExpenseTotals = expenseTotals;
			this.resetBalancesForAccount(newExpenseTotals);
			let newNetDiffTotals = netDiffTotals;
			this.resetBalancesForAccount(newNetDiffTotals);
			let newAccDiffTotals = accDiffTotals;
			this.resetBalancesForAccount(newAccDiffTotals);
			this.calculateNewBalancesForAccountBudget(newExpenseTotals, expenseAccounts, recoveryTotals, expenseTotals, newNetDiffTotals, newAccDiffTotals);
		}
		return totals;
	}

	getTrialBalanceEntries(recoveryAccounts, expenseAccounts, balanceSheetAccounts) {
		return combineLatest<any[]>(recoveryAccounts, expenseAccounts, balanceSheetAccounts).pipe(
			//map(arr => arr.reduce((acc, cur) => acc.concat(cur))),
			map(dataArrays => {
				let incomeStatementFinalList = [];
				let incomeStatementTotals = { description: '', debit: 0, credit: 0 };
				let incomeStatementProfitLoss = { description: '', debit: 0, credit: 0 };
				const recoveryAccounts = dataArrays[0];
				const expenseAccounts = dataArrays[1];
				const balanceSheetAccounts = dataArrays[2];
				let incomeTotal = 0;
				let expenseTotal = 0;
				let incomeStatementDebitTotal = 0;
				let incomeStatementCreditTotal = 0;

				//INCOME STATEMENT
				recoveryAccounts.forEach(account => {
					/* Object.entries(account).forEach(
                                  ([key, value]) => console.log("loop values", key, value)
                                ); */
					incomeTotal = incomeTotal + parseInt(account.ytd);
					if (account.ytd > 0) {
						account.credit = account.ytd;
						account.debit = 0;
						incomeStatementCreditTotal += parseInt(account.ytd);
					} else {
						account.debit = account.ytd;
						account.credit = 0;
						incomeStatementDebitTotal += parseInt(account.ytd);
					}
					if (account.debit === 0 && account.credit === 0) {
						account.isZero = true;
					}
					incomeStatementFinalList.push(account);
				});
				expenseAccounts.forEach(account => {
					expenseTotal = expenseTotal + parseInt(account.ytd);
					if (account.ytd > 0) {
						account.debit = account.ytd;
						account.credit = 0;
						incomeStatementDebitTotal += parseInt(account.ytd);
					} else {
						account.credit = account.ytd;
						account.debit = 0;
						incomeStatementCreditTotal += parseInt(account.ytd);
					}
					if (account.debit === 0 && account.credit === 0) {
						account.isZero = true;
					}
					incomeStatementFinalList.push(account);
				});

				if (incomeTotal > expenseTotal || incomeTotal == expenseTotal) {
					const profitAmount = incomeTotal - expenseTotal;
					incomeStatementDebitTotal += profitAmount;
					incomeStatementProfitLoss = { description: 'Profit year to date', debit: profitAmount, credit: 0 };
				} else {
					let lossAmount = expenseTotal - incomeTotal;
					incomeStatementCreditTotal += lossAmount;
					incomeStatementProfitLoss = { description: 'Loss year to date', debit: 0, credit: lossAmount };
				}
				incomeStatementTotals = { description: '', debit: incomeStatementDebitTotal, credit: incomeStatementCreditTotal };

				//BALANCE SHEET
				let balanceSheetTotals = { description: '', debit: 0, credit: 0 };
				let balanceSheetDebitTotal = incomeStatementProfitLoss.debit;
				let balanceSheetCreditTotal = incomeStatementProfitLoss.credit;
				let balanceSheetFinalList = [];
				balanceSheetAccounts.forEach(account => {
					if (account.ytd > 0) {
						account.debit = account.ytd;
						account.credit = 0;
						balanceSheetDebitTotal = account.debit + parseInt(account.ytd);
					} else {
						account.credit = account.ytd;
						account.debit = 0;
						balanceSheetCreditTotal = account.credit + parseInt(account.ytd);
					}
					if (account.debit === 0 && account.credit === 0) {
						account.isZero = true;
					}
					balanceSheetFinalList.push(account);
				});
				balanceSheetTotals = { description: '', debit: balanceSheetDebitTotal, credit: balanceSheetCreditTotal };
				let returnData = [];
				returnData['incomeStatementFinalList'] = incomeStatementFinalList;
				returnData['incomeStatementProfitLoss'] = incomeStatementProfitLoss;
				returnData['incomeStatementTotals'] = incomeStatementTotals;
				returnData['balanceSheetFinalList'] = balanceSheetFinalList;
				returnData['balanceSheetTotals'] = balanceSheetTotals;
				return returnData;
			})
		);
		/* let recoveryTotals = 0;
            let expenseTotals = 0;
            let totals = [];
            const profit = { name: "Profit for the year", debit: 0, credit: 0 };
            const columnTotals = { name: "Totals", debit: 0, credit: 0 };
            totals[0] = profit;
            totals[1] = columnTotals;

            //const finalTotals = new BehaviorSubject(totals);
            recoveryAccounts.subscribe(data => {
                if (data) {
                    console.log('data :', data);
                    totals.push(data);
                    //totals = this.resetTotalsForTrialBalance(totals);
                    //recoveryTotals = this.calculateNewTotalsForTrialBalance(data, columnTotals);
                }
            });
            expenseAccounts.subscribe(data => {
                if (data) {
                    console.log('data :', data);
                    totals.push(data);
                    //totals = this.resetTotalsForTrialBalance(totals);
                    //expenseTotals = this.calculateNewTotalsForTrialBalance(data, columnTotals);
                }
            });
            return totals; */
	}

	resetBalancesForBudget(account) {
		for (let i = 1; i <= 12; i++) {
			account['budget' + i] = 0;
		}
		account['ytd'] = 0;
		account['totalBudget'] = 0;
		account['remainingBudget'] = 0;
		account['actual'] = 0;
		account['variance'] = 0;
		return account;
	}

	resetTotalsForTrialBalance(totals) {
		totals[0]['credit'] = 0;
		totals[0]['debit'] = 0;
		return totals;
	}

	calculateNewBalancesForBudget(newRecoveryTotals, data, incomeTotals, expenseTotals, newNetDiffTotals, newAccDiffTotals) {
		data.forEach(account => {
			newRecoveryTotals.budget1 = newRecoveryTotals.budget1 + parseInt(account.budget1);
			newRecoveryTotals.budget2 = newRecoveryTotals.budget2 + parseInt(account.budget2);
			newRecoveryTotals.budget3 = newRecoveryTotals.budget3 + parseInt(account.budget3);
			newRecoveryTotals.budget4 = newRecoveryTotals.budget4 + parseInt(account.budget4);
			newRecoveryTotals.budget5 = newRecoveryTotals.budget5 + parseInt(account.budget5);
			newRecoveryTotals.budget6 = newRecoveryTotals.budget6 + parseInt(account.budget6);
			newRecoveryTotals.budget7 = newRecoveryTotals.budget7 + parseInt(account.budget7);
			newRecoveryTotals.budget8 = newRecoveryTotals.budget8 + parseInt(account.budget8);
			newRecoveryTotals.budget9 = newRecoveryTotals.budget9 + parseInt(account.budget9);
			newRecoveryTotals.budget10 = newRecoveryTotals.budget10 + parseInt(account.budget10);
			newRecoveryTotals.budget11 = newRecoveryTotals.budget11 + parseInt(account.budget11);
			newRecoveryTotals.budget12 = newRecoveryTotals.budget12 + parseInt(account.budget12);
			newRecoveryTotals.ytd = newRecoveryTotals.ytd + parseInt(account.ytd);
			newRecoveryTotals.actual = parseInt(account.actual);
			newRecoveryTotals.variance = parseInt(account.variance);
			newRecoveryTotals.totalBudget = newRecoveryTotals.totalBudget + parseInt(account.totalBudget);
			newRecoveryTotals.remainingBudget = newRecoveryTotals.remainingBudget + parseInt(account.remainingBudget);
		});

		newNetDiffTotals.budget1 = incomeTotals.budget1 - expenseTotals.budget1;
		newNetDiffTotals.budget2 = incomeTotals.budget2 - expenseTotals.budget2;
		newNetDiffTotals.budget3 = incomeTotals.budget3 - expenseTotals.budget3;
		newNetDiffTotals.budget4 = incomeTotals.budget4 - expenseTotals.budget4;
		newNetDiffTotals.budget5 = incomeTotals.budget5 - expenseTotals.budget5;
		newNetDiffTotals.budget6 = incomeTotals.budget6 - expenseTotals.budget6;
		newNetDiffTotals.budget7 = incomeTotals.budget7 - expenseTotals.budget7;
		newNetDiffTotals.budget8 = incomeTotals.budget8 - expenseTotals.budget8;
		newNetDiffTotals.budget9 = incomeTotals.budget9 - expenseTotals.budget9;
		newNetDiffTotals.budget10 = incomeTotals.budget10 - expenseTotals.budget10;
		newNetDiffTotals.budget11 = incomeTotals.budget11 - expenseTotals.budget11;
		newNetDiffTotals.budget12 = incomeTotals.budget12 - expenseTotals.budget12;
		newNetDiffTotals.ytd = incomeTotals.ytd - expenseTotals.ytd;
		newNetDiffTotals.totalBudget = incomeTotals.totalBudget - expenseTotals.totalBudget;
		newNetDiffTotals.remainingBudget = '';
		newNetDiffTotals.actual = 0;
		newNetDiffTotals.variance = 0;

		newAccDiffTotals.budget1 = newAccDiffTotals.accruals + newNetDiffTotals.budget1;
		newAccDiffTotals.budget2 = newAccDiffTotals.budget1 + newNetDiffTotals.budget2;
		newAccDiffTotals.budget3 = newAccDiffTotals.budget2 + newNetDiffTotals.budget3;
		newAccDiffTotals.budget4 = newAccDiffTotals.budget3 + newNetDiffTotals.budget4;
		newAccDiffTotals.budget5 = newAccDiffTotals.budget4 + newNetDiffTotals.budget5;
		newAccDiffTotals.budget6 = newAccDiffTotals.budget5 + newNetDiffTotals.budget6;
		newAccDiffTotals.budget7 = newAccDiffTotals.budget6 + newNetDiffTotals.budget7;
		newAccDiffTotals.budget8 = newAccDiffTotals.budget7 + newNetDiffTotals.budget8;
		newAccDiffTotals.budget9 = newAccDiffTotals.budget8 + newNetDiffTotals.budget9;
		newAccDiffTotals.budget10 = newAccDiffTotals.budget9 + newNetDiffTotals.budget10;
		newAccDiffTotals.budget11 = newAccDiffTotals.budget10 + newNetDiffTotals.budget11;
		newAccDiffTotals.budget12 = newAccDiffTotals.budget11 + newNetDiffTotals.budget12;
		newAccDiffTotals.ytd = '';
		newAccDiffTotals.totalBudget = '';
		newAccDiffTotals.remainingBudget = '';
		newAccDiffTotals.actual = 0;
		newAccDiffTotals.variance = 0;
	}

	calculateNewTotalsForTrialBalance(data, totals) {
		let totalCount = 0;
		data.forEach(account => {
			totalCount = totalCount + parseInt(account.ytd);
		});
		return totalCount;
	}

	updateBudgetAccount(accountId, financialYear, data, newValue?) {
		const incomeAccountRef = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('reports')
			.collection('years')
			.doc(financialYear)
			.collection('incomestatement')
			.doc(accountId).ref;

		return incomeAccountRef.get().then(account => {
			const accountData = account.data();

			data.totalBudget = +accountData.totalBudget + +newValue;

			this.afStore
				.collection('entities')
				.doc(this.entityId)
				.collection('fin')
				.doc('reports')
				.collection('years')
				.doc(financialYear)
				.collection('incomestatement')
				.doc(accountId)
				.set(data, { merge: true });
		});
	}

	getMonthOfYearFromNumber(month: number) {
		let returnMonth = null;
		Object.keys(this.monthsLookup).forEach(key => {
			if (this.monthsLookup[key] === month) {
				returnMonth = key;
			}
		});
		return returnMonth;
	}

	getMonthsFromYearStart(monthStart: number) {
		let monthsList = [];
		let currentMonth = monthStart;
		for (let index = 1; index <= 12; index++) {
			monthsList.push(currentMonth);
			currentMonth++;
			currentMonth === 13 ? (currentMonth = 1) : null;
		}
		return monthsList;
	}

	getBudgetMonths(monthStart) {
		let monthsList = [];
		let currentMonth = monthStart;
		for (let index = 1; index <= 12; index++) {
			monthsList.push('budget_' + currentMonth);
			currentMonth++;
			currentMonth === 13 ? (currentMonth = 1) : null;
		}
		return monthsList;
	}

	getMonthNumbers(monthStart) {
		console.log('monthStart', monthStart);
		let monthsList = [];
		let currentMonth = monthStart;
		for (let index = 1; index <= 12; index++) {
			console.log('index', index);
			console.log('month', this.monthsIndexLookup[currentMonth]);
			monthsList.push(this.monthsIndexLookup[currentMonth]);
			currentMonth++;
			currentMonth === 13 ? (currentMonth = 1) : null;
		}
		console.log('TCL: FinService -> getMonthNumbers -> monthsList', monthsList);
		return monthsList;
	}

	getMonthOfYearFromString(month: string) {
		const tmpMonth = month.substring(0, 3);
		return this.monthsLookup[tmpMonth];
	}

	//
	// DEBTORS
	//
	getDebtorsAccountsForEntity() {
		this.accountsCollection = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list', ref => ref.orderBy('name', 'asc'));
		this.accounts = this.accountsCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Account;
					data.id = a.payload.doc.id;
					data.value = a.payload.doc.id; // FOR DROPDOWNS
					data.label = data.name; // FOR DROPDOWNS
					return data;
				});
			})
		);

		return this.accounts;
	}

	addOrLinkDebtorAccount(account?: Account, userId?: string, propertyId?: string, regOwnerId?: string, accountId?: string) {
		const { admin, client, product } = environment;
		if (account) {
			account.active = true;
			account.createdBy = this.loggedInUser;
			account.created = Date.now();
			account.balance = 0;
			account.isFirefly = (product === 'whitfields' && account.name.length === 12) || false;
		}

		return this.functions
			.httpsCallable<AddDebtorAccountRequest>('fin-core-addDebtorAccount')({
				account,
				accountName: account?.name,
				entityId: this.entityId,
				userId,
				propertyId,
				regOwnerId,
				currentUser: this.auth.userDetails,
				sendEmail: true,
				environment: { admin, client, product },
				accountId,
			})
			.toPromise();
	}

	addLogToAccount(accountId?: string, property?: Property) {
		if (this.entityId) {
			const auditDetails = {
				category: 'debtors',
				createdBy: this.currentUser.uid ? this.currentUser.uid : '',
				firstname: this.currentUser.firstname ? this.currentUser.firstname : '',
				surname: this.currentUser.surname ? this.currentUser.surname : '',
				description: `Debtors account updated - ${property.property_number} was added to the account`,
				created: Date.now(),
				id: accountId,
				type: 'added',
			};
			const auditTrailCollection = this.afStore.collection(`entities/${this.entityId}/auditTrail`);
			auditTrailCollection.add(auditDetails);
		}
	}

	public async getDebtorWithPropertiesUsersOwners(entityId: string, accountId: string, active = true): Promise<DebtorWithPropertiesUsersOwners> {
		try {
			const debtorDoc = await this.afStore.doc<Account>(`entities/${entityId}/fin/debtors/list/${accountId}`).ref.get();
			const debtor = {
				id: debtorDoc.id,
				...debtorDoc.data(),
			};

			const properties = await this.getCollectionActiveDocsSourceDocs<Property>(
				`entities/${entityId}/fin/debtors/list/${accountId}/properties`,
				`entities/${entityId}/properties`,
				active
			);

			const registeredOwners = await this.getCollectionActiveDocsSourceDocs<RegisteredOwner>(
				`entities/${entityId}/fin/debtors/list/${accountId}/registered_owners`,
				`entities/${entityId}/registered_owners`,
				active
			);

			const users = await this.getCollectionActiveDocsSourceDocs<User>(`entities/${entityId}/fin/debtors/list/${accountId}/users`, `users`, active);

			return {
				...debtor,
				registeredOwners,
				users,
				properties,
			};
		} catch (error) {
			console.error(error);
		}
	}

	private async getCollectionActiveDocsSourceDocs<V>(
		subCollectionPath: string,
		sourceCollectionPath: string,
		active?: boolean | undefined
	): Promise<
		({
			active: any;
			id: string;
		} & V)[]
	> {
		try {
			let subCollectionQuery = this.afStore.collection<V & { active: boolean }>(subCollectionPath).ref as Query;
			if (active || active === false) {
				subCollectionQuery = subCollectionQuery.where('active', '==', active);
			}

			const subCollection = (await subCollectionQuery.get()).docs;
			const getSourceCollectionPromises = subCollection.map(async doc => {
				const { id } = doc;
				const { active } = doc.data();

				const sourceCollectionRef = this.afStore.doc<V>(`${sourceCollectionPath}/${doc.id}`).ref;
				const sourceCollectionDoc = (await sourceCollectionRef.get()).data();

				return {
					active,
					id,
					...sourceCollectionDoc,
				};
			});

			return Promise.all(getSourceCollectionPromises);
		} catch (error) {
			console.error(error);
		}
	}

	getLetterOfDemandAccount(entityId, accountId) {
		const getAccountDetails = new Promise(res => {
			this.afStore
				.collection('entities')
				.doc(entityId)
				.collection('fin')
				.doc('debtors')
				.collection('list')
				.doc(accountId)
				.ref.get()
				.then(doc => {
					res({ id: doc.id, ...doc.data() });
				});
		});
		const getAccountRegisteredOwner = new Promise((res, rej) => {
			const registeredOwners = [];
			this.afStore
				.collection('entities')
				.doc(entityId)
				.collection('fin')
				.doc('debtors')
				.collection('list')
				.doc(accountId)
				.collection('registered_owners')
				.ref.get()
				.then(docs => {
					let ownerCount = 0;
					if (docs.size > 0) {
						docs.forEach(doc => {
							this.afStore
								.collection('entities')
								.doc(entityId)
								.collection('registered_owners')
								.doc(doc.id)
								.ref.get()
								.then(ownerDoc => {
									registeredOwners.push({ id: ownerDoc.id, ...ownerDoc.data() });
									ownerCount++;
									if (ownerCount === docs.size) {
										res(registeredOwners);
									}
								});
						});
					} else {
						rej('No Registered Owners found.');
					}
				});
		});
		const getAccountUsers = new Promise((res, rej) => {
			const users = [];
			let usersCount = 0;
			this.afStore
				.collection('entities')
				.doc(entityId)
				.collection('fin')
				.doc('debtors')
				.collection('list')
				.doc(accountId)
				.collection('users')
				.ref.get()
				.then(docs => {
					if (docs.size > 0) {
						docs.forEach(doc => {
							this.afStore
								.collection('users')
								.doc(doc.id)
								.ref.get()
								.then(userDoc => {
									let user = userDoc.data() as User;
									if (doc.data().type) {
										delete user.type;
										users.push({ id: userDoc.id, ...user, type: doc.data().type });
									} else {
										users.push({ id: userDoc.id, ...user });
									}
									usersCount++;
									if (usersCount === docs.size) {
										res(users);
									}
								});
						});
					} else {
						rej('No users found.');
					}
				});
		});
		const getAccountProperties = new Promise((res, rej) => {
			const properties = [];
			this.afStore
				.collection('entities')
				.doc(entityId)
				.collection('fin')
				.doc('debtors')
				.collection('list')
				.doc(accountId)
				.collection('properties')
				.ref.get()
				.then(docs => {
					if (docs.size > 0) {
						docs.forEach(doc => {
							properties.push({ id: doc.id, ...doc.data() });
						});
						res(properties);
					} else {
						rej('No properties found.');
					}
				});
		});

		return Promise.all([getAccountDetails, getAccountRegisteredOwner, getAccountUsers, getAccountProperties])
			.then(results => {
				return Promise.resolve({
					users: results[2],
					registeredOwners: results[1],
					account: results[0],
					properties: results[3],
					entityId: entityId,
				});
			})
			.catch(err => {
				console.log('FinService -> getLetterOfDemandAccount -> err', err);
				return Promise.reject(err);
			});
	}

	getDebtorAccount(accountId: string) {
		const accountRef = this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('debtors').collection('list').doc(accountId);

		return accountRef.valueChanges().pipe(
			map((account: Account) => {
				return {
					...account,
					id: accountId,
				};
			})
		);
	}

	getDebtRequests(accountId: string) {
		return this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('debtors').collection('list').doc(accountId).collection('debit_orders').valueChanges();
	}

	debtorAccountExistsCheck(accountName: string) {
		let collref = this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('debtors').collection('list').ref;
		let queryref = collref.where('name', '==', accountName);
		return queryref.get().then(snapShot => {
			if (snapShot.empty) {
				return Promise.resolve();
			} else {
				return Promise.reject('An account with that name already exists');
			}
		});
	}

	linkUserAndAccount(accountId, userId) {
		const associateAccountToUser = this.getDebtorAccount(accountId).subscribe((accountDetails: Account) => {
			accountDetails['id'] = accountId;
			return this.usersService.addAccountToUser(userId, accountDetails);
		});
		const associateUserToAccount = this.usersService.fetchUser(userId).subscribe(userDetails => {
			return this.addUserToAccount(userId, accountId, userDetails);
		});

		return Promise.all([associateAccountToUser, associateUserToAccount]);
	}

	linkPropertyAndAccount(accountId, propertyId) {
		const associateAccountToProperty = this.getDebtorAccount(accountId).subscribe(accountDetails => {
			accountDetails['id'] = accountId;
			return this.propertiesService.addAccountToProperty(propertyId, accountDetails);
		});
		const associatePropertyToAccount = this.propertiesService.fetchPropertyDetails(propertyId).subscribe(propertyDetails => {
			return this.addPropertyToAccount(propertyId, accountId, propertyDetails);
		});

		return Promise.all([associateAccountToProperty, associatePropertyToAccount]);
	}

	linkRegisteredOwnerAndAccount(accountId, regOwnerId) {
		const associateAccountToRegOwner = this.getDebtorAccount(accountId).subscribe(accountDetails => {
			accountDetails['id'] = accountId;
			return this.registeredOwnersService.addAccountToRegOwner(regOwnerId, accountDetails);
		});
		const associateRegOwnerToAccount = this.registeredOwnersService.fetchRegisteredOwner(regOwnerId).subscribe(regOwnerDetails => {
			return this.addRegOwnerToAccount(regOwnerId, accountId, regOwnerDetails);
		});

		return Promise.all([associateAccountToRegOwner, associateRegOwnerToAccount]);
	}

	getActiveDebtorsAccountsForEntity() {
		this.accountsCollection = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list', ref => ref.orderBy('name', 'asc').where('active', '==', true));
		this.accounts = this.accountsCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Account;
					data.id = a.payload.doc.id;
					data.value = a.payload.doc.id; // FOR DROPDOWNS
					data.label = data.name; // FOR DROPDOWNS
					return data;
				});
			})
		);

		return this.accounts;
	}

	getAccountEntriesForDebtorsAccount(accountId) {
		this.accountEntriesCollection = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('entries');
		// .collection("entries", ref => ref.where("created", ">=", startDate).where("created", "<=", endDate));
		this.accountEntries = this.accountEntriesCollection.valueChanges({ idField: 'id' });
		// this.accountEntries = this.accountEntriesCollection.snapshotChanges().pipe(map(changes => {
		//     return changes.map(a => {
		//         const data = a.payload.doc.data() as AccountEntry;
		//         data.id = a.payload.doc.id;
		//         return data;
		//     });
		// }));
		return this.accountEntries;
	}

	addUserToAccount(userId, accountId, userDetails) {
		const userDetailsAssoc = {} as AccountUser;
		userDetailsAssoc.firstname = userDetails.firstname;
		userDetailsAssoc.surname = userDetails.surname;
		userDetailsAssoc.email = userDetails.email;
		userDetailsAssoc.type = '';
		userDetailsAssoc.userId = userId;
		userDetailsAssoc.active = true;
		userDetailsAssoc.created = new Date();
		userDetailsAssoc.createdBy = this.loggedInUser;

		if (this.entityId) {
			try {
				this.addAuditDetails('added', userDetails, accountId);
			} catch (error) {
				console.error(error);
			}
		}

		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('users')
			.doc(userId)
			.set(userDetailsAssoc, { merge: true });
	}

	addAuditDetails(type: 'added' | 'remove', userDetails: FixMeAny, accountId: string) {
		const auditDetails = {
			category: 'debtors',
			createdBy: this.currentUser.uid ? this.currentUser.uid : '',
			firstname: this.currentUser.firstname ? this.currentUser.firstname : '',
			surname: this.currentUser.surname ? this.currentUser.surname : '',
			description: `Account Updated - User ${userDetails.firstname} ${userDetails.surname} was added to the account`,
			created: Date.now(),
			id: accountId,
			type: 'added',
		};

		if (type === 'remove') {
			auditDetails.description = `Account Updated - User ${userDetails.firstname} ${userDetails.surname} was removed from the account`;
			auditDetails.type = 'remove';
		}

		const auditTrailCollection = this.afStore.collection(`entities/${this.entityId}/auditTrail`);
		auditTrailCollection.add(auditDetails);
	}

	updateUserAccountType(userId, accountId, value, user?) {
		console.log('adding user and audit log....');
		const userDetailsAssoc = {} as AccountUser;
		userDetailsAssoc.type = value.addType;

		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('users')
			.doc(userId)
			.set(userDetailsAssoc, { merge: true });
	}

	removeUserAccountType(userId, accountId) {
		const userDetailsAssoc = {} as AccountUser;
		userDetailsAssoc.type = '';

		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('users')
			.doc(userId)
			.set(userDetailsAssoc, { merge: true });
	}

	removeUserFromAccount(userId: string, accountId: string, user: User) {
		if (this.entityId) {
			this.addAuditDetails('remove', user, accountId);

			return this.afStore
				.collection('entities')
				.doc(this.entityId)
				.collection('fin')
				.doc('debtors')
				.collection('list')
				.doc(accountId)
				.collection('users')
				.doc(userId)
				.set({ active: false }, { merge: true });
		}
	}

	getUsersForAccount(accountId) {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('users', ref => ref.orderBy('firstname', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	getAllUsersForAccount(accountId) {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('users', ref => ref.orderBy('firstname', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	async fetchAccountUsers(accountId) {
		return (
			await this.afStore
				.collection('entities')
				.doc(this.entityId)
				.collection('fin')
				.doc('debtors')
				.collection('list')
				.doc(accountId)
				.collection('users')
				.ref.where('active', '==', true)
				.get()
		).docs.map(user => {
			return {
				id: user.id,
				...user.data(),
			};
		});
	}

	getUserData(userId) {
		return this.afStore.doc(`users/${userId}`).ref.get();
	}

	getAcountPropertyPrimary(accountId) {
		return new Promise(res => {
			this.afStore
				.collection('entities')
				.doc(this.entityId)
				.collection('fin')
				.doc('debtors')
				.collection('list')
				.doc(accountId)
				.collection('properties')
				.ref.where('active', '==', true)
				.get()
				.then(propertyDocs => {
					propertyDocs.forEach(propertyDoc => {
						this.afStore
							.collection('entities')
							.doc(this.entityId)
							.collection('properties')
							.doc(propertyDoc.id)
							.collection('users')
							.ref.where('type', '==', 'primary')
							.get()
							.then((primaryUserDocs: any) => {
								if (primaryUserDocs.size > 0) {
									const primaryUsers = [];
									primaryUserDocs.forEach(userDoc => {
										primaryUsers.push({ ...userDoc.data(), uid: userDoc.id });
									});
									res(primaryUsers);
								} else {
									res('');
								}
							});
					});
				});
		});
	}

	fetchAcountUserDetails(accountId) {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('users', ref => ref.where('active', '==', true))
			.valueChanges({ idField: 'uid' });
	}

	fetchAcountRegOwner(accountId: string) {
		const accountRegOwnerRef = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('registered_owners', ref => ref.orderBy('created', 'desc').where('active', '==', true));

		return accountRegOwnerRef.valueChanges().pipe(
			map((regOwner: any) => {
				return {
					regOwner,
				};
			})
		);
	}

	getAccountUser(userId) {
		return this.afStore.doc(`users/${userId}`).valueChanges();
	}

	addPropertyToAccount(propertyId, accountId, propertyDetails: Property) {
		const propertyAssoc = {
			...propertyDetails,
			active: true,
			created: new Date(),
			createdBy: this.loggedInUser,
		} as AccountProperty;

		//add property audit log
		const auditDetails = {
			category: 'debtors',
			createdBy: this.currentUser.uid ? this.currentUser.uid : '',
			firstname: this.currentUser.firstname ? this.currentUser.firstname : '',
			surname: this.currentUser.surname ? this.currentUser.surname : '',
			description: 'Account Updated - Property ' + propertyDetails.property_number + ' was added to the account',
			id: accountId,
			created: Date.now(),
			type: 'added',
		};

		if (this.entityId) {
			const auditTrailCollection = this.afStore.collection(`entities/${this.entityId}/auditTrail`);
			auditTrailCollection.add(auditDetails);
		}

		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('properties')
			.doc(propertyId)
			.set(propertyAssoc, { merge: true });
	}

	getPropertiesForAccount(accountId) {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('properties', ref => ref.orderBy('door_number', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	getAllPropertiesForAccount(accountId, entityId?) {
		return this.afStore
			.collection('entities')
			.doc(entityId || this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('properties', ref => ref.orderBy('door_number', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	removePropertyFromAccount(propertyId, accountId, property?: Property) {
		// //add property audit log
		const auditDetails = {
			category: 'debtors',
			createdBy: this.currentUser.uid ? this.currentUser.uid : '',
			firstname: this.currentUser.firstname ? this.currentUser.firstname : '',
			surname: this.currentUser.surname ? this.currentUser.surname : '',
			description: 'Account Updated - Property ' + property.property_number + ' was removed from the account',
			id: accountId,
			created: Date.now(),
			type: 'remove',
		};

		if (this.entityId) {
			const auditTrailCollection = this.afStore.collection(`entities/${this.entityId}/auditTrail`);
			auditTrailCollection.add(auditDetails);
		}

		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('properties')
			.doc(propertyId)
			.set({ active: false }, { merge: true });
	}

	addRegOwnerToAccount(regOwnerId, accountId, regOwnerDetails) {
		const regOwnerAssoc = {} as AccountRegOwner;
		regOwnerAssoc.name = regOwnerDetails.name;
		regOwnerAssoc.regOwnerId = regOwnerId;
		regOwnerAssoc.active = true;
		regOwnerAssoc.created = new Date();
		regOwnerAssoc.createdBy = this.loggedInUser;

		//add reg owner audit log
		const auditDetails = {
			category: 'debtors',
			createdBy: this.currentUser.uid ? this.currentUser.uid : '',
			firstname: this.currentUser.firstname ? this.currentUser.firstname : '',
			surname: this.currentUser.surname ? this.currentUser.surname : '',
			description: 'Account Updated - Registered Owner ' + regOwnerDetails.name + ' was added to the account',
			id: accountId,
			created: Date.now(),
			type: 'added',
		};

		if (this.entityId) {
			const auditTrailCollection = this.afStore.collection(`entities/${this.entityId}/auditTrail`);
			auditTrailCollection.add(auditDetails);
		}

		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('registered_owners')
			.doc(regOwnerId)
			.set(regOwnerAssoc, { merge: true });
	}

	getRegOwnerForAccount(accountId) {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('registered_owners', ref => ref.orderBy('name', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	getAllRegOwnerForAccount(accountId) {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('registered_owners', ref => ref.orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	removeRegOwnerFromAccount(regOwnerId: string, accountId: string, owner: RegOwner) {
		// add reg owner audit log
		const auditDetails = {
			category: 'debtors',
			createdBy: this.currentUser.uid ? this.currentUser.uid : '',
			firstname: this.currentUser.firstname ? this.currentUser.firstname : '',
			surname: this.currentUser.surname ? this.currentUser.surname : '',
			description: 'Account Updated - Registered Owner ' + owner.name + ' was removed from the account',
			id: accountId,
			created: Date.now(),
			type: 'remove',
		};

		if (this.entityId) {
			const auditTrailCollection = this.afStore.collection(`entities/${this.entityId}/auditTrail`);
			auditTrailCollection.add(auditDetails);
		}

		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('debtors')
			.collection('list')
			.doc(accountId)
			.collection('registered_owners')
			.doc(regOwnerId)
			.set({ active: false }, { merge: true });
	}

	// BUDGETS

	getCurrentBudget(budgetId) {
		return this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('budgets').collection('list').doc(budgetId).valueChanges();
	}

	getBudgets() {
		return this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('budgets')
			.collection('list', ref => ref.orderBy('created', 'desc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	getCurrentBudgetEntries(budgetId) {
		const recoveriesAccounts = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('budgets')
			.collection('list')
			.doc(budgetId)
			.collection('recoveries', ref => ref.orderBy('created', 'asc'))
			.valueChanges({ idField: 'id' });

		const expenseAccounts = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('budgets')
			.collection('list')
			.doc(budgetId)
			.collection('expenses', ref => ref.orderBy('created', 'asc'))
			.valueChanges({ idField: 'id' });

		const transferReserveAccounts = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('budgets')
			.collection('list')
			.doc(budgetId)
			.collection('transferReserves', ref => ref.orderBy('created', 'asc'))
			.valueChanges({ idField: 'id' });

		const reserveFundAccounts = this.afStore
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('budgets')
			.collection('list')
			.doc(budgetId)
			.collection('reserveFund', ref => ref.orderBy('created', 'asc'))
			.valueChanges({ idField: 'id' });

		return combineLatest(recoveriesAccounts, expenseAccounts, transferReserveAccounts, reserveFundAccounts);
	}

	removeBudget(budgetId) {
		return this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('budgets').collection('list').doc(budgetId).set({ active: false }, { merge: true });
	}

	updateBudgetStatus(budgetData) {
		return this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('budgets').collection('list').doc(budgetData.details.id).update(budgetData['details']);
	}

	updateBudgetDetails(budgetData) {
		return this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('budgets').collection('list').doc(budgetData.details.id).update(budgetData['details']);
	}

	updateBudget(budgetData) {
		let recoveriesToUpdate = [];
		let recoveriesToAdd = [];
		let expensesToUpdate = [];
		let expensesToAdd = [];
		let transferReservesToUpdate = [];
		let transferReservesToAdd = [];
		let totalreserveFundToUpdate = [];
		let totalreserveFundToAdd = [];
		// 2
		const updateCurrentBudget = previousCurrentBudget =>
			new Promise((res, rej) => {
				if (previousCurrentBudget) {
					this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(previousCurrentBudget)
						.update(budgetData['details'])
						.then(() => res())
						.catch(error => rej(error));
				} else {
					rej('Cannot resolve budget ID');
				}
			});

		const sortRecoveriesExpenses = budgetId =>
			new Promise((res, rej) => {
				let recoveriesRef = this.afStore
					.collection('entities')
					.doc(this.entityId)
					.collection('fin')
					.doc('budgets')
					.collection('list')
					.doc(budgetId)
					.collection('recoveries')
					.valueChanges({ idField: 'id' });

				let expensesRef = this.afStore
					.collection('entities')
					.doc(this.entityId)
					.collection('fin')
					.doc('budgets')
					.collection('list')
					.doc(budgetId)
					.collection('expenses')
					.valueChanges({ idField: 'id' });

				// let transferReservesRef = this.afStore
				//     .collection("entities")
				//     .doc(entityId)
				//     .collection("fin")
				//     .doc("budgets")
				//     .collection("list")
				//     .doc(budgetId)
				//     .collection('transferReserves').valueChanges({ idField: 'id' });

				// let reserveFundRef = this.afStore
				//     .collection("entities")
				//     .doc(entityId)
				//     .collection("fin")
				//     .doc("budgets")
				//     .collection("list")
				//     .doc(budgetId)
				//     .collection('reserveFund').valueChanges({ idField: 'id' });

				combineLatest(recoveriesRef, expensesRef)
					.pipe(take(1))
					.subscribe(data => {
						let totalRecoveries = [...budgetData.recoveries, ...data[0]];
						let totalExpenses = [...budgetData.expenses, ...data[1]];
						let totalTransferReserves = [...budgetData.transferReserves];
						let totalreserveFund = [...budgetData.reserveFund];

						//remove duplicates from arrays
						const totalRecoveriesFiltered = Array.from(new Set(totalRecoveries.map(a => a.id))).map(id => {
							return totalRecoveries.find(a => a.id === id);
						});
						const totalExpensesFiltered = Array.from(new Set(totalExpenses.map(a => a.id))).map(id => {
							return totalExpenses.find(a => a.id === id);
						});

						// filter out any entries that have an ID
						recoveriesToUpdate = totalRecoveriesFiltered.filter((item: any) => item.id !== undefined);
						recoveriesToAdd = totalRecoveries.filter((item: any) => item.id === undefined);

						expensesToUpdate = totalExpensesFiltered.filter((item: any) => item.id !== undefined);
						expensesToAdd = totalExpenses.filter((item: any) => item.id === undefined);

						transferReservesToUpdate = totalTransferReserves.filter((item: any) => item.id !== undefined);
						transferReservesToAdd = totalTransferReserves.filter((item: any) => item.id === undefined);

						totalreserveFundToUpdate = totalreserveFund.filter((item: any) => item.id !== undefined);
						totalreserveFundToAdd = totalreserveFund.filter((item: any) => item.id === undefined);
						res();
					});
			});

		// 4
		const saveRecoveriesToBudget = budgetId =>
			new Promise((res, rej) => {
				recoveriesToUpdate.map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('recoveries')
						.doc(entry['id'])
						.update(entry);
				});
				recoveriesToAdd.map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('recoveries')
						.add(entry);
				});
				res();
				//Promise.all([recoveriesToUpdate, recoveriesToAdd]).then(() => res());
			});

		// 5
		const saveExpensesToBudget = budgetId =>
			new Promise((res, rej) => {
				expensesToUpdate.map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('expenses')
						.doc(entry['id'])
						.update(entry);
				});
				expensesToAdd.map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('expenses')
						.add(entry);
				});
				res();
				//Promise.all([expensesToUpdate, expensesToAdd]).then(() => res());
			});

		// 6
		const saveTransferReservesToBudget = budgetId =>
			new Promise((res, rej) => {
				transferReservesToUpdate.map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('transferReserves')
						.doc(entry['id'])
						.update(entry);
				});
				transferReservesToAdd.map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('transferReserves')
						.add(entry);
				});
				res();
				//Promise.all([transferReservesToUpdate, transferReservesToAdd]).then(() => res());
			});

		// 7
		const saveReserveFundToBudget = budgetId =>
			new Promise((res, rej) => {
				totalreserveFundToUpdate.map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('reserveFund')
						.doc(entry['id'])
						.update(entry);
				});
				totalreserveFundToAdd.map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('reserveFund')
						.add(entry);
				});
				res();
				//Promise.all([totalreserveFundToUpdate, totalreserveFundToAdd]).then(() => res());
			});

		const handlePromises = async () => {
			const updateCurrentBudgetPromise = await updateCurrentBudget(budgetData.details.id);
			const sortRecoveriesExpensesPromise = await sortRecoveriesExpenses(budgetData.details.id);
			const saveRecoveriesToBudgetPromise = await saveRecoveriesToBudget(budgetData.details.id);
			const saveExpensesToBudgetPromise = await saveExpensesToBudget(budgetData.details.id);
			const saveReserveFundToBudgetPromise = await saveReserveFundToBudget(budgetData.details.id);
			const saveTransferReservesToBudgetPromise = await saveTransferReservesToBudget(budgetData.details.id);
			return Promise.all([
				updateCurrentBudgetPromise,
				sortRecoveriesExpensesPromise,
				saveRecoveriesToBudgetPromise,
				saveExpensesToBudgetPromise,
				saveTransferReservesToBudgetPromise,
				saveReserveFundToBudgetPromise,
			]);
		};
		return handlePromises();
	}

	removeEntriesFromBudget(budget) {
		const budgetsRef = this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('budgets').collection('list').doc(budget['budgetId']);
		if (budget['type'] === 'recoveriesGrid') {
			// remove entries from recoveries
			budget['entries'].map(entry => {
				budgetsRef.collection('recoveries').doc(entry['id']).delete();
			});
			return Promise.resolve();
		} else {
			if (budget['type'] === 'expensesGrid') {
				// remove entries from expenses
				budget['entries'].map(entry => {
					budgetsRef.collection('expenses').doc(entry['id']).delete();
				});
				return Promise.resolve();
			}
		}
		return Promise.resolve();
	}

	//SIMPLE BUDGET
	saveSimpleBudget(budgetData) {
		// 1
		const getpreviousCurrentBudget = () =>
			new Promise((res, rej) => {
				return this.afStore
					.collection('entities')
					.doc(this.entityId)
					.collection('fin')
					.doc('budgets')
					.collection('list', ref => ref.where('current', '==', true))
					.valueChanges({ idField: 'id' })
					.pipe(take(1))
					.subscribe(previousCurrentBudget => res(previousCurrentBudget[0]));
			});

		// 2
		const updateCurrentBudget = previousCurrentBudget =>
			new Promise((res, rej) => {
				if (previousCurrentBudget) {
					this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(previousCurrentBudget['id'])
						.update({ current: false })
						.then(() => res())
						.catch(error => rej(error));
				} else {
					res();
				}
			});

		// 3
		const saveBudget = () =>
			new Promise((res, rej) => {
				budgetData['details']['created'] = new Date();
				budgetData['details']['current'] = true;
				this.afStore
					.collection('entities')
					.doc(this.entityId)
					.collection('fin')
					.doc('budgets')
					.collection('list')
					.add(budgetData['details'])
					.then(result => res(result.id))
					.catch(error => rej(error));
			});

		// 4
		const saveRecoveriesToBudget = budgetId =>
			new Promise((res, rej) => {
				budgetData['recoveries'].map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('recoveries')
						.add(entry);
				});
				res();
			});

		// 5
		const saveExpensesToBudget = budgetId =>
			new Promise((res, rej) => {
				budgetData['expenses'].map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('expenses')
						.add(entry);
				});
				res();
			});

		// 6
		const saveTotalsToBudget = budgetId =>
			new Promise((res, rej) => {
				budgetData['totals'].map(entry => {
					return this.afStore.collection('entities').doc(this.entityId).collection('fin').doc('budgets').collection('list').doc(budgetId).collection('totals').add(entry);
				});
				res();
			});

		// 7
		const saveTransferReservesToBudget = budgetId =>
			new Promise((res, rej) => {
				budgetData['transferReserves'].map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('transferReserves')
						.add(entry);
				});
				res();
			});

		// 8
		const saveReserveFundToBudget = budgetId =>
			new Promise((res, rej) => {
				budgetData['reserveFund'].map(entry => {
					return this.afStore
						.collection('entities')
						.doc(this.entityId)
						.collection('fin')
						.doc('budgets')
						.collection('list')
						.doc(budgetId)
						.collection('reserveFund')
						.add(entry);
				});
				res();
			});

		const handlePromises = async () => {
			const getpreviousCurrentBudgetPromise = await getpreviousCurrentBudget();
			const updateCurrentBudgetPromise = await updateCurrentBudget(getpreviousCurrentBudgetPromise);
			const saveBudgetPromise = await saveBudget();
			const saveRecoveriesToBudgetPromise = await saveRecoveriesToBudget(saveBudgetPromise);
			const saveExpensesToBudgetPromise = await saveExpensesToBudget(saveBudgetPromise);
			const saveTotalsToBudgetPromise = await saveTotalsToBudget(saveBudgetPromise);
			const saveTransferReservesToBudgetPromise = await saveTransferReservesToBudget(saveBudgetPromise);
			const saveReserveFundToBudgetPromise = await saveReserveFundToBudget(saveBudgetPromise);
			return Promise.all([
				getpreviousCurrentBudgetPromise,
				updateCurrentBudgetPromise,
				saveBudgetPromise,
				saveRecoveriesToBudgetPromise,
				saveExpensesToBudgetPromise,
				saveTotalsToBudgetPromise,
				saveTransferReservesToBudgetPromise,
				saveReserveFundToBudgetPromise,
			]);
		};
		return handlePromises();
		// new Promise((res, rej) => {
		//     let count = 0;
		//     budgetData.forEach((account, index) => {
		//         console.log("TCL: FinService -> saveSimpleBudget -> index", index, account)
		//         this.afStore
		//             .collection("entities")
		//             .doc(entityId)
		//             .collection("fin")
		//             .doc("budgets")
		//             .collection("sections")
		//             .doc(section)
		//             .collection("accounts")
		//             .doc(index)
		//             .set(account, { merge: true });
		//     });
		// })
	}

	fetchAssociatedTasks(accountId: string, taskType?: string) {
		const arrearTasks = this.afStore
			.collection(`entities/${this.entityId}/tasks`, ref =>
				ref.where('active', '==', true).where('entityId', '==', this.entityId).where('accountNo', '==', accountId).where('taskType', '==', 'Arrears')
			)
			.valueChanges({ idField: 'id' });

		const transferTasks = this.afStore
			.collection(`entities/${this.entityId}/tasks`, ref =>
				ref.where('active', '==', true).where('entityId', '==', this.entityId).where('accountNo', '==', accountId).where('taskType', '==', 'Transfer')
			)
			.valueChanges({ idField: 'id' });

		const insuranceTasks = this.afStore
			.collection(`entities/${this.entityId}/tasks`, ref =>
				ref.where('active', '==', true).where('entityId', '==', this.entityId).where('accounts', 'array-contains', accountId).where('taskType', '==', 'Insurance')
			)
			.valueChanges({ idField: 'id' });

		return combineLatest(arrearTasks, transferTasks, insuranceTasks);
	}

	addFileLogToAccount(accountId?: string, type?: string, data?: FixMeAny) {
		if (this.entityId) {
			const details = type === 'Added' ? 'was added to the' : 'was removed from the';

			const auditDetails = {
				category: 'debtors',
				createdBy: this.currentUser.uid ? this.currentUser.uid : '',
				firstname: this.currentUser.firstname ? this.currentUser.firstname : '',
				surname: this.currentUser.surname ? this.currentUser.surname : '',
				description: `Debtors account updated - ${data.name}: ${data.filetype} ${details} account`,
				created: Date.now(),
				id: accountId,
				type: type.toLocaleLowerCase(),
			};
			const auditTrailCollection = this.afStore.collection(`entities/${this.entityId}/auditTrail`);
			auditTrailCollection.add(auditDetails);
		}
	}
}
