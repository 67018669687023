import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { MReportsService } from '../../m-reports.service';
import { SchemesService } from '../../../entities/entities.service';
import { Entity } from '../../../../management/entities/entities.model';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

declare const moment: any;
declare const toastr: any;
declare const $: any;

@Component({
	selector: 'app-accounts-report',
	templateUrl: './accounts-report.component.html',
	styleUrls: ['./accounts-report.component.scss'],
})
export class AccountsReportComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	trailBalanceForm: FormGroup;
	isLoadingTrialBalance = false;
	isLoadingGeneralLedger = false;
	date;
	monthsList = [];
	currentMonth;
	items: FormArray;
	entity: any;
	entitySubscription: Subscription;
	isLoadingTermsAndConditions = false;
	entityId: string;

	constructor(private mReportsService: MReportsService, private formBuilder: FormBuilder, private schemeService: SchemesService, private store: Store) {}

	ngOnInit() {
		this.store
			.select(selectEntityId)
			.pipe(take(1))
			.subscribe(id => {
				this.entityId = id;
			});

		this.currentMonth = moment().format('M YYYY');
		this.date = moment().format('YYYY-MM-DD');

		this.trailBalanceForm = this.formBuilder.group({
			month: [this.currentMonth, [Validators.required]],
		});

		this.entitySubscription = this.schemeService.getEntityDetails(this.entityId).subscribe((entity: Entity) => {
			this.entity = entity;
		});

		let lastMonth = moment().subtract(8, 'months');
		for (let i = 0; i <= 8; i++) {
			this.monthsList.unshift({ name: moment(lastMonth).add(i, 'months').format('MMMM YYYY'), value: moment(lastMonth).add(i, 'months').format('M YYYY') });
		}
	}

	toggleModal() {
		if (this.isLoadingTrialBalance) {
			$('#trial_balance_modal').modal({ backdrop: 'static', keyboard: false });
		}
	}

	async generateTrialBalance() {
		this.isLoadingTrialBalance = true;
		if (this.trailBalanceForm.valid) {
			const { result }: FixMeAny = await this.mReportsService.getOldPastelTrialBalance(
				this.entity.whitfieldsPrefix,
				this.trailBalanceForm.value['month'],
				this.entity['financial_year_end']
			);

			const {
				categories: { IncomeExpenses = [], BalanceSheet = [] },
				complex,
				beginDate,
				endDate,
				finalIncomeExpensesDebitTotal,
				finalIncomeExpensesCreditTotal,
				finalBalanceSheetDebitTotal,
				finalBalanceSheetCreditTotal,
			} = result;

			const build = [
				{ account: 'Trial Balance - ' + complex + ' (' + beginDate + ' - ' + endDate + ')', debitTotal: '', creditTotal: '' },
				{ account: 'Income Statement', debitTotal: '', creditTotal: '' },
				{ account: 'Account', debitTotal: 'Debit', creditTotal: 'Credit' },
				...IncomeExpenses.accounts.map(({ account, debitTotal, creditTotal }: any) => new Object({ account, debitTotal, creditTotal })),
				{ account: '', debitTotal: finalIncomeExpensesDebitTotal, creditTotal: finalIncomeExpensesCreditTotal },
				{ account: '', debitTotal: '', creditTotal: '' },
				{ account: 'Balance Sheet', debitTotal: '', creditTotal: '' },
				{ account: 'Account', debitTotal: 'Debit', creditTotal: 'Credit' },
				...BalanceSheet.accounts.map(({ account, debitTotal, creditTotal }: any) => new Object({ account, debitTotal, creditTotal })),
				{ account: '', debitTotal: finalBalanceSheetDebitTotal, creditTotal: finalBalanceSheetCreditTotal },
			];

			new ngxCsv(build, `${this.entity.whitfieldsPrefix}_Trial_Balance_${moment().format('YYYY-MM-DD')}`);
			this.isLoadingTrialBalance = false;
		} else {
			toastr.error('Invalid form. Please try again!');
			this.isLoadingTrialBalance = false;
		}
	}

	getUnique(arr, comp) {
		const unique = arr
			.map(e => e[comp])
			.map((e, i, final) => final.indexOf(e) === i && i)
			.filter(e => arr[e])
			.map(e => arr[e].type);
		return unique;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
