import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Note } from './note.model';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { ScriptLoaderService } from '../../../../../_services/script-loader.service';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';
import { NotesService } from './notes.service';
import { User } from '../users/user.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare const mUtil: any;

@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.css'],
})
export class NotesComponent implements OnInit, OnDestroy {
	notes: Note[];
	note: Note = {
		content: '',
	};
	currentUser: User;
	notesSubscription: Subscription;
	toggleNoteForm: boolean = false;
	loadingNotes: boolean = false;
	addingNote: boolean = false;
	noteDetails: FormGroup;
	@Input() notesTemplate: string = 'default';
	@Input() getRef: any;
	@Input() type: string;

	public perfectScrollbarConfig: any = {
		useBothWheelAxes: false,
		suppressScrollX: true,
	};

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private _script: ScriptLoaderService,
		private fb: FormBuilder,
		public auth: AuthenticationService,
		private notesService: NotesService
	) {}

	ngOnInit() {
		this.buildform();
		//FETCH LOGGED IN USER
		this.auth.user.subscribe(userDetails => {
			this.currentUser = userDetails;
		});

		//FETCH NOTES FOR USER
		if (this.getRef) {
			this.notesSubscription = this.notesService.fetchNotes(this.getRef).subscribe((notesData: any) => {
				this.notes = notesData.map(note => {
					console.log(typeof note.created);
					if (note.created_firstname && note.created_surname) {
						note.createdBy = `${note.created_firstname} ${note.created_surname}`;
					}
					if (typeof note.created !== 'object') {
						note.created = new Date(note.created);
					} else {
						note.created = note.created.toDate();
					}
					return note;
				});
				this.loadingNotes = true;
			});
		}
	}

	toggleNote() {
		this.toggleNoteForm = !this.toggleNoteForm;
	}

	sendNoteDetails(noteData) {
		const setUserId = sessionStorage.getItem('userId');
		const setName = sessionStorage.getItem('userFirstname');
		const setSurname = sessionStorage.getItem('userSurname');
		this.addingNote = true;
		this.note = {
			content: noteData.content,
			created_firstname: setName,
			created_surname: setSurname,
			createdby: setUserId,
			created: new Date(),
		};
		this.notesService.addNote(this.getRef, this.note, this.type).then(() => {
			this.addingNote = false;
			this.toggleNoteForm = !this.toggleNoteForm;
		});
	}

	buildform() {
		this.noteDetails = this.fb.group({
			content: ['', [Validators.required]],
		});
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.notesSubscription) this.notesSubscription.unsubscribe();
	}

	print() {
		const notes = document.getElementById('notes-component');
		const printWindow = window.open('', '', 'width=600,height=600');
		printWindow.document.write('<html><head><title>Print</title></head><body>');
		printWindow.document.write(notes.innerHTML);
		printWindow.document.write('</body></html>');
		printWindow.document.close();
		printWindow.print();
		printWindow.close();
	}
}
