import { Injectable } from '@angular/core';
import { environment } from './../../../../../../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from '../../../../../../auth/_services';
import { map, take } from 'rxjs/operators';
import { User } from '../../../plex/users/user.model';
import { SchemesService } from '../../entities/entities.service';
import { Router } from '@angular/router';
import { EntitiesService } from '../../../plex/entities/entities.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Entity } from '../../entities/entities.model';

interface UserEntity extends Entity {
	isMasterAdmin: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class AdvancedSearchService {
	user;
	constructor(
		public afs: AngularFirestore,
		private authService: AuthenticationService,
		private entitiesService: EntitiesService,
		private router: Router,
		private permissionsService: NgxPermissionsService
	) {}

	searchFromUserDetails(value) {
		return new Promise(resolve => {
			let resolveData = [];
			this.authService.user.pipe(take(1)).subscribe((user: User) => {
				this.user = user;
				let includeInactive = value['inactive'];
				let userEntitiesRef;
				let entitiesCount = 0;
				if (includeInactive) {
					userEntitiesRef = this.afs.collection(`users/${user.uid}/entities`);
				} else {
					userEntitiesRef = this.afs.collection(`users/${user.uid}/entities`, ref => ref.where('active', '==', true));
				}
				userEntitiesRef
					.valueChanges()
					.pipe(take(1))
					.subscribe(entities => {
						entities.forEach((entity: any) => {
							let ref;
							if (includeInactive) {
								ref = this.afs.collection(`entities/${entity.uid}/users`);
							} else {
								ref = this.afs.collection(`entities/${entity.uid}/users`, ref => ref.where('active', '==', true));
							}
							ref.valueChanges({ idField: 'uid' })
								.pipe(take(1))
								.subscribe((users: any) => {
									let tmpGlobalCount = 0;
									users.forEach((user: any) => {
										this.afs
											.collection(`entities/${entity.uid}/users/${user.uid}/accounts`)
											.valueChanges()
											.pipe(take(1))
											.subscribe(accountsData => {
												user['accounts'] = accountsData.length > 0 ? accountsData : [];
												tmpGlobalCount++;
												if (tmpGlobalCount === users.length) {
													let tmpName = [];
													let tmpSurname = [];
													let tmpCell = [];
													let tmpEmail = [];
													let tmpTel = [];
													let tmpAccountNumber = [];
													if (value['name'] !== '' && value['name'] !== null && value['name'] !== undefined) {
														tmpName = users.filter((item: any) => {
															return item.firstname.toLowerCase().includes(value['name'].toLowerCase());
														});
													}
													if (value['surname'] !== '' && value['surname'] !== null && value['surname'] !== undefined) {
														tmpSurname = users.filter((item: any) => {
															return item.surname.toLowerCase().includes(value['surname'].toLowerCase());
														});
													}
													if (value['cell'] !== '' && value['cell'] !== null && value['cell'] !== undefined) {
														tmpCell = users.filter((item: any) => {
															if (item.cell) {
																return item.cell.includes(value['cell']);
															}
														});
													}
													if (value['email'] !== '' && value['email'] !== null && value['email'] !== undefined) {
														tmpEmail = users.filter((item: any) => {
															return item.email.toLowerCase().includes(value['email'].toLowerCase());
														});
													}
													if (value['tel'] !== '' && value['tel'] !== null && value['tel'] !== undefined) {
														tmpTel = users.filter((item: any) => {
															if (item.tel) {
																return item.tel.includes(value['tel']);
															}
														});
													}
													if (value['accountNumber'] !== '' && value['accountNumber'] !== null && value['accountNumber'] !== undefined) {
														let count = 0;
														let tmpusersArr = [];
														for (let i = 0; i < users.length; i++) {
															if (users[i]['accounts'].length > 0) {
																let accountsData = users[i]['accounts'].filter((acc: any) => {
																	return acc.name.toLowerCase().includes(value['accountNumber'].toLowerCase());
																});
																if (accountsData.length > 0) {
																	users[i]['accounts'] = accountsData;
																	tmpusersArr.push(users[i]);
																}
															}
															count++;
															if (count === users.length) {
																tmpAccountNumber = tmpusersArr;
															}
														}
													}
													let final = [...tmpName, ...tmpSurname, ...tmpCell, ...tmpEmail, ...tmpAccountNumber, ...tmpTel];
													if (final.length > 0) {
														resolveData.push({ entityId: entity.uid, data: final, entityName: entity.name, prefix: entity.prefix });
													}

													if (entitiesCount === entities.length) {
														resolve(resolveData);
													}
												}
											});
									});
									entitiesCount++;
								});
						});
					});
			});
		}).then(data => {
			return data;
		});
	}

	searchFromAccountNumber(accountNumber: string) {
		// check if account number is longer than 7 characters
		let prefix = '';
		let type = '';
		if (accountNumber.length > 8) {
			// account is new firefly account and get the firs 7 characters
			prefix = accountNumber.match(/.{1,7}/g)[0];
			type = 'prefix';
		} else {
			prefix = accountNumber.match(/.{1,3}/g)[0];
			type = 'whitfieldsPrefix';
		}

		return this.afs
			.collection('/lookup/accounts/list')
			.ref.where(type, '==', prefix.toUpperCase())
			.where('active', '==', true)
			.orderBy('name', 'asc')
			.get()
			.then(accounts => {
				return accounts.docs
					.filter((item: any) => {
						const itemData = item.data();
						return itemData.name.toLowerCase().includes(accountNumber.toLowerCase());
					})
					.map(documentSnapshot => {
						const account = documentSnapshot.data();
						account['id'] = documentSnapshot.id;
						return account;
					});
			});
	}

	searchRegisteredOwners(name: string) {
		return this.afs
			.collection('lookup/registered_owners/list')
			.ref.get()
			.then(regOwners => {
				return regOwners.docs
					.filter((item: any) => {
						const itemData = item.data();
						return itemData.name.toLowerCase().includes(name.toLowerCase());
					})
					.map(documentSnapshot => {
						const regOwner = documentSnapshot.data();
						regOwner['id'] = documentSnapshot.id;
						return regOwner;
					});
			});
	}

	searchServiceProviders(name: string) {
		return this.afs
			.collection('public')
			.ref.where('active', '==', true)
			.get()
			.then(serviceProviders => {
				return serviceProviders.docs
					.filter((item: any) => {
						const itemData = item.data();
						return itemData.name.toLowerCase().includes(name.toLowerCase()) && itemData.entity_type == 'Service Provider';
					})
					.map(documentSnapshot => {
						const serviceProvider = documentSnapshot.data();
						serviceProvider['id'] = documentSnapshot.id;
						return serviceProvider;
					});
			});
	}

	async searchEmailAndAccountNumber(email: string, accountNumber: string) {
		const userId = await this.afs
			.collection('users')
			.ref.where('email', '==', email)
			.get()
			.then((users: any) => {
				return users.docs[0].id;
			});

		// check if account number is longer than 7 characters
		let prefix = '';
		let type = '';
		if (accountNumber.length > 8) {
			// account is new firefly account and get the firs 7 characters
			prefix = accountNumber.match(/.{1,7}/g)[0];
			type = 'prefix';
		} else {
			prefix = accountNumber.match(/.{1,3}/g)[0];
			type = 'whitfieldsPrefix';
		}
		return this.afs
			.collection('/lookup/accounts/list')
			.ref.where(type, '==', prefix.toUpperCase())
			.where('users', 'array-contains', userId)
			.where('active', '==', true)
			.orderBy('name', 'asc')
			.get()
			.then(accounts => {
				return accounts.docs
					.filter((item: any) => {
						const itemData = item.data();
						return itemData.name.toLowerCase().includes(accountNumber.toLowerCase());
					})
					.map(documentSnapshot => {
						const account = documentSnapshot.data();
						account['id'] = documentSnapshot.id;
						return account;
					});
			});
	}

	searchRegisteredOwner(value, entityId, inactive) {
		return new Promise(res => {
			let includeInActive = inactive;
			let ref;
			if (includeInActive) {
				ref = this.afs.collection(`entities/${entityId}/registered_owners`);
			} else {
				ref = this.afs.collection(`entities/${entityId}/registered_owners`, ref => ref.where('active', '==', true));
			}
			ref.valueChanges({ idField: 'uid' })
				.pipe(take(1))
				.subscribe((ownersData: any) => {
					let returnData = ownersData.filter((item: any) => {
						item['type'] = 'registeredOwners';
						item['registeredOwner'] = item['name'];
						if (item.name) return item.name.includes(value);
					});
					returnData.map((owner: any) => {
						this.afs
							.collection(`entities/${entityId}/registered_owners/${owner.uid}/accounts`)
							.valueChanges({ idField: 'uid' })
							.pipe(take(1))
							.subscribe((accountsData: any) => {
								owner.accounts = accountsData.length > 0 ? accountsData : [];
								return owner;
							});
					});
					res(returnData);
				});
		});
	}

	searchDoorNumber(value, entityId, inactive) {
		return new Promise(res => {
			let includeInActive = inactive;
			let ref;
			if (!includeInActive) {
				ref = this.afs.collection(`entities/${entityId}/properties`, ref => ref.where('active', '==', true));
			} else {
				ref = this.afs.collection(`entities/${entityId}/properties`);
			}
			ref.valueChanges({ idField: 'uid' })
				.pipe(take(1))
				.subscribe((propertiesData: any) => {
					let returnData = propertiesData.filter((item: any) => {
						item['type'] = 'propertiesDoor';
						item['doorNumber'] = item['door_number'];
						if (item.door_number) return item.door_number.includes(value);
					});
					returnData.map((prop: any) => {
						this.afs
							.collection(`entities/${entityId}/properties/${prop.uid}/accounts`)
							.valueChanges({ idField: 'uid' })
							.pipe(take(1))
							.subscribe((accountsData: any) => {
								prop.accounts = accountsData.length > 0 ? accountsData : [];
								return prop;
							});
					});
					res(returnData);
				});
		});
	}

	searchSectionStandNumber(value, entityId, inactive) {
		return new Promise(res => {
			let includeInActive = inactive;
			let ref;
			if (includeInActive) {
				this.afs.collection(`entities/${entityId}/properties`);
			} else {
				ref = this.afs.collection(`entities/${entityId}/properties`, ref => ref.where('active', '==', true));
			}
			ref.valueChanges({ idField: 'uid' })
				.pipe(take(1))
				.subscribe((propertiesData: any) => {
					let returnData = propertiesData.filter((item: any) => {
						item['type'] = 'propertiesSection';
						item['sectionStandNumber'] = item['section_stand_number'];
						if (item.section_stand_number) return item.section_stand_number.includes(value);
					});
					returnData.map((prop: any) => {
						this.afs
							.collection(`entities/${entityId}/properties/${prop.uid}/accounts`)
							.valueChanges({ idField: 'uid' })
							.pipe(take(1))
							.subscribe((accountsData: any) => {
								prop.accounts = accountsData.length > 0 ? accountsData : [];
								return prop;
							});
					});
					res(returnData);
				});
		});
	}

	fetchEntityDetails(entityId: String) {
		return new Promise(resolve => {
			this.afs
				.doc(`entities/${entityId}`)
				.ref.get()
				.then((entity: any) => {
					const entityData = { ...entity.data(), uid: entity.id };
					resolve(entityData);
				});
		});
	}

	async checkEntityUserExists(entityId, userId) {
		const entityUser = await this.afs.doc(`entities/${entityId}/users/${userId}`).get().toPromise();
		return entityUser.exists;
	}

	goToEntity(entityData, path) {
		return this.entitiesService.setEntity(entityData).then(() => {
			setTimeout(() => {
				this.router.navigate([path]);
			}, 500);
		});
	}

	filterAccountsSearchResults(data) {
		for (let i = 0; i < data.length; i++) {
			for (let j = 0; j < data[i]['data'].length; j++) {
				if (data[i]['data'][j]['type'] === 'propertiesDoor' || data[i]['data'][j]['type'] === 'propertiesSection') {
				}
			}
		}
	}

	async searchSchemesAndUsers(prefix: string, email: string) {
		const userId = await this.afs
			.collection('users')
			.ref.where('email', '==', email)
			.get()
			.then((users: any) => {
				return users.docs[0]?.id;
			});
		return this.afs
			.collection(`users/${userId}/entities`)
			.ref.where('product', '==', 'whitfields')
			.where('active', '==', true)
			.get()
			.then(entities => {
				return entities.docs
					.filter(entity => {
						const entityData = entity.data() as UserEntity;
						if (entityData.isMasterAdmin === true) {
							return false;
						}
						let found = false;
						if (entityData.prefix && entityData.prefix.toUpperCase().includes(prefix.toUpperCase())) {
							found = true;
						}
						if (entityData.whitfieldsPrefix && entityData.whitfieldsPrefix.toUpperCase().includes(prefix.toUpperCase())) {
							found = true;
						}
						if (entityData.name && entityData.name.toUpperCase().includes(prefix.toUpperCase())) {
							found = true;
						}
						return found;
					})
					.map(entity => {
						return {
							entityId: entity.id,
							entityData: entity.data(),
							user: {
								id: userId,
								email: email,
							},
						};
					});
			});
	}

	async searchByEmail(email: string) {
		try {
			const user = (await this.afs.collection<User>('users').ref.where('email', '==', email).get()).docs[0];
			if (user) {
				const userEntities = (
					await this.afs
						.collection<UserEntity>(`users/${user.id}/entities`)
						.ref.where('product', '==', environment.product)
						.where('active', '==', true)
						.orderBy('name', 'asc')
						.get()
				).docs;

				const data = [];
				for await (const userEntity of userEntities) {
					data.push({
						entityId: userEntity.id,
						entityData: userEntity.data(),
						user: {
							id: user.id,
							email,
						},
					});
				}

				return data;
			}
		} catch (error) {
			console.error(error);
		}
	}

	async fetchEntityAdditionalData(entityId: string, active: boolean) {
		const entity = await this.afs
			.doc(`entities/${entityId}`)
			.ref.get()
			.then(entity => {
				const entityData = entity.data();
				entityData['id'] = entity.id;
				entityData['uid'] = entity.id;
				return entityData;
			});

		return {
			entity,
		};
	}
}
