import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// TODO: Set diffrerent DSNs for different products
if (environment.useSentry) {
	Sentry.init({
		dsn: 'https://aa3fd956fb944c8b972762094ec592b2@o561556.ingest.sentry.io/4505471175098368',
		environment: environment.firebase.projectId.includes('live') ? 'production' : 'development',
		integrations: [
			new Sentry.BrowserTracing({
				// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled

				tracePropagationTargets: ['localhost:4200', /^https:\/\/firefly.whitfields\.co\.za/],
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
	});
}

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
