import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { ScriptLoaderService } from '../_services/script-loader.service';
import { Entity } from './pages/default/plex/entities/entities.model';
import { EntitiesService } from './pages/default/plex/entities/entities.service';
import { environment } from '../../environments/environment';
import { DataService } from '../_services/data.service';
import { config } from '../../configs/configs';
import { Helpers } from '../helpers';
import { selectEntityId } from '../_state/entity/entity.selectors';

declare let mApp: any;
declare let mUtil: any;
declare let mLayout: any;
declare const $: any;

@Component({
	selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
	templateUrl: './theme.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit, OnDestroy {
	entity: Entity;
	entityId: string;
	entitySubscription: Subscription;
	createTask: boolean;
	envType = environment.product;
	conf = config[this.envType];
	constructor(private _script: ScriptLoaderService, private _router: Router, private entitiesService: EntitiesService, public dataService: DataService, private store: Store) {}
	ngOnInit() {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
		const tmp = {
			plex: '/plex/entities',
			// 'whitfields': '/management/db/main',
			whitfields: '/whitfields/management/tasks/dashboard',
			sports: '/sports/sports/entities',
			serve: '/serve/serve/entities/list',
			faith: '/faith/faith/organisations',
			connect: '/connect',
			olympus: '/olympus/dashboard',
		};
		let redirectUrl;
		if (typeof tmp[this.envType] !== 'undefined') {
			if (this.entityId) {
				if (this.entityId !== 'whitfields' && this.envType === 'whitfields') {
					redirectUrl = `/${this.entityId}/management/db`;
				}
			}
			redirectUrl = tmp[this.envType];
		}

		const urlProductHandler = () => {
			if (this.entityId) {
				if (this.entityId && this._router.url === '/entities') {
					this._router.navigate([redirectUrl]);
				}
			} else if (environment.product !== 'olympus') {
				this._router.navigate([this.conf['entitiesUrl']]);
			}
		};
		urlProductHandler();

		this.dataService.currentCreation.subscribe(createTask => {
			this.createTask = createTask;
		});
		if (this.entityId) {
			this.fetchEntityDetails();
		}
		this._script.loadScripts('body', ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo3/base/scripts.bundle.js'], true).then(result => {
			Helpers.setLoading(false);
			// optional js to be loaded once
			// this._script.loadScripts('head', ['assets/vendors/custom/fullcalendar/fullcalendar.bundle.js'], true);
		});

		this._router.events.subscribe(route => {
			if (route instanceof NavigationStart) {
				if (this.entityId) {
					this.fetchEntityDetails();
				}
				(<any>mLayout).closeMobileAsideMenuOffcanvas();
				(<any>mLayout).closeMobileHorMenuOffcanvas();
				Helpers.setLoading(true);
				// hide visible popover
				(<any>$('[data-toggle="m-popover"]')).popover('hide');
			}
			if (route instanceof NavigationEnd) {
				// init required js
				(<any>mApp).init();
				(<any>mUtil).init();
				Helpers.setLoading(false);
				urlProductHandler();
				// content m-wrapper animation
				let isThing = sessionStorage.getItem('stepsIncomplete');
				const tabName = [
					`/${this.entityId}/entities/edit?tab=details`,
					`/${this.entityId}/entities/edit?tab=physical_address`,
					`/${this.entityId}/entities/edit?tab=financial_details`,
					`/${this.entityId}/entities/edit?tab=scheme_website`,
					`/${this.entityId}/entities/edit?tab=addons`,
					`/${this.entityId}/entities/edit?tab=representatives`,
				];
				if (isThing !== 'true' && tabName.indexOf(route.url) === -1) {
					let animation = 'm-animate-fade-in-up';
					$('.m-wrapper')
						.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function (e) {
							$('.m-wrapper').removeClass(animation);
						})
						.removeClass(animation)
						.addClass(animation);
				}
			}
		});
	}

	private fetchEntityDetails() {
		this.entitySubscription = this.entitiesService.getEntityDetails(this.entityId).subscribe(entityData => {
			this.entity = { ...entityData };
			if (this.entity) {
				sessionStorage.setItem('entity_prefix', this.entity.prefix);
			}
			if (this.entity && this.entity.domain) {
				this.entity.domain = `https://${this.entity.domain}.${environment.client}`;
			}
		});
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.entitySubscription.unsubscribe();
	}
}
