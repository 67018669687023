import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../auth/_services/authentication.service';
import { IMeeting, IMeetingUser } from './meetings.interface';
import { Store } from '@ngrx/store';
import { selectEntityId } from '../../../../_state/entity/entity.selectors';
import { User } from '@plex/users/user.model';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase';

@Injectable()
export class MeetingsService {
	entityId: string;
	loggedInUser: User;
	constructor(public afs: AngularFirestore, private auth: AuthenticationService, public router: Router, private store: Store, private functions: AngularFireFunctions) {
		this.store.select(selectEntityId).subscribe(entityId => {
			this.entityId = entityId;
		});
		this.auth.user.subscribe(user => {
			this.loggedInUser = user;
		});
	}

	getMeetings() {
		return this.afs.collection(`entities/${this.entityId}/meetings`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	getMeeting(meetingId) {
		return this.afs.doc(`entities/${this.entityId}/meetings/${meetingId}`).valueChanges({ idField: 'id' });
	}

	getMeetingUsers(meetingId) {
		return this.afs.collection(`entities/${this.entityId}/meetings/${meetingId}/users`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	getEntityManagementTeam() {
		return this.afs
			.collection(`entities/${this.entityId}/management/users/list`)
			.ref.where('active', '==', true)
			.get()
			.then(usersRef => {
				let users = [];
				usersRef.forEach(userDoc => {
					users.push(userDoc.data());
				});
				console.log(`CJ ~ result`, users);
			});
	}

	async addMeeting(meeting: IMeeting) {
		meeting.created = new Date();
		meeting.createdBy = this.loggedInUser.uid;
		meeting.active = true;
		const ref = await this.afs.collection(`entities/${this.entityId}/meetings`).add(meeting);
		meeting.id = ref.id;

		const user: IMeetingUser = {
			active: true,
			id: this.loggedInUser.uid,
			firstname: this.loggedInUser.firstname,
			surname: this.loggedInUser.surname,
			status: 'unconfirmed',
			email: this.loggedInUser.email,
			vote: '',
			cell: this.loggedInUser.cell || '',
		};

		await this.updateMeetingUser(meeting, user);

		// const managementUsers = await this.getManagement();
		// await managementUsers.forEach(async (userDoc: any) => {
		// 	const user = { ...userDoc.data(), id: userDoc.id };
		// 	if (user.isSchemeExecutive) {
		// 		const addUser: IMeetingUser = {
		// 			active: true,
		// 			id: user.uid,
		// 			firstname: user.firstname,
		// 			surname: user.surname,
		// 			status: 'unconfirmed',
		// 			email: user.email,
		// 			vote: '',
		// 			cell: user.cell || '',
		//			sendStatus: ''
		// 		};

		// 		await this.updateMeetingUser(meeting, addUser);
		// 	}
		// });

		this.addTempUsersToAdd(meeting);

		return ref.id;
	}

	async getManagement() {
		return this.afs.collection(`entities/${this.entityId}/management/users/list`).ref.get();
	}

	updateMeeting(meetingId, meeting: IMeeting) {
		console.log(`CJ ~ meeting`, meeting);
		return this.afs.doc(`entities/${this.entityId}/meetings/${meetingId}`).set(meeting, { merge: true });
	}

	updateMeetingUser(meeting: IMeeting, user: IMeetingUser) {
		return this.afs.doc(`entities/${this.entityId}/meetings/${meeting.id}/users/${user.id}`).set(user, { merge: true });
	}

	getTopics(meetingId) {
		return this.afs.collection(`entities/${this.entityId}/meetings/${meetingId}/topics`, ref => ref.orderBy('created', 'asc')).valueChanges({ idField: 'id' });
	}

	getTopicVotes(meetingId, topicId) {
		console.log(`CJ ~ topicId`, topicId);
		console.log(`CJ ~ meetingId`, meetingId);
		return this.afs.collection(`entities/${this.entityId}/meetings/${meetingId}/votes`, ref => ref.where('topicId', '==', topicId)).valueChanges({ idField: 'id' });
	}

	async addTopic(topic, meeting) {
		topic.created = new Date();
		topic.createdBy = this.loggedInUser.uid;
		const ref = await this.afs.collection(`entities/${this.entityId}/meetings/${meeting.id}/topics`).add(topic);
	}

	async addTopicVote(meeting, topic, vote) {
		console.log(`CJ ~ vote`, vote);
		console.log(`CJ ~ topic`, topic);
		console.log(`CJ ~ meeting`, meeting);
		vote.created = new Date();
		vote.meetingId = meeting.id;
		vote.topicId = topic.id;
		vote.status = 'created';
		const ref = await this.afs.collection(`entities/${this.entityId}/meetings/${meeting.id}/votes`).add(vote);
	}

	// This will need to be removed
	addTempUsersToAdd(meeting) {
		this.afs
			.collection(`entities/${this.entityId}/users`)
			.ref.get()
			.then(usersRef => {
				let users = [];
				usersRef.forEach(userDoc => {
					users.push(userDoc.data());
				});
				users = users.filter(user => {
					if (user.email === 'cj@noldor.co.za' || user.email === 'duncan@noldor.co.za' || user.email === 'theunis@whitfields.co.za') {
						return user;
					}
				});
				users.forEach(user => {
					const addUser: IMeetingUser = {
						active: true,
						id: user.uid,
						firstname: user.firstname,
						surname: user.surname,
						status: 'unconfirmed',
						email: user.email,
						vote: '',
						cell: user.cell || '',
					};

					this.updateMeetingUser(meeting, addUser);
				});
				console.log('users', users);
			});
	}

	getFilesRef(meetingId) {
		return this.afs.collection(`entities/${this.entityId}/meetings/${meetingId}/files`);
	}

	async handleVote(meeting, users, vote, updateStatus, selectedTopic) {
		if (updateStatus === 'send') {
			meeting.vote = vote;
			meeting.forTopic = selectedTopic;
			// await this.sendWhatsApp(meeting, users, 'meeting_vote');
			await this.afs.doc(`entities/${this.entityId}/meetings/${meeting.id}/votes/${vote.id}`).set({ status: 'sent' }, { merge: true });
		} else {
			await this.afs.doc(`entities/${this.entityId}/meetings/${meeting.id}/votes/${vote.id}`).set({ status: updateStatus }, { merge: true });
		}
	}

	private httpsCallableWhitfieldsCallWhatsapp = firebase.functions().httpsCallable('whitfields-callWhatsapp');

	async sendWhatsApp<T>(meeting: IMeeting, users: IMeetingUser[], type: string) {
		const httpsCallableResult = await this.httpsCallableWhitfieldsCallWhatsapp({
			type: type,
			meeting: meeting,
			users: users,
		});

		if (httpsCallableResult) {
			if (httpsCallableResult.data == null) {
				console.error({ httpsCallableResult });
				throw new Error(`Endpoint unreachable.`);
			}
		}

		return httpsCallableResult.data as T;
	}
}
