export const environment = {
	production: true,
	useEmulators: false,
	firebase: {
		apiKey: 'AIzaSyACQd_bsyKM4P4QskwlQKKQrpfHd2Sm3Qs',
		authDomain: 'whitfields-firefly-staging.firebaseapp.com',
		databaseURL: 'https://whitfields-firefly-staging.firebaseio.com',
		projectId: 'whitfields-firefly-staging',
		storageBucket: 'whitfields-firefly-staging.appspot.com',
		messagingSenderId: '1017662959991',
	},
	product: 'olympus',
	admin: 'https://firefly.staging.whitfields.co.za',
	client: 'staging.whitfields.co.za',
	olympus: 'olympus.staging.whitfields.co.za',
	googleMapAPI: 'AIzaSyDk2XwitE8ii_ln6opmBWZTeQGpfzE7gtw',
	port: 8006,
	allowedLoginDomains: ['noldor.co.za', 'noltech.co.za', 'whitfields.co.za'],
	useSentry: false,
	region: 'europe-west1',
};
