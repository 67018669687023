import { createReducer, on, Action } from '@ngrx/store';
import { AppState } from '../app.state';
import { setPrsPermissions } from './prs.actions';

export const initialState: Readonly<FixMeAny> = {};

export const prsReducer = createReducer(
	initialState,
	on(setPrsPermissions, (state, { prsPermissions }) => {
		return { ...state, ...prsPermissions };
	})
);
