import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PropertiesService } from './properties.service';
import { take, finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { WodApiService } from '../../management/services/wod-api.service';
import { FilesService } from '../components/files/files.service';
import { Account } from '../fin/models/account';
import { Property } from './property.model';
import { RegOwner } from '../registered-owners/registered-owner.model';
import { AlanLevyAttorneysandConveyancers } from '../../management/tasks/components/lod-widget/templates/AlanLevyAttorneysandConveyancers';
import { CeronioAttorneys } from '../../management/tasks/components/lod-widget/templates/CeronioAttorneys';
import { HahnandHahnAttorneys } from '../../management/tasks/components/lod-widget/templates/HahnandHahnAttorneys';
import { JdeWetAttorneys } from '../../management/tasks/components/lod-widget/templates/JdeWetAttorneys';
import { KramerAttorneysatLaw } from '../../management/tasks/components/lod-widget/templates/KramerAttorneysatLaw';
import { MorganLaw } from '../../management/tasks/components/lod-widget/templates/MorganLaw';
import { PotgieterLouwAttorneys } from '../../management/tasks/components/lod-widget/templates/PotgieterLouwAttorneys';
import { RobinTwaddleAttorneys } from '../../management/tasks/components/lod-widget/templates/RobinTwaddleAttorneys';
import { SBrownAttorneysIncorporated } from '../../management/tasks/components/lod-widget/templates/SBrownAttorneysIncorporated';
import { SutherlandKrugerAttorneys } from '../../management/tasks/components/lod-widget/templates/SutherlandKrugerAttorneys';
import moment from 'moment';
import { CurrencyPipe } from '@angular/common';
import { selectEntityData, selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';
import { WhitfieldsCallLampyService } from '../../management/whitfields-call-lampy.service';
import { Statement, StatementClass } from '@plex/fin/models/statement.model';
import { getFireflyStatementInvoiceNumberAndDate } from '@noldortech/firefly-statement-dates';
import { ManagementService } from '../../management/management.service';
import { GenerateLodArgs } from '../../management/types';
import { environment } from '@environments/environment';
declare const pdfMake;

@Injectable({
	providedIn: 'root',
})
export class FfPropertiesService {
	entityId;
	currentAccount = new BehaviorSubject<any>({});
	currentOwner = new BehaviorSubject<any>({});
	constructor(
		private afs: AngularFirestore,
		private wodApiService: WodApiService,
		private storage: AngularFireStorage,
		private filesService: FilesService,
		private currencyPipe: CurrencyPipe,
		private store: Store,
		private whitfieldsCallLampyService: WhitfieldsCallLampyService,
		private managementService: ManagementService
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	getAttorney(entityId?) {
		let tmpEntityId = '';
		if (entityId) {
			tmpEntityId = entityId;
		} else {
			tmpEntityId = this.entityId;
		}
		return this.afs
			.collection(`entities/${tmpEntityId}/WhitfieldsProfessionalTeam`, ref => ref.where('active', '==', true).where('type', '==', 'attorney'))
			.valueChanges({ idField: 'id' });
	}

	nextAccount(account, owner) {
		this.currentAccount.next(account);
		this.currentOwner.next(owner);
	}

	getAttorneyWithId(entityId?: string) {
		if (!entityId) {
			entityId = this.entityId;
		}
		return this.afs.collection(`entities/${entityId}/WhitfieldsProfessionalTeam`, ref => ref.where('active', '==', true).where('type', '==', 'attorney')).valueChanges();
	}

	getLodOwnerAndAccount() {
		return combineLatest(this.currentAccount, this.currentOwner);
	}

	addLetterOfDemand(data: GenerateLodArgs): Promise<void> {
		if (!data.entityId) {
			data.entityId = this.entityId;
		}

		return this.managementService.sendLod(data);
	}

	saveFileDataFirestore(saveRef, upload: any) {
		// console.log(saveRef);
		const collectionRef = this.afs.collection(saveRef); //ref

		// console.log('upload save to db', upload);

		return collectionRef.add(upload).then(ref => {
			ref.update({
				ref: ref,
			});

			return ref;
		});
	}

	uploadStatementDocument(details, fileDD, propertyId?) {
		return new Promise(res => {
			if (details['send_type'] !== 'csv') {
				pdfMake.createPdf(fileDD).getBlob(blob => {
					const fileName = `statement_${details.accountNumber}`;
					const path = `entities/${this.entityId}/properties/${details.uid}/statements/`;
					const document = {
						name: fileName,
						file: blob,
					};
					new Promise(reso => {
						this.filesService.startUpload(document, path).then((ref: any) => {
							// save the data to pending to send the email
							this.afs
								.doc(`entities/${this.entityId}/properties/${details.uid}/statements/${ref.id}`)
								.ref.get()
								.then(doc => {
									let attachment = doc.data() as any;
									details['statement'] = Object.assign({}, details['statement']);
									let data = {
										...details,
										entityId: this.entityId,
										fileAttachments: [
											{
												content: attachment.path,
												name: attachment.name,
												filetype: attachment.filetype,
												disposition: 'attachment',
												content_id: attachment.name,
												path: attachment.path,
												active: true,
											},
										],
										product: environment.product,
									};
									data['select_month'] = data['select_month'] || '';
									data['select_year'] = data['select_year'] || '';
									data['request'] = 'sendFireflyStatement';

									this.afs
										.doc(`pending/${ref.id}`)
										.set(data)
										.then(() => {
											reso('');
										});
								});
						});
					}).then(() => res(''));
				});
			} else {
			}
		});
	}

	sendStatement(data) {
		let saveData = {
			active: true,
			generating: true,
			door_number: data.door_number,
			email: data.email,
			property_number: data.property_number,
			section_stand_number: data.section_stand_number,
			street_name: data.street_name,
			category: 'statements',
			propertyId: data.uid,
			createdBy: data.createdBy,
			entityId: this.entityId,
			type: data.type,
			accountNumber: data.accountNumber,
			request: 'sendFireflyEmails',
			url: data.url,
			fromDate: data.fromDate ? data.fromDate : '',
			toDate: data.toDate ? data.toDate : '',
			send_statement: data.send_statement,
			select_year: data.select_year ? data.select_year : '',
			select_month: data.select_month ? data.select_month : '',
			send_type: data.send_type,
			regOwnerId: data.regOwnerId,
			userId: data.userId,
			fromClient: false,
		};
		return this.afs.collection(`pending`).add(saveData);

		/*  this.propertiesService.getAccountsForProperty(data.uid).pipe(take(1)).subscribe((accountsData: any) => {
             if (accountsData.length > 0) {
                 accountsData.sort((a, b) => (a.created < b.created) ? 1 : ((b.created < a.created) ? -1 : 0));
                 saveData['accountNumber'] = accountsData[0].name;
                 saveData['request'] = 'sendFireflyEmails';
                 return this.afs.collection(`pending`).add(saveData).then(() => {
                     console.log("TCL: FfPropertiesService -> addLetterOfDemand -> saveData", saveData)
                 });
             }
         }); */
	}

	async getFireflyStatement(account, startDate, endDate, type, statementType, isFirefly) {
		const { date, taxInvoiceNumber } = getFireflyStatementInvoiceNumberAndDate(account.name, { startDate, endDate, statementType });
		try {
			let statementResult = await this.whitfieldsCallLampyService.getLampyAccountStatement({ name: account.name, startDate, endDate, type, statementType, isFirefly });
			if (statementResult) {
				const cscResult = await this.afs.doc(`entities/${this.entityId}/WhitfieldsManagementTeam/occ`).ref.get();
				const cscUser = cscResult.data();
				const propertiesResult = await this.afs.collection(`entities/${this.entityId}/fin/debtors/list/${account.id}/properties`).ref.where('active', '==', true).get();
				let property;
				if (propertiesResult.size > 0) {
					let propertyGet = await this.afs.doc(`entities/${this.entityId}/properties/${propertiesResult.docs[0].id}`).ref.get();
					property = propertyGet.data();
				}
				const entityData = await this.store.select(selectEntityData).pipe(take(1)).toPromise();
				statementResult = statementResult.statementData;
				let statementData = new StatementClass();
				statementData['clientStatementEntries']['entries'] = statementResult['transactions'].map(entry => {
					return {
						transNow: entry['runningBalance'],
						reference: entry['reference'],
						credit: entry['credit'],
						debit: entry['debit'],
						description: entry['description'],
						transdate: entry['txdate'],
						property: entry['property'],
						allocationDate: entry['allocationDate'],
					};
				});
				statementData['clientStatementEntries']['totals'] = {
					amountDue: statementResult['amountDue'],
					transNow: '',
					balanceBFDebit: statementResult['balanceBroughtForward'],
				};
				statementData.occDetails = {
					occName: `${cscUser['firstname']} ${cscUser['surname']}`,
					occPhone: cscUser['tel'] || '',
					occFax: cscUser['fax'] || '',
					occEmail: cscUser['email'] || '',
				};
				statementData.invoiceDetails = {
					complexName: entityData['name'] || '',
					unitNumber: property['property_number'] || '',
					unitPhysical1: property['street_name'] || '',
					unitPhysical2: entityData['suburb'] || '',
					statementDate: date,
					invoiceNum: taxInvoiceNumber || '',
					complexVatNumber: entityData['vat_number'] || '',
					accountNum: account['name'] || '',
					addressee: '',
					dueDate: '',
					unitVatNumber: '',
					amountDue: '',
					vat_number: '',
				};
				return Promise.resolve(statementData);
			} else {
				return Promise.reject('There was an error fetching the statement');
			}
		} catch (error) {
			return Promise.reject(error);
		}
	}

	getLastLOD(property: Property, account: Account) {
		return this.afs
			.collection(`entities/${this.entityId}/properties/${property.uid}/pending`, (ref: CollectionReference) => {
				return ref
					.where('type', '==', 'LOD')
					.where('propertyId', '==', property.id)
					.where('accountNumber', '==', account.name)
					.where('test', '==', false)
					.orderBy('last_sent', 'desc');
			})
			.valueChanges();
	}

	resendTenantStatement(form, property) {
		// get account for tenant
		const propertyId = property.uid;
		this.afs
			.collection(`entities/${this.entityId}/properties/${propertyId}/users`, ref => ref.where(`active`, '==', true).where(`type`, '==', 'tenant'))
			.valueChanges()
			.pipe(take(1))
			.subscribe(data => {
				console.log('TCL: FfPropertiesService -> resendTenantStatement -> data', data);
			});
	}

	getPropertyPrimaryContact(property) {
		return this.afs
			.collection(`entities/${this.entityId}/properties/${property.id}/users`, ref => ref.where(`active`, '==', true).where(`type`, '==', 'primary'))
			.valueChanges();
	}

	getPropertyData(propertyId) {
		return new Promise((res, rej) => {
			return this.afs
				.doc(`entities/${this.entityId}/properties/${propertyId}/`)
				.ref.get()
				.then((propertyDoc: any) => {
					if (propertyDoc.exists) {
						res(propertyDoc.data());
					} else {
						rej('Property document does not exist.');
					}
				})
				.catch(err => {
					rej(err);
				});
		});
	}

	async getRegisteredOwnersForProperty(property: Property): Promise<RegOwner[]> {
		const registeredOwnerRef: AngularFirestoreCollection = this.afs
			.collection('entities')
			.doc(this.entityId)
			.collection('registered_owners', (ref: CollectionReference) => {
				return ref.where('active', '==', true).where('owner_type', '==', 'current_owner');
			});

		const registeredOwners: Array<RegOwner> = await registeredOwnerRef.valueChanges({ idField: 'uid' }).pipe(take(1)).toPromise();

		const registeredOwnersWithProperties: Array<Promise<RegOwner>> = registeredOwners.map(async (owner: RegOwner) => {
			const registeredOwnerPropertiesRef: AngularFirestoreCollection = this.afs
				.collection('entities')
				.doc(this.entityId)
				.collection('registered_owners')
				.doc(owner.uid)
				.collection('properties', (ref: CollectionReference) => {
					return ref.where('active', '==', true);
				});

			const registeredOwnerProperties: Array<Property> = await registeredOwnerPropertiesRef.valueChanges({ idField: 'uid' }).pipe(take(1)).toPromise();
			owner.properties = registeredOwnerProperties;

			return owner;
		});

		const owners = (await Promise.all(registeredOwnersWithProperties)).filter((owner: RegOwner) => {
			const propertyIds = owner.properties.map((property: Property) => property.uid);
			return propertyIds.includes(property.id);
		});

		return owners;
	}
}
