import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import firebase from 'firebase/app';

import { environment } from './../../../../../environments/environment';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { ConfigsService, Environment } from 'src/app/_shared/services/configs.service';
import { AuthenticationService } from '@auth/_services';
import { GenerateLodArgs } from './types';
import { SavedWhitfieldsJournal, WhitfieldsJournalSaveData } from './whitfields-journals/whitfields-journal.model';

export interface FirestoreSelectOp {
	collection: string;
	select: string[];
	where: {
		fieldPath: string;
		opStr: firebase.firestore.WhereFilterOp;
		value: any;
	}[];
	orderBy: {
		fieldPath: string;
		directionStr: firebase.firestore.OrderByDirection;
	}[];
}

@Injectable()
export class ManagementService {
	entityType = environment.product;
	entityId: string;

	constructor(public afStore: AngularFirestore, private store: Store, private configsService: ConfigsService, private authenticationService: AuthenticationService) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	fetchProjectEntity() {
		return this.afStore.doc(`entities/${this.entityType}`).valueChanges();
	}

	getLoggedInUserEntity() {
		const userId = sessionStorage.getItem('userId');
		return this.afStore.doc(`users/${userId}/entities/${this.entityType}`).valueChanges();
	}

	getDefaultEntity() {
		return this.afStore
			.doc(`entities/whitfields`)
			.ref.get()
			.then(doc => {
				if (doc.exists) {
					return Promise.resolve(true);
				} else {
					return Promise.resolve(false);
				}
			});
	}

	deleteProTeamDoc(type: string) {
		/**
		 * Deletes a given Whitfields Professional Team Member Document on Firestore.
		 * @param type: string - This is the name of the document it'll delete.
		 */
		return this.afStore.doc(`entities/${this.entityId}/WhitfieldsProfessionalTeam/${type}`).delete();
	}

	createDefaultEntity() {
		let entity = {
			name: 'Whitfields',
			entity_type: 'Body Corporate',
			no_units: 0,
			prefix: 'WPM',
			domain: 'dev.whitfields.amiti.online',
			disableClientSiteDeploy: false,
			websiteUrl: 'dev.whitfields.amiti.online',
			address: 'Mowbray House, Fourways Golf Park, Roos Street, Fourways',
			suburb: 'Fourways',
			city: 'Johannesburg',
			province: 'Gauteng',
			code: '0000',
			erf_number: '0147258369',
			gps_coord1: '-26.015611',
			gps_coord2: '28.002667',
			incorporation_date: '',
			registration_number: '',
			tax_number: '01478523699',
			vat_number: '',
			sars_empoyer: '',
			financial_year_end: '',
			template: {
				colour1: '#00c5dc',
				colour2: '#36a3f7',
				contact_number: '',
				email_address: '',
				about_us: '',
				web_template: 'default',
			},
			deployed: false,
			active: true,
			createdby: sessionStorage.getItem('userId'),
			created: Date.now(),
			product: 'whitfields',
		};

		return this.afStore
			.doc(`entities/whitfields`)
			.ref.get()
			.then(doc => {
				if (!doc.exists) {
					return this.afStore
						.doc('entities/whitfields')
						.set(entity, { merge: true })
						.then(() => {
							// console.log('DEFAULT WHITFIELDS ENTITY CREATED')
						});
				} else {
					// console.log(`DEFAULT DOCUMENT ALREADY EXISTS!`)
				}
			});
	}

	private async getCloudWorkersInfo() {
		const idToken = await this.authenticationService.getIdToken();
		const { amitiCloudWorkersUrl } = await this.configsService.getProductConfig();
		const { cloudWorkersUrl } = environment as Environment;

		const workersUrl = cloudWorkersUrl || amitiCloudWorkersUrl;

		if (!workersUrl) {
			throw new Error('amitiCloudWorkersUrl not configured.');
		}

		return {
			amitiCloudWorkersUrl: workersUrl,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: idToken,
				'X-Source': 'amiti-admin',
			},
		};
	}

	/**
	 * Calls httpsCallable Google Cloud Function containing logic to generate a unique sequential task number.
	 *
	 * "Generates a unique sequential task number by fetching the last created task(created, desc) in the provided
	 * directory where the whitfieldsPrefix and taskCode are the same as the provided. Checks if a task with the full refNo
	 * already exists before resolving. If it already exists, it regenerates the refNo."
	 *
	 * @param taskDir - Firestore directory of tasks collection
	 * @param whitfieldsPrefix - whitfieldsPrefix field stored on task documents.
	 * @param taskCode - taskCode e.g. "GEN" stored on task documents.
	 * @param refNoFieldName - To indicate which key on a task document contains the refNo value.
	 * @param startingTask - Optional task to start incrementing at.
	 * @returns Unique sequential task number string padded with zeros.
	 */
	public async generateUniqueSequentialTaskNumber(taskDir: string, whitfieldsPrefix: string, taskCode: string, refNoFieldName: string, startingTask?): Promise<string> {
		const { amitiCloudWorkersUrl, headers } = await this.getCloudWorkersInfo();

		const rawResponse = await fetch(`${amitiCloudWorkersUrl}/firefly/generate-unique-sequential-task-number`, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				refNoFieldName,
				taskCode,
				taskDir,
				whitfieldsPrefix,
				startingTask,
			}),
		});

		if (!rawResponse.ok) {
			const text = await rawResponse.text();
			throw new Error(text);
		}

		const { uniqueSequentialTaskNumber } = await rawResponse.json();

		return uniqueSequentialTaskNumber as string;
	}

	public async sendLod(data: GenerateLodArgs): Promise<void> {
		const { amitiCloudWorkersUrl, headers } = await this.getCloudWorkersInfo();

		const rawResponse = await fetch(`${amitiCloudWorkersUrl}/firefly/send-letter-of-demand`, {
			method: 'POST',
			headers,
			body: JSON.stringify(data),
		});

		if (!rawResponse.ok) {
			const text = await rawResponse.text();
			throw new Error(text);
		}

		return rawResponse.json();
	}

	public async saveWhitfieldsJournal(data: WhitfieldsJournalSaveData): Promise<SavedWhitfieldsJournal | void> {
		const { amitiCloudWorkersUrl, headers } = await this.getCloudWorkersInfo();

		const rawResponse = await fetch(`${amitiCloudWorkersUrl}/firefly/save-whitfields-journal`, {
			method: 'POST',
			headers,
			body: JSON.stringify({ data }),
		});

		if (!rawResponse.ok) {
			const text = await rawResponse.text();
			throw new Error(text);
		}

		return rawResponse.json();
	}

	public async firestoreSelect<T>(selectQuery: FirestoreSelectOp) {
		const { amitiCloudWorkersUrl, headers } = await this.getCloudWorkersInfo();

		const rawResponse = await fetch(`${amitiCloudWorkersUrl}/utils/firestore-select`, {
			method: 'POST',
			headers,
			body: JSON.stringify({ selectQuery }),
		});

		if (!rawResponse.ok) {
			const text = await rawResponse.text();
			throw new Error(text);
		}

		return rawResponse.json() as Promise<Partial<T>[]>;
	}
}
