import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HistoryAddedField } from '@noldortech/plex-utilities';

import { Note } from './note.model';
import { User } from '../users/user.model';
import { UsersService } from '../users/users.service';
import { PropertiesService } from '../properties/properties.service';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { RegisteredOwnersService } from '@plex/registered-owners/registered-owners.service';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';

declare var toastr: any;

@Injectable()
export class NotesService {
	notesCollection: AngularFirestoreCollection<Note[]>;
	userDoc: AngularFirestoreDocument<User>;
	entityId: any;
	loggedInUser: string;
	loggedInEmail: string;
	currentUser: User;

	constructor(
		public afs: AngularFirestore,
		public router: Router,
		private usersService: UsersService,
		private propertiesService: PropertiesService,
		private store: Store,
		private registeredOwnersService: RegisteredOwnersService,
		private auth: AuthenticationService
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));

		this.auth.user.subscribe(userDetails => {
			this.loggedInUser = userDetails.uid;
			this.loggedInEmail = userDetails.email;
			this.currentUser = userDetails;
		});
	}

	addNote(ref_path, content, type?) {
		content.entity_id = this.entityId;
		this.notesCollection = this.afs.collection(`${ref_path}/notes`);

		return this.notesCollection
			.add(content)
			.then(() => {
				const splitPath: string[] = ref_path.split('/');

				const noteType = splitPath[0];
				const userId = splitPath[1];
				const propertyId = splitPath[3];
				const accountId = splitPath[5];

				if (noteType === 'users' || noteType === 'entities') this.addNoteHistory(content.content, userId, propertyId, noteType, type, accountId);

				toastr.success('Note was successfully added');
			})
			.catch(error => {
				toastr.error('Note could not be added! Please try again.');
			});
	}

	private addNoteHistory(content: string, userID: string, propertyId: string, noteType: 'users' | 'entities', type?: string, accountId?: string): void {
		const changedField: HistoryAddedField = {
			field: 'Added note',
			value: content,
			fieldAction: 'added',
		};

		if (noteType === 'users') this.usersService.addHistoryLogToUser([changedField], 'notes', userID, true);
		if (noteType === 'entities') this.propertiesService.addHistoryLogToProperty([changedField], propertyId, 'note', true);
		if (type === 'Owner') this.registeredOwnersService.addHistoryLogToRegisteredOwner([changedField], userID, 'note', true);
		if (type === 'Account') {
			const auditDetails = {
				category: 'debtors',
				createdBy: this.currentUser.uid ? this.currentUser.uid : '',
				firstname: this.currentUser.firstname ? this.currentUser.firstname : '',
				surname: this.currentUser.surname ? this.currentUser.surname : '',
				description: `Debtors account updated - Note: ${content} added to account`,
				created: Date.now(),
				id: accountId,
				type: 'added',
			};
			const auditTrailCollection = this.afs.collection(`entities/${this.entityId}/auditTrail`);
			auditTrailCollection.add(auditDetails);
		}
	}

	fetchNotes(ref_path) {
		this.notesCollection = this.afs.collection(`${ref_path}/notes`, ref => ref.orderBy('created', 'desc'));

		return this.notesCollection.valueChanges({ idField: 'uid' });
	}

	fetchNoteUser(user_path) {
		this.userDoc = this.afs.doc(`${user_path}`);
		return this.userDoc.valueChanges();
	}
}
