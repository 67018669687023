import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SchemesService } from '../theme/pages/default/management/entities/entities.service';
import { Store } from '@ngrx/store';
import { selectEntityId } from '../_state/entity/entity.selectors';
import { environment } from '@environments/environment';
import { ConfigsService, Environment } from '../_shared/services/configs.service';
import { setGeneralData } from '../_state/general/general.actions';

@Injectable({
	providedIn: 'root',
})
export class EntityResolverService implements Resolve<any> {
	constructor(private schemesService: SchemesService, private router: Router, private store: Store, private configsService: ConfigsService) {}
	resolve(route: ActivatedRouteSnapshot) {
		this.getCloudWorkersInfo().then(value => this.store.dispatch(setGeneralData(value)));

		const entityId$ = this.store.select(selectEntityId).pipe(take(1));

		return entityId$
			.toPromise()
			.then(id => {
				let entityId = id;

				if (environment.product === 'olympus') {
					entityId = 'olympus';
				}

				return this.schemesService.setEntityId(entityId).catch(err => {
					this.router.navigate(['entities']);
				});
			})
			.catch(err => {
				this.router.navigate(['entities']);
			});
	}

	private async getCloudWorkersInfo() {
		const { amitiCloudWorkersUrl } = await this.configsService.getProductConfig();
		const { cloudWorkersUrl } = environment as Environment;

		const workersUrl = cloudWorkersUrl || amitiCloudWorkersUrl;

		if (!workersUrl) {
			throw new Error('amitiCloudWorkersUrl not configured.');
		}

		return {
			cloudWorkersHeaders: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-Source': 'amiti-admin' },
			cloudWorkersUrl: workersUrl,
		};
	}
}
