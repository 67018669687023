import { createReducer, on, Action } from '@ngrx/store';
import { GeneralState } from '../app.state';

import { setGeneralData } from './general.actions';

export const initialState: Readonly<GeneralState> = { cloudWorkersHeaders: {}, cloudWorkersUrl: '' };

export const generalReducer = createReducer(
	initialState,
	on(setGeneralData, (state, props) => {
		return { ...state, ...props };
	})
);
