<!-- BEGIN: Header -->
<header id="m_header" class="m-grid__item m-header" m-minimize-offset="200" m-minimize-mobile-offset="200"
	appunwraptag="">
	<div class="m-container m-container--fluid m-container--full-height">
		<div class="m-stack m-stack--ver m-stack--desktop">
			<!-- BEGIN: Brand -->
			<div class="m-stack__item m-brand" [ngClass]="config.leftMenuTheme"
				[ngStyle]="{ 'background-color': showHeaderMenu ? '' : '#403f3f' }">
				<div class="m-stack m-stack--ver m-stack--general">
					<div class="m-stack__item m-stack__item--middle m-stack__item--center m-brand__logo">
						<a (click)="navigate()" href="#" class="m-brand__logo-wrapper"> <img alt=""
								src="{{ config?.entityLogo }}" style="max-width: 120px" /> </a>
					</div>
					<div class="m-stack__item m-stack__item--middle m-brand__tools">
						<!-- BEGIN: Responsive Aside Left Menu Toggler -->
						<a href="javascript:;" id="m_aside_left_offcanvas_toggle"
							class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
							<span></span>
						</a>

						<!-- END -->

						<!-- BEGIN: Responsive Header Menu Toggler -->
						<a id="m_aside_header_menu_mobile_toggle" href="javascript:;"
							class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
							<span></span>
						</a>

						<!-- END -->

						<!-- BEGIN: Topbar Toggler -->
						<a id="m_aside_header_topbar_mobile_toggle" href="javascript:;"
							class="m-brand__icon m--visible-tablet-and-mobile-inline-block">
							<i class="flaticon-more"></i>
						</a>

						<!-- BEGIN: Topbar Toggler -->
					</div>
				</div>
			</div>

			<!-- END: Brand -->
			<div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
				<!-- BEGIN: Horizontal Menu -->
				<button class="m-aside-header-menu-mobile-close m-aside-header-menu-mobile-close--skin-dark"
					id="m_aside_header_menu_mobile_close_btn">
					<i class="la la-close"></i>
				</button>
				<div id="m_header_menu"
					class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark">
					<ul *ngIf="user?.verified && envType !== 'olympus'" class="m-menu__nav m-menu__nav--submenu-arrow">
						<li *ngIf="envType == 'whitfields'" class="m-menu__item m-menu__item--rel"
							routerLinkActive="m-menu__item--open" routerLinkActiveOptions="{ exact: true }">
							<a routerLink="/whitfields/management/tasks/dashboard" href="javascript:;"
								class="m-menu__link"><span class="m-menu__link-text">Tasks</span>
								<!-- <i
									class="m-menu__hor-arrow la la-angle-down"></i><i
									class="m-menu__ver-arrow la la-angle-right"></i> -->
							</a>
						</li>
						<li class="m-menu__item m-menu__item--submenu m-menu__item--rel"
							routerLinkActive="m-menu__item--open" routerLinkActiveOptions="{ exact: true }"
							m-menu-submenu-toggle="click" aria-haspopup="true">
							<a href="javascript:;" class="m-menu__link m-menu__toggle">
								<i class="m-menu__link-icon flaticon-app"></i>
								<span class="m-menu__link-text">{{ config?.entityPlural }}</span>
								<i class="m-menu__hor-arrow la la-angle-down"></i>
								<i class="m-menu__ver-arrow la la-angle-right"></i>
							</a>
							<div class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left">
								<span class="m-menu__arrow m-menu__arrow--adjust"></span>
								<ul class="m-menu__subnav">
									<li [ngClass]="entities?.length > 0 ? '' : 'm--hide'"
										class="m-menu__item m-menu__item--submenu"
										routerLinkActive="m-menu__item--active"
										routerLinkActiveOptions="{ exact: true }" m-menu-submenu-toggle="hover"
										m-menu-link-redirect="1" aria-haspopup="true">
										<a (click)="navigateToScheme(config['entitiesUrl'])"
											class="m-menu__link m-menu__toggle">
											<span class="m-menu__link-text">View {{ config?.entityPlural }}</span>
											<i class="m-menu__hor-arrow la la-angle-right"></i>
											<i class="m-menu__ver-arrow la la-angle-right"></i>
										</a>
										<div *ngxPermissionsOnly="['admin', 'view_only']"
											class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--right">
											<span class="m-menu__arrow"></span>
											<ul class="m-menu__subnav">
												<li class="m-menu__item" m-menu-link-redirect="1" aria-haspopup="true">
													<a [routerLink]="config['entitiesUrl']" class="m-menu__link">
														<span class="m-menu__link-text">View All {{ config?.entityPlural
															}}</span>
													</a>
												</li>
												<hr />
												<li *ngFor="let entity of entities | slice: 0:5" class="m-menu__item"
													m-menu-link-redirect="1" aria-haspopup="true">
													<a href="#" (click)="setEntityData(entity)" class="m-menu__link">
														<span class="m-menu__link-text">{{ entity?.name }}</span>
													</a>
												</li>
											</ul>
										</div>
									</li>
									<ng-container *ngIf="envType === 'whitfields'">
										<li class="m-menu__item" routerLinkActive="m-menu__item--active"
											routerLinkActiveOptions="{ exact: true }"
											*ngxPermissionsOnly="['addEntities']" aria-haspopup="true">
											<a routerLink="{{ config?.entityAddUrl }}" class="m-menu__link">
												<span class="m-menu__link-text">+ New {{ config?.entitySingle }}</span>
											</a>
										</li>
									</ng-container>
									<ng-container *ngIf="envType !== 'whitfields'">
										<li class="m-menu__item" routerLinkActive="m-menu__item--active"
											routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
											<a routerLink="{{ config?.entityAddUrl }}" class="m-menu__link">
												<span class="m-menu__link-text">+ New {{ config?.entitySingle }}</span>
											</a>
										</li>
									</ng-container>
								</ul>
							</div>
						</li>
						<li *ngIf="config.headerShowManagement"
							class="m-menu__item m-menu__item--submenu m-menu__item--rel"
							routerLinkActive="m-menu__item--open" routerLinkActiveOptions="{ exact: true }"
							m-menu-submenu-toggle="click" aria-haspopup="true">
							<a href="javascript:;" class="m-menu__link m-menu__toggle"><span
									class="m-menu__link-text">Management</span><i
									class="m-menu__hor-arrow la la-angle-down"></i><i
									class="m-menu__ver-arrow la la-angle-right"></i></a>
							<div class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left">
								<span class="m-menu__arrow m-menu__arrow--adjust"></span>
								<ul class="m-menu__subnav">
									<li class="m-menu__item" routerLinkActive="m-menu__item--active"
										routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
										<a routerLink="/whitfields/management/db/main" class="m-menu__link"><i
												class="m-menu__link-icon flaticon-apps"></i><span
												class="m-menu__link-text">Home</span></a>
									</li>
									<li class="m-menu__item" routerLinkActive="m-menu__item--active"
										*ngxPermissionsOnly="['listStaff']" routerLinkActiveOptions="{ exact: true }"
										m-menu-link-redirect="1" aria-haspopup="true">
										<a routerLink="/whitfields/management/staff/list" class="m-menu__link">
											<i class="m-menu__link-icon flaticon-users"></i>
											<span class="m-menu__link-title">
												<span class="m-menu__link-wrap">
													<span class="m-menu__link-text">Staff</span>
												</span>
											</span>
										</a>
									</li>
								</ul>
							</div>
						</li>
						<ng-container *ngIf="envType !== 'whitfields'">
							<li *ngIf="showHeaderMenu" [ngClass]="showPopover ? 'help-pop' : 'no-show'"
								class="m-menu__item m-menu__item--submenu m-menu__item--rel"
								routerLinkActive="m-menu__item--open" routerLinkActiveOptions="{ exact: true }"
								m-menu-submenu-toggle="click" m-menu-link-redirect="1" aria-haspopup="true">
								<div class="popped bottom">
									Your scheme prefix and name will always be displayed here<br />
									<br />
									The prefix is the system’s unique identifier for your scheme<br /><br />
									<i class="m-menu__link-icon flaticon-settings"></i> Edit, here you can edit you
									scheme details, or view your scheme's website.
								</div>
								<a class="m-menu__link m-menu__toggle">
									<i id="step1-icon" class="m-menu__link-icon flaticon-cogwheel"></i>
									<span class="m-menu__link-text" style="padding-top: 14px">
										<div class="help-pulse-icon">
											<div class="icon-wrap">
												<i class="icon fa fa-question-circle" aria-hidden="true"></i>
											</div>
											<div class="elements">
												<div class="circle circle-inner"></div>
											</div>
										</div>
										<span id="step1">
											<span>{{ entity?.name }} &middot; </span>
											<small><sup>({{ entity?.prefix }})</sup></small><i
												class="m-menu__hor-arrow la la-angle-down"></i>
											<br />

											<small *ngIf="!entity?.disableClientSiteDeploy; else showCustomUrl">
												<a class="m-link" href="{{ entity?.clientWebsiteUrl }}"
													title="Open {{ entity?.clientWebsiteUrl }} website tab"
													(click)="navToClient()">{{ entity?.clientWebsiteUrl }}</a>
											</small>

											<ng-template #showCustomUrl>
												<small *ngIf="entity?.websiteUrl !== ''">
													<a class="m-link" href="{{ entity?.websiteUrl }}"
														title="Open {{ entity?.websiteUrl }} website tab"
														(click)="navToWebsite(entity?.websiteUrl)">{{ entity?.websiteUrl
														}}</a>
												</small>
											</ng-template>
										</span>
									</span>
								</a>
								<div class="m-menu__submenu m-menu__submenu--fixed m-menu__submenu--left"
									style="width: 500px !important">
									<span class="m-menu__arrow m-menu__arrow--adjust"></span>
									<div class="m-menu__subnav">
										<ul class="m-menu__content">
											<li class="m-menu__item" routerLinkActive="m-menu__item--active"
												routerLinkActiveOptions="{ exact: true }">
												<h3 class="m-menu__heading m-menu__toggle">
													<span class="m-menu__link-text">{{ config?.entitySingle }}
														Settings</span>
													<i class="m-menu__ver-arrow la la-angle-right"></i>
												</h3>
												<ul class="m-menu__inner">
													<li class="m-menu__item" routerLinkActive="m-menu__item--active"
														aria-haspopup="true">
														<a href="#" class="m-menu__link"
															(click)="navigateToTab('details')">
															<span class="m-menu__link-text">{{ config?.entitySingle }}
																Details</span>
														</a>
													</li>

													<li class="m-menu__item" routerLinkActive="m-menu__item--active"
														aria-haspopup="true">
														<a href="#" class="m-menu__link"
															(click)="navigateToTab('physical_address')">
															<span class="m-menu__link-text">{{ config?.entitySingle }}
																Address</span>
														</a>
													</li>
													<li *ngIf="config.headerFinancialDetailsTab && entity?.entity_type !== 'Rental Agency'"
														class="m-menu__item" routerLinkActive="m-menu__item--active"
														aria-haspopup="true">
														<a href="#" (click)="navigateToTab('financial_details')"
															class="m-menu__link">
															<span class="m-menu__link-text">Financial Details</span>
														</a>
													</li>
												</ul>
											</li>
											<li class="m-menu__item" routerLinkActive="m-menu__item--active"
												routerLinkActiveOptions="{ exact: true }">
												<h3 class="m-menu__heading m-menu__toggle">
													<span class="m-menu__link-text">{{ config?.entitySingle }}
														Website</span>
													<i class="m-menu__ver-arrow la la-angle-right"></i>
												</h3>
												<ul class="m-menu__inner">
													<li class="m-menu__item" routerLinkActive="m-menu__item--active"
														routerLinkActiveOptions="{ exact: true }"
														m-menu-link-redirect="1" aria-haspopup="true">
														<a (click)="navigateToTab('scheme_website')"
															class="m-menu__link">
															<span class="m-menu__link-text">Edit Website Details</span>
														</a>
													</li>
													<li class="m-menu__item" routerLinkActive="m-menu__item--active"
														routerLinkActiveOptions="{ exact: true }"
														m-menu-link-redirect="1" aria-haspopup="true">
														<a (click)="navigateToTab('addons')" class="m-menu__link">
															<span class="m-menu__link-text">Edit Website Add-ons</span>
														</a>
													</li>
													<li class="m-menu__item" routerLinkActive="m-menu__item--active"
														routerLinkActiveOptions="{ exact: true }"
														m-menu-link-redirect="1" aria-haspopup="true">
														<a class="m-menu__link" href="{{ entity?.domain }}"
															title="{{ entity?.domain }}" (click)="navToClient()"
															*ngIf="!entity?.disableClientSiteDeploy; else showCustomUrlmenu"><span
																class="m-menu__link-text">View Website</span></a>
														<ng-template #showCustomUrlmenu>
															<a class="m-menu__link" href="{{ entity?.websiteUrl }}"
																title="{{ entity?.websiteUrl }}"
																(click)="navToWebsite(entity?.websiteUrl)"
																*ngIf="entity?.websiteUrl !== ''"><span
																	class="m-menu__link-text">View Website</span></a>
														</ng-template>
													</li>
												</ul>
											</li>
										</ul>
									</div>
								</div>
							</li>
						</ng-container>
					</ul>
				</div>

				<!-- END: Horizontal Menu -->

				<!-- BEGIN: Topbar -->
				<div id="m_header_topbar" class="m-topbar m-stack m-stack--ver m-stack--general">
					<div class="m-stack__item m-topbar__nav-wrapper">
						<ul class="m-topbar__nav m-nav m-nav--inline">
							<!-- <li class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width"
							    m-dropdown-toggle="click" m-dropdown-persistent="1">
								<a href="#" class="m-nav__link m-dropdown__toggle" id="m_topbar_notification_icon">
									<span class="m-nav__link-badge m-badge m-badge--accent">3</span>
									<span class="m-nav__link-icon">
										<i class="flaticon-alert-2"></i>
									</span>
								</a>
								<div class="m-dropdown__wrapper">
									<span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
									<div class="m-dropdown__inner">
										<div class="m-dropdown__header m--align-center" style="background: url(./assets/app/media/img/misc/notification_bg.jpg); background-size: cover;">
											<span class="m-dropdown__header-title">9 New</span>
											<span class="m-dropdown__header-subtitle">User Notifications</span>
										</div>
										<div class="m-dropdown__body">
											<div class="m-dropdown__content">
												<ul class="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand" role="tablist">
													<li class="nav-item m-tabs__item">
														<a class="nav-link m-tabs__link active" data-toggle="tab" href="#topbar_notifications_notifications" role="tab"> Alerts </a>
													</li>
													<li class="nav-item m-tabs__item">
														<a class="nav-link m-tabs__link" data-toggle="tab" href="#topbar_notifications_events" role="tab">Events</a>
													</li>
													<li class="nav-item m-tabs__item">
														<a class="nav-link m-tabs__link" data-toggle="tab" href="#topbar_notifications_logs" role="tab">Logs</a>
													</li>
												</ul>
												<div class="tab-content">
													<div class="tab-pane active" id="topbar_notifications_notifications" role="tabpanel">
														<div class="m-scrollable" data-scrollable="true" data-height="250" data-mobile-height="200">
															<div class="m-list-timeline m-list-timeline--skin-light">
																<div class="m-list-timeline__items">
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge -m-list-timeline__badge--state-success"></span>
																		<span class="m-list-timeline__text">12 new users registered</span>
																		<span class="m-list-timeline__time">Just now</span>
																	</div>
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge"></span>
																		<span class="m-list-timeline__text">System shutdown
																			<span class="m-badge m-badge--success m-badge--wide">pending</span>
																		</span>
																		<span class="m-list-timeline__time">14 mins</span>
																	</div>
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge"></span>
																		<span class="m-list-timeline__text">New invoice received</span>
																		<span class="m-list-timeline__time">20 mins</span>
																	</div>
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge"></span>
																		<span class="m-list-timeline__text">DB overloaded 80%
																			<span class="m-badge m-badge--info m-badge--wide">settled</span>
																		</span>
																		<span class="m-list-timeline__time">1 hr</span>
																	</div>
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge"></span>
																		<span class="m-list-timeline__text">System error -
																			<a href="#" class="m-link">Check</a>
																		</span>
																		<span class="m-list-timeline__time">2 hrs</span>
																	</div>
																	<div class="m-list-timeline__item m-list-timeline__item--read">
																		<span class="m-list-timeline__badge"></span>
																		<span href="" class="m-list-timeline__text">New order received
																			<span class="m-badge m-badge--danger m-badge--wide">urgent</span>
																		</span>
																		<span class="m-list-timeline__time">7 hrs</span>
																	</div>
																	<div class="m-list-timeline__item m-list-timeline__item--read">
																		<span class="m-list-timeline__badge"></span>
																		<span class="m-list-timeline__text">Production server down</span>
																		<span class="m-list-timeline__time">3 hrs</span>
																	</div>
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge"></span>
																		<span class="m-list-timeline__text">Production server up</span>
																		<span class="m-list-timeline__time">5 hrs</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="tab-pane" id="topbar_notifications_events" role="tabpanel">
														<div class="m-scrollable" data-scrollable="true" data-height="250" data-mobile-height="200">
															<div class="m-list-timeline m-list-timeline--skin-light">
																<div class="m-list-timeline__items">
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge m-list-timeline__badge--state1-success"></span>
																		<a href="" class="m-list-timeline__text">New order received</a>
																		<span class="m-list-timeline__time">Just now</span>
																	</div>
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge m-list-timeline__badge--state1-danger"></span>
																		<a href="" class="m-list-timeline__text">New invoice received</a>
																		<span class="m-list-timeline__time">20 mins</span>
																	</div>
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge m-list-timeline__badge--state1-success"></span>
																		<a href="" class="m-list-timeline__text">Production server up</a>
																		<span class="m-list-timeline__time">5 hrs</span>
																	</div>
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge m-list-timeline__badge--state1-info"></span>
																		<a href="" class="m-list-timeline__text">New order received</a>
																		<span class="m-list-timeline__time">7 hrs</span>
																	</div>
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge m-list-timeline__badge--state1-info"></span>
																		<a href="" class="m-list-timeline__text">System shutdown</a>
																		<span class="m-list-timeline__time">11 mins</span>
																	</div>
																	<div class="m-list-timeline__item">
																		<span class="m-list-timeline__badge m-list-timeline__badge--state1-info"></span>
																		<a href="" class="m-list-timeline__text">Production server down</a>
																		<span class="m-list-timeline__time">3 hrs</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="tab-pane" id="topbar_notifications_logs" role="tabpanel">
														<div class="m-stack m-stack--ver m-stack--general" style="min-height: 180px;">
															<div class="m-stack__item m-stack__item--center m-stack__item--middle">
																<span class="">All caught up!
																	<br>No new logs.</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li> -->

							<li *ngIf="config.headerShowQuickLinks"
								class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown quick-links m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width"
								m-dropdown-toggle="click" m-dropdown-persistent="1">
								<a href="#" class="m-nav__link m-dropdown__toggle"
									id="m_topbar_notification_icon quick_links">
									<!-- <span class="m-nav__link-badge m-badge m-badge--accent">0</span> -->
									<span class="m-nav__link-icon">
										<i class="flaticon-search-1"></i>
									</span>
								</a>
								<div class="m-dropdown__wrapper">
									<span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
									<div class="m-dropdown__inner">
										<div class="m-dropdown__header m--align-center m--bg-brand"
											style="background-size: cover">
											<span class="m-dropdown__header-title">Quick Links</span>
											<span class="m-dropdown__header-subtitle">Search Complexes...</span>
										</div>
										<div class="m-dropdown__body">
											<div class="m-dropdown__content">
												<app-quick-search></app-quick-search>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li (click)="clickedNotification()" *ngIf="config.headerShowTaskNotifications"
								class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width"
								m-dropdown-toggle="click" m-dropdown-persistent="1">
								<a href="#" class="m-nav__link m-dropdown__toggle" id="m_topbar_notification_icon">
									<span class="m-nav__link-badge m-badge m-badge--info" *ngIf="myTaskCount > 0">{{
										myTaskCount }}</span>
									<span class="m-nav__link-icon"><i class="flaticon-alert-2"></i></span>
								</a>
								<div class="m-dropdown__wrapper">
									<span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
									<div class="m-dropdown__inner">
										<div class="m-dropdown__header m--align-center m--bg-brand">
											<span class="m-dropdown__header-title">{{ myTaskCount }} New</span>
											<!-- <span class="m-dropdown__header-subtitle">User Notifications</span> </div> -->
											<span class="m-dropdown__header-subtitle">Activities</span>
										</div>
										<div class="m-dropdown__body">
											<div class="m-dropdown__content">
												<ul class="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand"
													role="tablist">
													<li class="nav-item m-tabs__item">
														<a class="nav-link m-tabs__link active" data-toggle="tab"
															href="#topbar_notifications_notifications" role="tab">
															Activities
														</a>
													</li>
												</ul>
												<div class="tab-content">
													<div class="tab-pane active" id="topbar_notifications_notifications"
														role="tabpanel">
														<div *ngIf="userActivities">
															<app-activities-tab
																[activities]="userActivities ? userActivities : null"
																[loading]="loadingNotificationTasks"></app-activities-tab>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>

							<li class="m-nav__item m-topbar__quick-actions m-topbar__quick-actions--img"
								*ngIf="envType !== 'whitfields'" [ngClass]="showPopover ? 'help-pop' : 'no-show'">
								<!-- HELP -->
								<div class="help-pulse-icon">
									<div class="icon-wrap">
										<i class="icon fa fa-question-circle" aria-hidden="true"></i>
									</div>
									<div class="elements">
										<div class="circle circle-inner"></div>
									</div>
								</div>
								<!-- HELP TEXT -->
								<div class="popped bottom-right">Click here to access our Frequently Asked Questions on
									advice, tips and hints when using PLEX</div>
								<a [attr.href]="config.faqUrl" target="_blank" class="m-nav__link" title="Need help?">
									<span class="m-nav__link-badge m-badge m-badge--dot m-badge--info m--hide"></span>
									<span class="m-nav__link-icon">
										<i id="step6" class="flaticon-lifebuoy"></i>
									</span>
								</a>
							</li>
							<li class="m-nav__item m-topbar__quick-actions m-topbar__quick-actions--img"
								[ngClass]="showPopover ? 'help-pop' : 'no-show'">
								<!-- HELP -->
								<div class="help-pulse-icon">
									<div class="icon-wrap">
										<i class="icon fa fa-question-circle" aria-hidden="true"></i>
									</div>
									<div class="elements">
										<div class="circle circle-inner"></div>
									</div>
								</div>
								<!-- HELP TEXT -->
								<div class="popped bottom-right">Switch this help feature on and off by clicking on this
									button</div>
								<a href="#" class="m-nav__link" (click)="toggleSidebar()" title="Toggle Hints">
									<span class="m-nav__link-badge m-badge m-badge--dot m-badge--info m--hide"></span>
									<span class="m-nav__link-icon">
										<i id="step7" class="flaticon-info"></i>
									</span>
								</a>
							</li>
							<li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
								m-dropdown-toggle="click" [ngClass]="showPopover ? 'help-pop' : 'no-show'">
								<!-- HELP -->
								<div class="help-pulse-icon">
									<div class="icon-wrap">
										<i class="icon fa fa-question-circle" aria-hidden="true"></i>
									</div>
									<div class="elements">
										<div class="circle circle-inner"></div>
									</div>
								</div>
								<!-- HELP TEXT -->
								<div class="popped bottom-right">
									You can access your own profile by clicking on the down arrow next to your name. You
									can also update your details from here
								</div>
								<a id="step8" href="#" class="m-nav__link m-dropdown__toggle" (click)="checkTasks()">
									<span class="m-topbar__userpic">
										<img height="40" *ngIf="userDetails?.photoURL" [src]="userDetails.photoURL"
											alt="{{ user?.firstname }}" />
										<span *ngIf="!userDetails?.photoURL" class="m-type m--bg-info"><span
												class="m--font-light">{{ user.firstname | slice: 0:1 | uppercase
												}}</span></span>

										<!-- <img height="40" *ngIf="userDetails?.photoURL" [src]="userDetails?.photoURL" alt="user?.firstname" />
										<span *ngIf="!userDetails?.photoURL" class="m-type m--bg-info"><span class="m--font-light">{{ user?.firstname |
												slice:0:1 | uppercase }}</span></span>-->
										<div class="m-list-pics">
											<!-- <a href="#"><img id="step8" [src]="user?.photoURL" alt="" /></a> -->
										</div>
									</span>
								</a>

								<div class="m-dropdown__wrapper">
									<span
										class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
									<div class="m-dropdown__inner">
										<div class="m-dropdown__header m--align-center m--bg-brand"
											style="background-size: cover">
											<div class="m-card-user m-card-user--skin-dark">
												<div class="m-card-user__pic">
													<span class="m-type m--font-light" style="border: 2px solid"><span
															class="m--font-light">{{ user.firstname | slice: 0:1 |
															uppercase }}</span></span>

													<!--<img *ngIf="user?.photoURL" [src]="user?.photoURL" alt="user?.firstname" />
													<span *ngIf="!user?.photoURL" class="m-type m--font-primary"><span class="m--font-light">{{ user?.firstname |
															slice:0:1 | uppercase }}</span></span>-->
												</div>
												<div class="m-card-user__details">
													<span
														class="m-card-user__name m--font-weight-500 m--font-light"><strong>{{
															user.firstname }} {{ user.surname }}</strong></span>
													<label class="m-card-user__email m--font-light">{{ user.email
														}}</label>
												</div>
											</div>
										</div>
										<div class="m-dropdown__body">
											<div class="m-dropdown__content">
												<ul class="m-nav m-nav--skin-light">
													<li class="m-nav__section m--hide">
														<span class="m-nav__section-text">Section</span>
													</li>
													<li class="m-nav__item">
														<a routerLink="/user" class="m-nav__link">
															<i class="m-nav__link-icon la la-user"></i>
															<span class="m-nav__link-text">My Profile</span>
														</a>
													</li>
													<li class="m-nav__item"
														[ngClass]="showHeaderMenu ? 'm--show' : 'm--hide'"
														routerLinkActive="m-menu__item--active"
														routerLinkActiveOptions="{ exact: true }">
														<a [routerLink]="['/' + entityId + '/tasks/list']"
															[queryParams]="{ tab: 'my_tasks' }" class="m-nav__link">
															<i class="m-nav__link-icon flaticon-clipboard"></i>
															<span class="m-nav__link-text">My Tasks <span
																	class="m-badge m-badge--brand">{{ myTaskCount
																	}}</span></span>
														</a>
													</li>
													<hr />
													<li class="m-nav__separator m-nav__separator--fit"></li>
													<li class="m-nav__item">
														<button class="btn btn-outline-danger"
															(click)="logout()">Logout</button>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</li>
							<!-- <li id="m_quick_sidebar_toggle" class="m-nav__item">
								<a href="#" class="m-nav__link m-dropdown__toggle">
									<span class="m-nav__link-icon">
										<i class="flaticon-menu-button"></i>
									</span>
								</a>
							</li> -->
						</ul>
					</div>
				</div>

				<!-- END: Topbar -->
			</div>
		</div>
	</div>
</header>

<!-- END: Header -->