import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { FirebaseApp } from '@angular/fire';
import { Observable, Subject } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

import { Folder } from '../models/folder';
import { AuthenticationService } from '../../../../../../auth/_services/authentication.service';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';

@Injectable()
export class DocsService {
	entityId: string;
	entityName: any;

	// Folder variables
	foldersCollection: AngularFirestoreCollection<Folder>;
	folders: Observable<Folder[]>;
	loggedInUser: string;
	foldersList: Observable<any>;
	currentFolders: Folder[];
	currentFolder: Folder;
	folderItem = new Subject<Folder>();
	folderItems = new Subject<Folder[]>();
	folderDoc: AngularFirestoreDocument<Folder>;
	deleteFolder: AngularFirestoreDocument<Folder>;

	// Docs variables
	docsCollection: AngularFirestoreCollection<Folder>;
	docsList: Observable<any>;
	docsItems = new Subject<Folder>();
	currentDocs: Folder[];
	folderDocFile: AngularFirestoreDocument<Folder>;
	deleteDocument: AngularFirestoreDocument<Folder>;

	// Trash variables
	trashCollection: AngularFirestoreCollection<Folder>;
	trashSubfoldersCollection: AngularFirestoreCollection<Folder>;
	trashDocsCollection: AngularFirestoreCollection<Folder>;
	trashFolderDoc: AngularFirestoreDocument<Folder>;
	deleteTrashFolder: AngularFirestoreDocument<Folder>;

	// Recent variables
	recentCollection: AngularFirestoreCollection<Folder>;
	recentDocsCollection: AngularFirestoreCollection<Folder>;
	recentFolderDoc: AngularFirestoreDocument<Folder>;

	// Restore
	restoreCollection: AngularFirestoreCollection<Folder>;

	// BUCKETS
	bucketDocsCollection: AngularFirestoreCollection<Folder>;
	bucketRef: any;
	bucketCollection: AngularFirestoreCollection<Folder>;
	bucketDocument: AngularFirestoreDocument<Folder>;
	deleteBucket: AngularFirestoreDocument<Folder>;

	constructor(private afStore: AngularFirestore, private auth: AuthenticationService, public router: Router, public fb: FirebaseApp, private store: Store) {
		// GET LOGGED IN USERS
		this.loggedInUser = sessionStorage.getItem('userId');
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	fetchRootFolders() {
		this.foldersCollection = this.afStore.collection(`entities/${this.entityId}/documents/root/list`, ref => ref.orderBy('name', 'asc').where('active', '==', true));
		if (environment.product === 'whitfields') {
			this.foldersCollection = this.afStore.collection(`entities/${this.entityId}/documents/folders/list`, ref =>
				ref.orderBy('name', 'asc').where('active', '==', true).where('isRoot', '==', true)
			);
		}

		return this.foldersCollection.valueChanges({ idField: 'id' });
	}

	addRootFolder(folderData) {
		console.log('🚀 ~ DocsService ~ folderData', folderData);
		this.entityName = sessionStorage.getItem('entity_name');

		// Create root folder
		this.foldersCollection = this.afStore.collection(`entities/${this.entityId}/documents/root/list`, ref => ref.orderBy('name', 'asc'));

		// Add reference
		const userRef = this.afStore.collection('users').doc(this.loggedInUser);

		// Add folder data
		folderData.created = new Date();
		folderData.createdby = userRef.ref;
		folderData.removable = true;
		folderData.path = [
			{
				location: 'documents',
				name: this.entityName,
			},
		];
		folderData.active = true;
		this.foldersCollection.add(folderData).then(rootRef => {
			const foldersRef = this.afStore.doc(`entities/${this.entityId}/documents/folders/list/${rootRef.id}`);
			foldersRef.set(folderData).then(folderRef => {
				const location = `documents/${this.entityId}/${rootRef.id}`;
				const pathArray = {
					location: location,
					name: folderData.name,
				};
				folderData.path.push(pathArray);

				rootRef.update({
					ref: foldersRef.ref,
					path: folderData.path,
					uid: rootRef.id,
					url: location,
					public: false,
					displayOnClientSite: folderData.displayOnClientSite,
				});
				foldersRef.update({
					ref: rootRef,
					path: folderData.path,
					uid: rootRef.id,
					url: location,
					public: false,
					displayOnClientSite: folderData.displayOnClientSite,
				});
			});
		});
	}

	fetchFolderDetails(docUID) {
		this.folderDoc = this.afStore.doc(`entities/${this.entityId}/documents/folders/list/${docUID}`);
		return this.folderDoc.valueChanges();
	}

	fetchSubfolders(docUID) {
		const subfoldersCollection = this.afStore.collection<Folder>(`entities/${this.entityId}/documents/folders/list/${docUID}/subfolders`, ref =>
			ref.orderBy('name', 'asc').where('active', '==', true)
		);

		return subfoldersCollection.valueChanges({ idField: 'id' });
	}

	fetchSubfolderDocs(docUID, groupId: string = 'docs') {
		return this.afStore
			.collection(`entities/${this.entityId}/documents/folders/list/${docUID}/${groupId}`, ref => {
				return groupId !== 'docs' ? ref : ref.orderBy('name', 'asc').where('active', '==', true);
			})
			.valueChanges({ idField: 'id' });
	}

	fetchSubfolderDocsDetails(docUID, groupId: string = 'docs', docFileID): Observable<any> {
		return this.afStore.doc(`entities/${this.entityId}/documents/folders/list/${docUID}/${groupId}/${docFileID}`).valueChanges();
	}

	async addSubFolder(subfolderData, docId: string, paths: string[], publicFolder: boolean) {
		const subfoldersCollection = this.afStore.collection<Folder>(`entities/${this.entityId}/documents/folders/list/${docId}/subfolders`);

		subfolderData = {
			...subfolderData,
			created: new Date(),
			createdby: this.afStore.collection('users').doc(this.loggedInUser).ref,
			path: paths,
			removable: true,
			active: true,
		};

		const subRef = await subfoldersCollection.add(subfolderData);

		const foldersRef = this.afStore.doc(`entities/${this.entityId}/documents/folders/list/${subRef.id}`);
		await foldersRef.set(subfolderData);

		const location = `documents/${this.entityId}/${subRef.id}`;
		const updatedPaths = [
			...subfolderData.path,
			{
				location,
				name: subfolderData.name,
			},
		];

		const subRefUpdate = subRef.update({
			ref: foldersRef.ref,
			path: updatedPaths,
			uid: subRef.id,
			url: location,
			public: publicFolder,
			displayOnClientSite: subfolderData.displayOnClientSite,
		});

		const foldersRefUpdate = foldersRef.update({
			ref: subRef,
			path: updatedPaths,
			uid: subRef.id,
			url: location,
			public: publicFolder,
			displayOnClientSite: subfolderData.displayOnClientSite,
		});

		await Promise.all([subRefUpdate, foldersRefUpdate]);
	}

	// UPDATE FOLDER
	async updateFolder(folder: Folder, path, docUID) {
		this.folderDoc = this.afStore.collection(`entities/${this.entityId}/documents/folders/list`).doc(`${docUID}`);
		const folderRootDoc = this.afStore.collection(`entities/${this.entityId}/documents/root/list`).doc(`${docUID}`);
		folder.modified = new Date();
		path[path.length - 1].name = folder.name;
		folder.path = path;
		await this.folderDoc.update(folder);
		await folderRootDoc.update(folder);
	}

	// DELETE FOLDER
	deleteFolders(docUID, subFolderRef) {
		this.folderDoc = this.afStore.collection(`entities/${this.entityId}/documents/folders/list`).doc(`${docUID}`);
		const folderUpdate = this.folderDoc.update({
			active: false,
		});
		const refUpdate = subFolderRef.update({
			active: false,
		});
		return Promise.all([folderUpdate, refUpdate]);
	}

	// UPDATE File
	updateFile(file, fileExt, docUID, docFileID, groupId: string = 'docs') {
		const docFile = this.afStore.doc(`entities/${this.entityId}/documents/folders/list/${docUID}/${groupId}/${docFileID}`);
		return docFile
			.update({
				name: file.name + '.' + fileExt,
			})
			.then(() => {
				const fileRef = this.afStore.doc(`entities/${this.entityId}/documents/recent/docs/${docFileID}`);
				fileRef.set(
					{
						name: file.name + '.' + fileExt,
					},
					{ merge: true }
				);
			});
	}
	updateFileTags(fileTags, docUID, docFileID, groupId: string = 'docs') {
		const docFile = this.afStore.doc(`entities/${this.entityId}/documents/folders/list/${docUID}/${groupId}/${docFileID}`);
		return docFile
			.update({
				tags: fileTags,
			})
			.then(() => {
				const fileRef = this.afStore.doc(`entities/${this.entityId}/documents/recent/docs/${docFileID}`);
				fileRef.set(
					{
						tags: fileTags,
					},
					{ merge: true }
				);
			});
	}

	downloadFile(fileName, file): void {
		const xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		xhr.onload = event => {
			/* Create a new Blob object using the response
			 *  data of the onload object.
			 */
			const blob = new Blob([xhr.response], { type: file.filetype });
			const a: any = document.createElement('a');
			a.style = 'display: none';
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = fileName;
			a.click();
			window.URL.revokeObjectURL(url);
		};
		xhr.open('GET', file.downloadFile);
		xhr.send();

		// console.log(fileName);
		// let storageRef = this.fb.storage().ref();
		// console.log('storageRef', storageRef);
		// storageRef.child(`entities/${this.entityId}/files/${fileName}`)
		//     .getDownloadURL().then((url) => {
		//         const xhr = new XMLHttpRequest();
		//         xhr.responseType = 'blob';
		//         xhr.onload = (event) => {
		//             /* Create a new Blob object using the response
		//             *  data of the onload object.
		//             */
		//             const blob = new Blob([xhr.response], { type: 'image/jpg' });
		//             const a: any = document.createElement('a');
		//             a.style = 'display: none';
		//             document.body.appendChild(a);
		//             const url = window.URL.createObjectURL(blob);
		//             a.href = url;
		//             a.download = fileName;
		//             a.click();
		//             window.URL.revokeObjectURL(url) ;
		//         };
		//         xhr.open('GET', url);
		//         xhr.send();
		//     }).catch(function(error) {
		//         // Handle any errors
		//         console.log(error);
		//     });
	}

	// DELETE DOCUMENT
	deleteDocuments(docUID, docFileID, groupId: string = 'docs') {
		const folderFile = this.afStore.doc(`entities/${this.entityId}/documents/folders/list/${docUID}/${groupId}/${docFileID}`);
		const folderFileRef = this.afStore.doc(`entities/${this.entityId}/documents/recent/docs/${docFileID}`);
		folderFile.set(
			{
				active: false,
			},
			{ merge: true }
		);

		folderFileRef.set(
			{
				active: false,
			},
			{ merge: true }
		);
		return Promise.all([folderFile, folderFileRef]);
	}

	// FETCH RECENTLY ADDED FOLDERS
	fetchRecentFolders() {
		this.recentCollection = this.afStore.collection(`entities/${this.entityId}/documents/folders/list`, ref => ref.orderBy('created', 'desc').where('active', '==', true));

		return this.recentCollection.valueChanges({ idField: 'id' });
	}

	// FETCH RECENTLY ADDED DOCUMENTS
	fetchRecentDocuments() {
		this.recentDocsCollection = this.afStore.collection(`entities/${this.entityId}/documents/recent/docs`, ref => ref.orderBy('created', 'desc').where('active', '==', true));

		return this.recentDocsCollection.valueChanges({ idField: 'id' });
	}

	// FETCH TRASH DOCUMENT DETAILS
	fetchRecentDocumentDetails(docFileUID) {
		this.recentFolderDoc = this.afStore.doc(`entities/${this.entityId}/documents/recent/docs/${docFileUID}`);
		return this.recentFolderDoc.valueChanges();
	}

	// FETCH TRASH ROOT FOLDERS
	fetchTrashRootFolders() {
		this.trashCollection = this.afStore.collection(`entities/${this.entityId}/documents/trash/rootfolders`, ref => ref.orderBy('name', 'asc'));

		return this.trashCollection.valueChanges({ idField: 'id' });
	}

	// FETCH TRASH ROOT FOLDER DETAILS
	fetchTrashRootFoldersDetails(folderUID) {
		this.trashFolderDoc = this.afStore.doc(`entities/${this.entityId}/documents/trash/rootfolders/${folderUID}`);
		return this.trashFolderDoc.valueChanges();
	}

	// RESTORE TRASH ROOT FOLDER
	restoreTrashRootFolder(restoreFolder) {
		this.folderDoc = this.afStore.collection(`entities/${this.entityId}/documents/folders/list`).doc(`${restoreFolder.uid}`);
		const folderUpdate = this.folderDoc.update({
			active: true,
		});
		const refUpdate = restoreFolder.restoreRef.update({
			active: true,
		});
		return Promise.all([folderUpdate, refUpdate]);
	}

	// DELETE TRASH ROOT FOLDER
	deleteTrashRootFolders(folderUID) {
		this.trashFolderDoc = this.afStore.doc(`entities/${this.entityId}/documents/trash/rootfolders/${folderUID}`);
		return this.trashFolderDoc.delete();
	}

	// FETCH TRASH FOLDERS
	fetchTrashFolders() {
		this.trashCollection = this.afStore.collection(`entities/${this.entityId}/documents/trash/subfolders`, ref => ref.orderBy('name', 'asc'));

		return this.trashCollection.valueChanges({ idField: 'id' });
	}

	// FETCH TRASH FOLDER DETAILS
	fetchTrashFolderDetails(folderUID) {
		this.trashFolderDoc = this.afStore.doc(`entities/${this.entityId}/documents/trash/subfolders/${folderUID}`);
		return this.trashFolderDoc.valueChanges();
	}

	// RESTORE TRASH FOLDER
	restoreTrashFolder(restoreFolder) {
		this.folderDoc = this.afStore.collection(`entities/${this.entityId}/documents/folders/list`).doc(`${restoreFolder.uid}`);
		const folderUpdate = this.folderDoc.update({
			active: true,
		});
		const refUpdate = restoreFolder.restoreRef.update({
			active: true,
		});
		return Promise.all([folderUpdate, refUpdate]);
	}

	// DELETE TRASH FOLDER
	deleteTrashFolders(folderUID) {
		this.trashFolderDoc = this.afStore.doc(`entities/${this.entityId}/documents/trash/subfolders/${folderUID}`);
		return this.trashFolderDoc.delete();
	}

	// FETCH TRASH DOCUMENTS
	fetchTrashDocuments() {
		this.trashCollection = this.afStore.collection(`entities/${this.entityId}/documents/trash/docs`, ref => ref.orderBy('name', 'asc'));

		return this.trashCollection.valueChanges({ idField: 'id' });
	}

	// FETCH TRASH DOCUMENT DETAILS
	fetchTrashDocumentDetails(docFileUID) {
		this.trashFolderDoc = this.afStore.doc(`entities/${this.entityId}/documents/trash/docs/${docFileUID}`);
		return this.trashFolderDoc.valueChanges();
	}

	// RESTORE TRASH DOCUMENT
	restoreTrashDocument(restoreFile, fileID) {
		this.trashFolderDoc = this.afStore.doc(`entities/${this.entityId}/documents/recent/docs/${fileID}`);
		const fileUpdate = this.trashFolderDoc.update({
			active: true,
		});
		const refUpdate = restoreFile.restoreRef.update({
			active: true,
		});
		return Promise.all([fileUpdate, refUpdate]);
	}

	// DELETE TRASH FILE
	deleteTrashFiles(docFileUID) {
		this.trashFolderDoc = this.afStore.doc(`entities/${this.entityId}/documents/trash/docs/${docFileUID}`);
		return this.trashFolderDoc.delete();
	}

	// DRAFTS BUCKETS
	addBucketFromDraft(name: string, draftUID: string, publicBucket = false) {
		const bucketsRef = this.afStore.collection(`entities`).doc(this.entityId).collection('communications').doc('buckets').collection('list');

		return bucketsRef
			.add({
				name: name,
				created: new Date(),
				createdby: this.loggedInUser,
				active: true,
				publicBucket: publicBucket,
			})
			.then(ref => {
				this.bucketRef = ref;

				// CREATE BUCKET LINK
				const draftRef = this.afStore.collection(`entities`).doc(this.entityId).collection('communications').doc('drafts').collection('list').doc(draftUID);
				const bucketDraftsRef = this.afStore
					.collection(`entities`)
					.doc(this.entityId)
					.collection('communications')
					.doc('buckets')
					.collection('list')
					.doc(this.bucketRef.id)
					.collection('drafts')
					.doc(draftUID);

				draftRef.set(
					{
						bucketRef: this.bucketRef,
						publicBucket,
					},
					{ merge: true }
				);

				// CREATE LINK TO DRAFT BUCKETS
				bucketDraftsRef.set(
					{
						ref: draftRef.ref,
						publicBucket,
					},
					{ merge: true }
				);

				return ref;
			});
	}

	addDocToBucketFromDraft(doc: Folder, bucketRef: any, publicBucket = false) {
		// CREATE DOC IN BUCKET
		const docData = {
			name: doc.name,
			filetype: doc.filetype,
			ref: doc.ref,
			publicBucket,
			downloadFile: doc.downloadFile,
			public: doc.public || false,
			size: doc.size,
		};
		bucketRef.collection('docs').doc(doc.id).set(docData, { merge: true });

		// CREATE BUCKET IN DOCS
		doc.ref.collection('buckets').doc(bucketRef.id).set(
			{
				ref: bucketRef,
			},
			{ merge: true }
		);
	}

	removeDocFromBucketFromDraft(doc: Folder, bucketRef: any) {
		// REMOVE DOC IN BUCKET
		bucketRef
			.collection('docs')
			.doc(doc.id)
			.delete()
			.then(() => {
				// console.log('Document removed from bucket');
			})
			.catch(err => {
				console.log(err);
			});

		// REMOVE BUCKET IN DOCS
		doc.ref
			.collection('buckets')
			.doc(bucketRef.id)
			.delete()
			.then(() => {
				// console.log('Bucket removed from document');
			})
			.catch(err => {
				console.log(err);
			});
	}

	fetchBucket(draftUID: string) {
		return this.afStore.doc(`entities/${this.entityId}/communications/drafts/list/${draftUID}`).valueChanges();
	}

	fetchBucketDocsFromDraft(ref: any) {
		this.bucketDocsCollection = this.afStore.doc(ref.path).collection('docs');

		return this.bucketDocsCollection.valueChanges({ idField: 'id' });
	}

	// *** DOCUMENTS BUCKETS SECTION *** //

	// ADD BUCKET
	addBucket(bucketData) {
		this.bucketCollection = this.afStore.collection(`entities/${this.entityId}/communications/buckets/list`, ref => ref.orderBy('name', 'asc'));

		// Add bucket data
		bucketData.created = new Date();
		bucketData.createdby = this.loggedInUser;
		bucketData.active = true;
		this.bucketCollection.add(bucketData);
	}

	// FETCH BUCKETS
	fetchBucketsList() {
		this.bucketCollection = this.afStore.collection(`entities/${this.entityId}/communications/buckets/list/`, ref => ref.orderBy('name', 'asc').where('active', '==', true));

		return this.bucketCollection.valueChanges({ idField: 'id' });
	}

	fetchBucketData(bucketID) {
		this.bucketDocument = this.afStore.doc(`entities/${this.entityId}/communications/buckets/list/${bucketID}`);
		return this.bucketDocument.valueChanges();
	}

	fetchBucketDocs(bucketID) {
		this.bucketCollection = this.afStore.collection(`entities/${this.entityId}/communications/buckets/list/${bucketID}/docs`);

		return this.bucketCollection.valueChanges({ idField: 'id' });
	}

	// ADD BUCKET FROM DOCS
	addDocToBucketFromDocs(bucketID: string, doc: Folder) {
		// CREATE DOC IN BUCKET
		const bucketDocRef = this.afStore.doc(`entities/${this.entityId}/communications/buckets/list/${bucketID}/docs/${doc.id}`);
		const bucketRef = this.afStore.doc(`entities/${this.entityId}/communications/buckets/list/${bucketID}`);

		bucketDocRef.set(
			{
				name: doc.name,
				filetype: doc.filetype,
				ref: doc.ref,
			},
			{ merge: true }
		);

		// CREATE BUCKET IN DOCS
		doc.ref.collection('buckets').doc(bucketID).set(
			{
				ref: bucketRef.ref,
			},
			{ merge: true }
		);
	}

	// UPDATE BUCKET
	updateBucket(bucket: Folder, bucketID) {
		this.bucketDocument = this.afStore.collection(`entities/${this.entityId}/communications/buckets/list`).doc(`${bucketID}`);
		bucket.modified = new Date();
		this.bucketDocument.update(bucket);
	}

	// DELETE BUCKET
	deleteBuckets(bucketID) {
		this.bucketDocument = this.afStore.collection(`entities/${this.entityId}/communications/buckets/list`).doc(`${bucketID}`);
		const bucketUpdate = this.bucketDocument.update({
			modified: new Date(),
			active: false,
		});
		return Promise.all([bucketUpdate]);
	}

	// FETCH BUCKET DOCUMENT DETAILS
	fetchBucketDocsDetails(fileRef) {
		const bucketData = this.afStore.doc(`${fileRef.path}`);
		return bucketData.valueChanges();
	}

	// DELETE BUCKET DOCUMENT
	deleteBucketDoc(bucketID, fileID, doc) {
		const bucketFile = this.afStore.doc(`entities/${this.entityId}/communications/buckets/list/${bucketID}/docs/${fileID}`);
		const docBucket = doc.ref.collection('buckets').doc(bucketID).delete();
		bucketFile.delete();
		return Promise.all([bucketFile, docBucket]);
	}
}
