import { Subject, Subscription, combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import * as _ from 'lodash';
import * as firebase from 'firebase';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { map, take, finalize } from 'rxjs/operators';

import { Changed, Entity, IncomingEntity } from './entities.model';
import { AuditLogService } from '../audit-log/audit-log.service';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';
import { GenerateThumbnailService } from '../_services/generate-thumbnail.service';
import { environment } from '../../../../../../../src/environments/environment';
import { setEntityData, setEntityId, setEntityWhitfieldsPrefix } from 'src/app/_state/entity/entity.actions';
import { selectEntityData, selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { PropertyUser, User } from '../users/user.model';
import { Property } from '@plex/properties/models/index.barrel';

export interface EntityProperty extends Property {
	id: string;
	primaryContact?: PropertyUser & { id: string };
}

@Injectable()
export class EntitiesService {
	entityDoc: AngularFirestoreDocument<Entity>;
	entityTemp: AngularFirestoreDocument<any>;
	entity = new Subject<Entity>();
	currentEntity: Entity;

	permissions = new Subject();
	permissionsList: any;
	userPermissions: any;
	permissionsSubscription: Subscription;

	sponsorsCollection: AngularFirestoreCollection<any>;
	galleryCollection: AngularFirestoreCollection<any>;
	deleteImage: AngularFirestoreDocument<any>;
	task: AngularFireUploadTask;
	itemCollection: AngularFirestoreCollection<Entity>;
	progressBarValue;
	entityId: string;

	constructor(
		private auditLogService: AuditLogService,
		public afStore: AngularFirestore,
		private storage: AngularFireStorage,
		private permissionsService: NgxPermissionsService,
		private auth: AuthenticationService,
		public generateThumbnailService: GenerateThumbnailService,
		private store: Store
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	getUsersEntities() {
		const userId = this.auth.userId;
		return this.afStore
			.collection(`users/${userId}/entities`, ref => ref.where('active', '==', true).where('product', '==', environment.product).orderBy('name', 'asc'))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data: any = a.payload.doc.data();
						data.id = a.payload.doc.id;

						// TODO: this is causing some weird behaviour that is hard to understand and isn't utilizing data we are fetching properly
						// TODO: e.g. if we stored that data we fetched here in the store we could cut down on many database reads
						this.afStore
							.doc(`entities/${a.payload.doc.id}`)
							.snapshotChanges()
							.subscribe((snap: any) => {
								if (snap.payload.data()) {
									data.template = snap.payload.data().template;
									data.deployed = snap.payload.data().deployed;
								}
							});

						// FETCH ENTITY PERMISSIONS
						let isAdmin = false;
						let isViewOnly = false;

						if (data.permissions) {
							sessionStorage.setItem('permissions', JSON.stringify(data.permissions));
							if (environment.product !== 'whitfields') {
								this.permissionsService.loadPermissions(data.permissions);
							}
							if (data.permissions.indexOf('admin') !== -1) {
								isAdmin = true;
							}
							if (data.permissions.indexOf('view_only') !== -1) {
								isViewOnly = true;
							}
						}

						data.isAdmin = isAdmin;
						data.isViewOnly = isViewOnly;

						return data;
					});
				})
			);
	}

	fetchAllowedEntities(entityId) {
		return this.afStore.doc(`amiti/config/allowedEntities/${entityId}`).valueChanges();
	}

	async setCommsDocsStatus(status, entityId) {
		const userID = sessionStorage.getItem('userId');
		const userName = `${sessionStorage.getItem('firstname')} ${sessionStorage.getItem('surname')} `;
		const data = {
			active: status,
			id: entityId,
			updated: Date.now(),
			updatedBy: userID,
			updatedByName: userName,
		};
		const entityDocRef = `/amiti/config/allowedEntities/${entityId}`;
		await this.afStore.doc(entityDocRef).set({ ...data }, { merge: true });
	}

	getAllEntities() {
		return this.afStore.collection('entities', ref => ref.where('active', '==', true).where('product', '==', environment.product).orderBy('name', 'asc')).valueChanges();
	}

	// TODO: this query needs to be moved to cloud functions and firestore rules to read entity data enabled
	prefixCheck(prefix: string) {
		let collref = this.afStore.collection('entities').ref;
		let queryref = collref.where('prefix', '==', prefix);
		return queryref.get().then(snapShot => {
			return snapShot;
		});
	}

	// TODO: this query needs to be moved to cloud functions and firestore rules to read entity data enabled
	domainCheck(q: string) {
		let collref = this.afStore.collection('entities').ref;
		let queryref = collref.where('domain', '==', q);
		return queryref.get().then(snapShot => {
			return snapShot;
		});
	}

	public async fetchSchemeRelationship(entityId: string, userId: string) {
		const managementUser = await this.afStore
			.doc<{ isSchemeExecutive?: boolean; isSchemeManager?: boolean; isExecutiveManagingAgent?: boolean }>(`entities/${entityId}/management/users/list/${userId}`)
			.ref.get();

		/*
			SX – Scheme Executive
			OT – On-site Team
			EM – Executive Managing Agent
			AT - Admin Team
			PT – Professional Team
		*/
		const relationships = [];

		if (managementUser.exists) {
			const { isSchemeExecutive, isSchemeManager, isExecutiveManagingAgent } = managementUser.data();

			if (isSchemeExecutive) {
				relationships.push('SX');
			}

			if (isSchemeManager) {
				relationships.push('OT');
			}

			if (isExecutiveManagingAgent) {
				relationships.push('EM');
			}
		}

		const managementTeam = await this.afStore.collection(`entities/${entityId}/WhitfieldsManagementTeam`).ref.where('uid', '==', userId).get();
		if (managementTeam.docs.length) {
			relationships.push('AT');
		}

		const professionalTeam = await this.afStore.collection(`entities/${entityId}/WhitfieldsProfessionalTeam`).ref.where('uid', '==', userId).get();
		if (professionalTeam.docs.length) {
			relationships.push('PT');
		}

		return relationships;
	}

	//FETCH ENTITY DETAILS
	//TODO Investigate why this entityID argument is here if it is not being used.
	getEntityDetails(entityId: String) {
		//TODO:find a way to reload on update, as the below call requires a page refresh to see new data
		return this.store.select(selectEntityData);
	}

	getUserEntityDetails(entityId: String) {
		const userID = sessionStorage.getItem('userId');
		return this.afStore.doc(`users/${userID}/entities/${entityId}`).valueChanges();
	}

	getUsersForEntity() {
		return this.afStore
			.collection(`entities/${this.entityId}/users`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data: any = a.payload.doc.data();
						data.id = a.payload.doc.id;
						data.fullName = data.firstname + ' ' + data.surname;
						return data;
					});
				})
			);
	}

	getPropertiesForEntity(active = true) {
		return this.afStore
			.collection<Property>(`entities/${this.entityId}/properties`, ref => ref.orderBy('property_number', 'asc').where('active', '==', active))
			.valueChanges({ idField: 'id' });
	}

	public getPropertiesWithPrimaryContactForEntity(active = true): Observable<Promise<EntityProperty[]>> {
		const propertiesPath = `entities/${this.entityId}/properties`;
		return this.afStore
			.collection<Property>(propertiesPath, ref => {
				return ref.orderBy('property_number', 'asc').where('active', '==', active);
			})
			.valueChanges({ idField: 'id' })
			.pipe(
				map(properties => {
					return Promise.all(
						properties.map(async property => {
							const primaryContactQuery = await this.afStore
								.collection<PropertyUser>(`${propertiesPath}/${property.id}/users`)
								.ref.where('active', '==', true)
								.where('type', '==', 'primary')
								.orderBy('firstname')
								.limit(1)
								.get();

							if (primaryContactQuery.empty) {
								return property;
							}

							const [primaryContact] = primaryContactQuery.docs;
							const primaryContactData = primaryContact.data();
							const { firstname, surname } = primaryContactData;
							return {
								...property,
								primaryContact: {
									...primaryContactData,
									firstname: firstname.trim(),
									surname: surname.trim(),
									id: primaryContact.id,
								},
							};
						})
					);
				})
			);
	}

	getSubEntitiesForEntity() {
		return this.afStore.collection(`entities/${this.entityId}/subentities`).valueChanges();
	}

	/**
	 * Explicit force of state update.
	 * Use when subscription to update state might cause unforeseen consequences.
	 */
	public async forceEntityStateUpdate(entityId: string) {
		const entity = await this.afStore.doc<Entity>(`entities/${entityId}`).ref.get();
		this.store.dispatch(setEntityData(entity.data()));
	}

	setEntity(entity: Entity): Promise<any> {
		const userID = sessionStorage.getItem('userId');
		this.entity.next(entity);
		this.currentEntity = entity;
		this.store.dispatch(setEntityData(entity));

		if (entity.whitfieldsPrefix === 'WPM') {
			sessionStorage.setItem('entity_id', 'whitfields');
		} else {
			this.store.dispatch(setEntityId({ entityId: entity.id }));
			this.store.dispatch(setEntityWhitfieldsPrefix({ whitfieldsPrefix: entity.whitfieldsPrefix ? entity.whitfieldsPrefix : '' }));
			// TODO: we need to see if we can centralise this data fetching to a single, because finding the right place to set the entityData is cumbersome
			this.afStore
				.doc<IncomingEntity>(`entities/${this.entityId}`)
				.get()
				.subscribe(entity => {
					this.store.dispatch(setEntityData(entity.data()));
				});
			sessionStorage.setItem('entity_id', entity.id);
		}

		sessionStorage.setItem('entity_name', entity.name);
		sessionStorage.setItem('entity_type', entity.entity_type);

		// UNSUBSCRIBE FROM PREVIOUS ENTITY PERMISSIONS AND FLUSH OUT PERMISSIONS
		if (this.permissionsSubscription) {
			this.permissionsSubscription.unsubscribe();
			this.permissionsService.flushPermissions();
			sessionStorage.removeItem('permissions');
		}

		// FETCH ENTITY PERMISSIONS
		this.permissionsSubscription = this.getUserPermissionsForEntity(userID, entity.id).subscribe((userEntityPermissions: any) => {
			if (userEntityPermissions.permissions) {
				this.permissions.next(userEntityPermissions.permissions);
				if (environment.product !== 'whitfields') {
					this.permissionsService.loadPermissions(userEntityPermissions.permissions);
				} else {
					this.auth.loadUserRoles();
				}
			}
		});
		return Promise.resolve();
	}

	getCurrentEntityID() {
		return this.entityId;
	}

	getCurrentEntityName() {
		// TODO: refactor to use ngrx store
		return sessionStorage.getItem('entity_name');
	}

	get newId() {
		return this.afStore.collection('_').doc().ref.id;
	}

	async addEntity(entity: Entity, id?: string) {
		entity.product = environment.product;
		entity.deployed = false;
		entity.active = true;
		entity.createdby = sessionStorage.getItem('userId');
		entity.created = Date.now();
		entity.primaryContactID = sessionStorage.getItem('userId');
		entity.collectionGuarantee = false;
		entity.receivesLevyAdvance = false;

		if (id) {
			const entityRef = this.afStore.doc(`entities/${id}`).ref;
			await entityRef.set(entity);
			return entityRef;
		}

		return this.afStore.collection(`entities`).add(entity);
	}

	setGenerateEntityForUser(userId, entityName, refId, disableClientSiteDeploy, websiteUrl) {
		const userEntity = this.afStore.collection('users').doc(userId).collection('entities').doc(refId);
		return userEntity.set(
			{
				active: true,
				name: entityName,
				generating: true,
				disableClientSiteDeploy: disableClientSiteDeploy ? disableClientSiteDeploy : false,
				product: environment.product,
				websiteUrl: websiteUrl,
			},
			{ merge: true }
		);
	}

	entityAddedNotification(refId, entityName, userId) {
		const entityAddedNotificationRef = this.afStore.collection('pending').doc(refId);
		return entityAddedNotificationRef.set({
			request: 'entityAddedNotifications',
			id: refId,
			entityName: entityName,
			environmentAdmin: environment.admin,
			environmentOlympus: environment.olympus,
			environmentType: environment.product,
			createdBy: userId,
		});
	}

	async updateEntity(entity: Entity, changed?: Array<Changed>) {
		this.entityDoc = this.afStore.doc(`entities/${entity.id}`);
		const publicEntityDoc = this.afStore.doc(`public/${entity.id}`);

		try {
			await this.checkDates(entity);

			await this.entityDoc.set(entity, { merge: true });
			await publicEntityDoc.set(entity, { merge: true });
			this.store.dispatch(setEntityData(entity));
			let logData = {
				name: entity.name,
				description: 'Entity details updated',
				type: 'update',
				category: 'entities',
				created: Date.now(),
				changed,
			};
			return await this.auditLogService.addAudit(logData);
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async checkDates(entity) {
		entity.incorporation_date = entity.incorporation_date ? this.formatDate(entity.incorporation_date) : '';
		entity.dateTakeOn = entity.dateTakeOn ? this.formatDate(entity.dateTakeOn) : '';
		entity.ownBankAccountDate = entity.ownBankAccountDate ? this.formatDate(entity.ownBankAccountDate) : '';
		entity.agmDate = entity.agmDate ? this.formatDate(entity.agmDate) : '';
		entity.fireServiceDate = entity.fireServiceDate ? this.formatDate(entity.fireServiceDate) : '';
		entity.previousAgmDate = entity.previousAgmDate ? this.formatDate(entity.previousAgmDate) : '';
		entity.sgmDate = entity.sgmDate ? this.formatDate(entity.sgmDate) : '';
		entity.previousExecutiveMeetingDate = entity.previousExecutiveMeetingDate ? this.formatDate(entity.previousExecutiveMeetingDate) : '';
	}

	formatDate(date) {
		if (date === '') {
			return '';
		}
		const [day, month, year] = date.split('/');

		return new Date(year, +month - 1, day);
	}

	updateEntityTemplate(entityID, entityTemplate: any, entityContactPersonDetails: any, entitySocialLinks: any, entityWorkingHours: any, entityAddons: any) {
		this.entityTemp = this.afStore.doc(`entities/${entityID}`);
		entityTemplate.contact_person_details = entityContactPersonDetails;
		entityTemplate.social_links = entitySocialLinks;
		entityTemplate.working_hours = entityWorkingHours;
		entityTemplate.addons = entityAddons;
		this.entityTemp.set(
			{
				template: entityTemplate,
			},
			{ merge: true }
		);
	}

	updateEntityEmail(entityEmail) {
		this.entityTemp = this.afStore.doc(`entities/${this.entityId}`);
		return this.entityTemp.set(
			{
				template: {
					email_address: entityEmail,
				},
			},
			{ merge: true }
		);
	}

	updateEntityContact(primaryContactId) {
		const entityContact = this.afStore.doc(`entities/${this.entityId}`);
		entityContact.set(primaryContactId, { merge: true });
	}

	updateEntityTaskWatcher(primaryTaskWatcher) {
		const entityContact = this.afStore.doc(`entities/${this.entityId}`);
		entityContact.set(primaryTaskWatcher, { merge: true });
	}

	getEntityCounts() {
		const propertiesRef = this.afStore.collection(`entities/${this.entityId}/properties`, ref => ref.where('active', '==', true)).valueChanges();
		const usersRef = this.afStore.collection(`entities/${this.entityId}/users`, ref => ref.where('active', '==', true)).valueChanges();
		return new Observable(observer => {
			combineLatest(propertiesRef, usersRef).subscribe(data => {
				const counts = {
					usersCount: data[1].length,
					propertiesCount: data[0].length,
				};
				observer.next(counts);
				observer.complete();
			});
		});
	}

	collectData(entity: Entity) {
		this.itemCollection = this.afStore.collection('entities', ref => {
			return ref.where('domain', '==', entity.domain);
		});
		return this.afStore.collection(`entities`, ref => ref.orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	// Entity template uploads
	async uploadEntityWebsiteImage(entityId: string, imageType: 'Logo' | 'Favicon' | 'Banner' | string, imageData: any = null) {
		if (!imageData) {
			return Promise.reject('No image data');
		}

		const entityRef = this.afStore.doc<Entity>(`entities/${entityId}`).ref;
		const { template }: Entity = (await entityRef.get()).data();

		if (template.downloadBanner !== '' && environment.product === 'whitfields') {
			await entityRef.set({ template: { downloadBanner: '' } }, { merge: true });
		}

		const fileName = `${new Date().getTime()}_${imageType.toString().toLowerCase()}.png`;
		const path = `entities/${entityId}/templates/${fileName}`;
		const task = this.storage.upload(path, imageData);
		const ref = this.storage.ref(path);

		return new Promise<string>((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe(async url => {
							const data = {
								template: {
									[`download${imageType}`]: url,
								},
							};

							try {
								await this.saveDataToFirestore(entityId, data);
								resolve(url);
							} catch (err) {
								return reject('Failed to save data to firestore: ' + err);
							}
						});
					})
				)
				.subscribe();
		});
	}

	private async saveDataToFirestore(entityID, downloadData) {
		const entityRef = this.afStore.doc(`entities/${entityID}`);
		return entityRef.set(downloadData, { merge: true });
	}

	async uploadContactPersonImage(entityID, contactPersonImage = null, contactPersonImageBase64 = '') {
		const fileName = `${new Date().getTime()}_contact_person.png`;
		const path = `entities/${entityID}/templates/${fileName}`;
		const task = this.storage.upload(path, contactPersonImage);
		const ref = this.storage.ref(path);

		const url = await new Promise<string>(res => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe(url => {
							res(url);
						});
					})
				)
				.subscribe();
		});

		try {
			await this.saveContactPersonDataFirestore(entityID, url, contactPersonImageBase64);
			return url;
		} catch (error) {
			throw new Error('Failed to save data to firestore: ' + error);
		}
	}

	private async saveContactPersonDataFirestore(entityID, downloadData, contactPersonImage) {
		const entityRef = this.afStore.doc<{ template }>(`entities/${entityID}`).ref;
		const entity = await entityRef.get();
		const { template } = entity.data();

		const thumbnailData = await new Promise<string>(res => {
			this.generateThumbnailService.generateFromImage(contactPersonImage, 333, 443, 1, thumbnailData => {
				res(thumbnailData);
			});
		});

		template.contact_person_details.downloadContactPersonImage = downloadData;
		template.contact_person_details.downloadContactPersonImageThumbnail = thumbnailData;

		return entityRef.update({ template });
	}

	uploadBrochure(entityID, selectedFiles) {
		let file = selectedFiles.item(0);
		if (file.type.split('/')[1] !== 'pdf') {
			console.error('unsupported file type :( ');
			return;
		}

		const fileName = `${new Date().getTime()}_brochure.pdf`;
		const path = `entities/${entityID}/templates/${fileName}`;
		const task = this.storage.upload(path, file);
		const ref = this.storage.ref(path);

		task.percentageChanges().subscribe(value => {
			this.progressBarValue = value.toFixed(2);
		});

		return new Promise((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe(url => {
							this.saveBrochureDataFirestore(entityID, url)
								.then(() => {
									return resolve('');
								})
								.catch(err => {
									return reject('Failed to save data to firestore: ' + err);
								});
						});
					})
				)
				.subscribe();
		});
	}

	private saveBrochureDataFirestore(entityID, downloadData) {
		const entityRef = this.afStore.doc(`entities/${entityID}`);
		let template;
		return entityRef
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then((snap: any) => {
				template = snap.payload.data().template;
				template.downloadBrochure = downloadData;
				return entityRef.update({ template: template });
			});
	}

	sendAdminNotificationEmail(entityID: string, userID: string) {
		const pendingEmailAdminNotificationRef = this.afStore.collection('pending');

		pendingEmailAdminNotificationRef.add({
			request: 'emailAdminNotification',
			entityId: entityID,
			userId: userID,
			environmentAdmin: environment.admin,
			product: environment.product,
		});
	}

	private async sendAdminNotification(entityId: string, { email }: User, additionalPendingRequestFields: any) {
		const pendingRequest = {
			email,
			entityId,
			created: new Date(),
			environmentAdmin: environment.admin,
			product: environment.product,
			...additionalPendingRequestFields,
		};

		await this.afStore.collection('pending').add(pendingRequest);
	}

	public async sendAdminAddedNotification(entityId: string, user: User) {
		const userInfo = this.auth.userDetails;
		const userAccountsRef = await this.afStore.collection(`entities/${entityId}/users/${user.id}/accounts`).ref.get();
		const userAccountNames = userAccountsRef.docs.map(account => {
			return account.get('name');
		});

		const additionalPendingRequestFields = {
			request: 'emailAdminAddedNotification',
			userName: `${user.firstname} ${user.surname}`,
			addedBy: `${userInfo.firstname} ${userInfo.surname}`,
			userAccountNames,
			created: new Date(),
		};

		await this.sendAdminNotification(entityId, user, additionalPendingRequestFields);
	}

	public async sendAdminRemovedNotification(entityId: string, user) {
		const userInfo = this.auth.userDetails;
		const userAccountsRef = await this.afStore.collection(`entities/${entityId}/users/${user.id}/accounts`).ref.get();
		const userAccountNames = userAccountsRef.docs.map(account => {
			return account.get('name');
		});

		const additionalPendingRequestFields = {
			request: 'emailAdminRemovedNotification',
			userName: user.fullName,
			removedBy: `${userInfo.firstname} ${userInfo.surname}`,
			userAccountNames,
			created: new Date(),
		};

		await this.sendAdminNotification(entityId, user, additionalPendingRequestFields);
	}

	sendManagerAddedNotification(entityID: string, userDetails) {
		const pendingManagerAddedNotificationRef = this.afStore.collection('pending');
		const userInfo = this.auth.userDetails;

		pendingManagerAddedNotificationRef.add({
			request: 'emailManagerAddedNotification',
			userName: userDetails.fullName,
			email: userDetails.email,
			entityId: entityID,
			environmentAdmin: environment.admin,
			product: environment.product,
			addedBy: `${userInfo.firstname} ${userInfo.surname}`,
		});
	}

	sendManagerRemovedNotification(entityID: string, userDetails) {
		const pendingManagerRemovedNotificationRef = this.afStore.collection('pending');
		const userInfo = this.auth.userDetails;

		pendingManagerRemovedNotificationRef.add({
			request: 'emailManagerRemovedNotification',
			userName: userDetails.fullName,
			email: userDetails.email,
			entityId: entityID,
			environmentAdmin: environment.admin,
			product: environment.product,
			removedBy: `${userInfo.firstname} ${userInfo.surname}`,
		});
	}

	fetchSponsors(entityID) {
		this.sponsorsCollection = this.afStore.collection(`entities/${entityID}/serviceProviders`, ref => ref.where('active', '==', true).where('sponsor', '==', true).limit(5));

		return this.sponsorsCollection.valueChanges({ idField: 'id' });
	}

	deleteSponsor(entityID: string, sponsorID: string) {
		const spRef = this.afStore.doc(`entities/${entityID}/serviceProviders/${sponsorID}`);

		spRef.set(
			{
				sponsor: false,
			},
			{ merge: true }
		);
	}

	fetchGalleryImages(entityID) {
		this.galleryCollection = this.afStore.collection(`entities/${entityID}/gallery`, ref => ref.orderBy('name', 'asc'));

		return this.galleryCollection.valueChanges({ idField: 'id' });
	}

	deleteGalleryImage(entityID, imageID) {
		this.deleteImage = this.afStore.doc(`entities/${entityID}/gallery/${imageID}`);
		return this.deleteImage.delete();
	}

	deleteBrochure(entityID) {
		const entityRef = this.afStore.doc(`entities/${entityID}`);
		return entityRef.update({
			'template.downloadBrochure': firebase.default.firestore.FieldValue.delete(),
		});
	}

	//PERMISSIONS
	getUserPermissionsForEntity(userId: string, entityId?) {
		let entityID = '';
		if (environment.product === 'whitfields') {
			entityID = 'whitfields';
		} else {
			entityID = this.getCurrentEntityID();
		}
		return this.afStore.doc(`/users/${userId}/entities/${entityID}`).valueChanges();
	}
	getRedirectUserPermissionsForEntity(userId: string, entityId: string) {
		return this.afStore.doc(`/users/${userId}/entities/${entityId}`).valueChanges();
	}

	fetchEntityModules() {
		return this.afStore.collection(`entities/${this.entityId}/modules`).valueChanges();
	}

	getTaskCounts() {
		const userID = sessionStorage.getItem('userId');

		const tasksRef = this.afStore.collection(`entities/${this.entityId}/tasks`, ref => ref.where('assignedToId', '==', userID).where('active', '==', true));
		return tasksRef.valueChanges();
	}

	// WHITFIELDS GET OCC

	fetchUsersListWhere(type) {
		const t = type.toLowerCase();
		const memberEntitiesRef = this.afStore.collection(`entities/whitfields/users`, ref => ref.where('active', '==', true).where('permissions', 'array-contains', t));

		return memberEntitiesRef.valueChanges({ idField: 'uid' });
	}

	// add user to whitfields management team
	addEntityWhitfieldsManagementTeamMember(entityId, user, permission) {
		const data = {
			active: true,
			created: Date.now(),
			email: user.email,
			firstname: user.firstname,
			type: permission,
			ref: `/users/${user.uid}`,
			surname: user.surname,
			uid: user.uid,
			entityId: entityId,
			permissions: user.permissions,
		};
		return this.afStore.doc(`entities/${entityId}/WhitfieldsManagementTeam/${permission}`).set(data);
	}

	removeManagementMember(userId, permission) {
		let updateManagementPermissions;
		let updateUserPermissions;
		const user = this.afStore.doc(`users/${userId}/entities/whitfields`);

		updateUserPermissions = user.ref.get().then(doc => {
			const userData = doc.data() as any;
			const newPermissions = userData.permissions.filter(perm => {
				return perm !== permission;
			});
			return user.update({ permissions: newPermissions }).then(() => {
				return this.afStore
					.doc(`entities/whitfields/users/${userId}`)
					.ref.get()
					.then(doc => {
						if (doc.exists) {
							return Promise.resolve();
						} else {
							let userPerms = newPermissions;
							if (newPermissions.indexOf('admin')) {
								userPerms = userData.permissions.filter(perm => {
									return perm !== 'admin';
								});
							}
							if (newPermissions.indexOf('view_only')) {
								userPerms = userData.permissions.filter(perm => {
									return perm !== 'view_only';
								});
							}
							return user.update({ permissions: userPerms });
						}
					});
			});
		});

		return Promise.all([updateManagementPermissions, updateUserPermissions]);
	}
}
