import { Injectable, resolveForwardRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { GenerateThumbnailService } from '../../_services/generate-thumbnail.service';
import { Upload } from './upload';
import * as _ from 'lodash';
import { AuthenticationService } from '../../../../../../auth/_services';
import { PropertiesService } from '../../properties/properties.service';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { FinService } from '@plex/fin/services/fin.service';

@Injectable()
export class FilesService {
	file: any = {};
	loggedInUser = {
		uid: '',
		userFirstname: '',
		userSurname: '',
	};
	userId: string;

	currentUpload: Upload;
	dropzoneActive: boolean = false;
	// Main task
	task: AngularFireUploadTask;

	// Progress monitoring
	percentage: Observable<number>;
	snapshot: Observable<any>;

	// Download URL
	downloadURL: Observable<string>;

	// State for dropzone CSS toggling
	isHovering: boolean;

	private uploadingFile: BehaviorSubject<any>;
	fileResult = new BehaviorSubject<any>({});
	fileUploaded: boolean = false;
	entityId: any;

	constructor(
		private storage: AngularFireStorage,
		public generateThumbnailsService: GenerateThumbnailService,
		public afStore: AngularFirestore,
		private functions: AngularFireFunctions,
		private auth: AuthenticationService,
		private propertiesService: PropertiesService,
		private store: Store,
		private finService: FinService
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
		this.uploadingFile = new BehaviorSubject<boolean>(false);
		this.auth.user.subscribe(userDetails => {
			if (userDetails) {
				this.userId = userDetails.uid;
				// Get Logged in User
				this.loggedInUser.uid = userDetails.uid;
				this.loggedInUser.userFirstname = userDetails.firstname;
				this.loggedInUser.userSurname = userDetails.surname;
			}
		});
	}

	public getUploadStatus(): Observable<any> {
		return this.uploadingFile.asObservable();
	}

	public getFiles(filesRef) {
		return filesRef.valueChanges({ idField: 'id' });
	}

	removeTaskFile(fileRef) {
		return this.afStore.doc(fileRef).update({ active: false });
	}

	public removeFile(fileRef, fileId: string) {
		return fileRef.doc(fileId).update({ active: false });
	}

	getSignedUrl(filePath: string) {
		return this.functions.httpsCallable(`docs-core-getSignedUrl`)(filePath).toPromise();
	}

	writeToLog(taskRefNo: string, taskId: string) {
		const logEntry = `${taskRefNo} download all files too large, files exceeded 10MB`;

		const data = {
			taskRefNo,
			taskId,
			created: new Date(),
			createdBy: this.loggedInUser.uid,
			createdByName: this.loggedInUser.userFirstname + ' ' + this.loggedInUser.userSurname,
			logEntry,
		};

		return this.afStore.collection(`amiti/logs/taskFileDownloadAlerts`).add(data);
	}

	handleDrop(fileList: FileList, saveRef?, storageRef?: FixMeAny, uploadType?: string, uploadId?: string) {
		let filesIndex = _.range(fileList.length);

		_.each(filesIndex, idx => {
			this.currentUpload = new Upload(fileList[idx]);
			this.startUpload(this.currentUpload, saveRef, storageRef, uploadType, uploadId);
		});
	}

	async handleDropAsync(fileList: FileList, saveRef?, storageRef?: FixMeAny, uploadType?: string, uploadId?: string) {
		let filesIndex = _.range(fileList.length);

		const uploadPromise = new Promise((resolve, reject) => {
			_.each(filesIndex, async idx => {
				this.currentUpload = new Upload(fileList[idx]);
				await this.startUpload(this.currentUpload, saveRef, storageRef, uploadType, uploadId)
					.then(ref => {
						resolve(ref);
					})
					.catch(err => {
						reject(err);
					});
			});
		});

		return uploadPromise;
	}

	async startUpload(upload: Upload, saveRef?, storageRef?, uploadType?, uploadId?) {
		this.setUploadStatus(true, upload.file.name);
		const fileName = `${new Date().getTime()}_${upload.file.name ? upload.file.name : upload.name}`;
		let path = `entities/${this.entityId}/files/${fileName}`;

		if (storageRef) {
			path = `newsletters/send/${fileName}`;
		}

		const task = this.storage.upload(path, upload.file);
		const ref = this.storage.ref(path);
		this.percentage = task.percentageChanges();
		this.snapshot = task.snapshotChanges().pipe(
			tap(snap => {
				if (snap.bytesTransferred === snap.totalBytes) {
				}
			})
		);

		let reader = new FileReader();
		reader.readAsDataURL(upload.file);
		reader.onerror = error => {
			error;
		};
		const readerPromise = new Promise(res => {
			reader.onload = () => {
				new Promise((resolve, reject) => {
					task.snapshotChanges()
						.pipe(
							finalize(() => {
								const downloadURL = ref.getDownloadURL();
								downloadURL.subscribe(url => {
									if (saveRef) {
										if (upload.file.type.indexOf('image') !== -1) {
											this.generateThumbnailsService.generateFromImage(reader.result, 700, 700, 1, thumbnailData => {
												const file = {
													downloadFile: url,
													downloadFileThumbnail: thumbnailData,
													name: upload.file.name ? upload.file.name : upload.name,
													path: path,
													size: upload.file.size.toString(),
													created: new Date().getTime(),
													createdByUID: this.loggedInUser.uid,
													createdByName: this.loggedInUser.userFirstname + ' ' + this.loggedInUser.userSurname,
													filetype: upload.file.type,
													active: true,
													saved: true,
													tags: [],
													category: upload.file['category'] ? upload.file['category'] : null,
													fileName: upload.file['fileName'] ? upload.file['fileName'] : null,
													paymentId: upload.file['paymentId'] ? upload.file['paymentId'] : null,
												};

												this.saveFileDataFirestore(saveRef, file).then(refe => {
													this.file = file;
													this.setUploadStatus(false, upload.file.name ? upload.file.name : upload.name);
													this.fileUploaded = true;
													resolve(refe);
												});
												switch (uploadType) {
													case 'property':
														this.propertiesService.propertyFileHistoryLog(file, uploadId, 'Added');
														break;
													case 'debtors':
														this.finService.addFileLogToAccount(uploadId, 'Added', file);
												}
											});
										} else {
											const file = {
												downloadFile: url,
												name: upload.file.name ? upload.file.name : upload.name,
												path: path,
												size: upload.file.size.toString(),
												created: new Date().getTime(),
												createdByUID: this.loggedInUser.uid,
												createdByName: this.loggedInUser.userFirstname + ' ' + this.loggedInUser.userSurname,
												filetype: upload.file.type,
												active: true,
												saved: true,
												category: upload.file['category'] ? upload.file['category'] : null,
												fileName: upload.file['fileName'] ? upload.file['fileName'] : null,
												paymentId: upload.file['paymentId'] ? upload.file['paymentId'] : null,
											};

											this.saveFileDataFirestore(saveRef, file).then(refe => {
												this.file = file;
												this.setUploadStatus(false, upload.file.name ? upload.file.name : upload.name);
												this.fileUploaded = true;
												resolve(refe);
											});
											switch (uploadType) {
												case 'property':
													this.propertiesService.propertyFileHistoryLog(file, uploadId, 'Added');
													break;
												case 'debtors':
													this.finService.addFileLogToAccount(uploadId, 'Added', file);
											}
										}
									} else {
										const file = {
											downloadFile: url,
											name: upload.file.name ? upload.file.name : upload.name,
											path: path,
											size: upload.file.size.toString(),
											created: new Date().getTime(),
											createdByUID: this.loggedInUser.uid,
											createdByName: this.loggedInUser.userFirstname + ' ' + this.loggedInUser.userSurname,
											filetype: upload.file.type,
											active: true,
											saved: true,
											tags: [],
										};
										this.file = file;
										this.setUploadStatus(false, upload.file.name ? upload.file.name : upload.name);
										this.fileUploaded = true;
									}
								});
							})
						)
						.subscribe();
				}).then(ref => res(ref));
			};
		});
		return await readerPromise;
	}

	handleGalleryDrop(fileList: FileList, saveRef) {
		let filesIndex = _.range(fileList.length);

		_.each(filesIndex, idx => {
			this.currentUpload = new Upload(fileList[idx]);
			this.startGalleryUploadTest(this.currentUpload, saveRef, idx);
		});
	}

	startGalleryUploadTest(upload: Upload, saveRef, idx) {
		this.setUploadStatus(true, upload.file.name);

		let reader = new FileReader();
		reader.readAsDataURL(upload.file);
		reader.onerror = error => {
			error;
		};
		return (reader.onload = () => {
			return new Promise((resolve, reject) => {
				this.generateThumbnailsService.generateFromImage(reader.result, 700, 700, 1, imageData => {
					const fileName = `${new Date().getTime()}_${upload.file.name}`;
					const path = `${saveRef.pathRef}${fileName}`;
					const ref = this.storage.ref(path);
					const task = ref.putString(imageData, 'data_url');
					let count = saveRef.order + idx;
					this.snapshot = task.snapshotChanges().pipe(
						tap(snap => {
							if (snap.bytesTransferred === snap.totalBytes) {
							}
						})
					);
					return new Promise((resolve, reject) => {
						task.snapshotChanges()
							.pipe(
								finalize(() => {
									const downloadURL = ref.getDownloadURL();
									downloadURL.subscribe(url => {
										this.generateThumbnailsService.generateThumbFromImage(reader.result, 370, 370, 1, thumbnailData => {
											const thumbFileName = `${new Date().getTime()}_${upload.file.name}_thumb`;
											const thumbPath = `${saveRef.pathRef}${thumbFileName}`;
											const thumbRef = this.storage.ref(thumbPath);
											const thumbTask = thumbRef.putString(thumbnailData, 'data_url');
											return new Promise((resolve, reject) => {
												thumbTask
													.snapshotChanges()
													.pipe(
														finalize(() => {
															const downloadThumbURL = thumbRef.getDownloadURL();
															downloadThumbURL.subscribe(thumbUrl => {
																const file = {
																	downloadFile: url,
																	name: upload.file.name,
																	downloadFileThumbnail: thumbUrl,
																	path: path,
																	path_thumb: thumbPath,
																	size: upload.file.size.toString(),
																	created: new Date().getTime(),
																	createdByUID: this.loggedInUser.uid,
																	createdByName: this.loggedInUser.userFirstname + ' ' + this.loggedInUser.userSurname,
																	filetype: upload.file.type,
																	active: true,
																	order: count++,
																};

																this.saveGalleryFileDataFirestore(saveRef, file).then(() => {
																	this.setUploadStatus(false, upload.file.name);
																	this.fileUploaded = true;
																	resolve(undefined);
																});
															});
														})
													)
													.subscribe();
											});
										});
									});
								})
							)
							.subscribe();
					});
				});
			});
		});
	}

	saveGalleryFileDataFirestore(saveRef, upload: Upload) {
		return this.saveFileDataFirestore(saveRef.dbRef, upload).then(ref => {
			if (saveRef.origin === 'Properties Gallery') {
				if (upload.order === 0) {
					let imgInfo = {
						propertyUID: saveRef.propertyUID,
						downloadFileThumbnail: upload.downloadFileThumbnail,
						uid: ref.id,
					};
					this.propertiesService.updatePropertyFeatureImage(imgInfo);
				}
			}
		});
	}

	public setUploadStatus(newValue: boolean, fileName: string): void {
		const values = {
			uploading: newValue,
			fileName,
		};
		this.uploadingFile.next(values);
	}

	saveFileDataFirestore(saveRef, upload: Upload) {
		// console.log(saveRef);
		const collectionRef = this.afStore.collection(saveRef); //ref
		// console.log('upload save to db', upload);

		return collectionRef.add(upload).then(ref => {
			this.fileResult.next({ ref: ref, doc: upload });
			ref.update({
				ref: ref,
			});

			return ref;
		});
	}
	//TODO: this falls in with PART 2 of AM-1631
	// createZipFolder(filesData) {
	// 	const pendingDownloadAllFilesRequestRef = this.afStore.collection('pending');

	// 	return pendingDownloadAllFilesRequestRef.add({
	// 		request: 'downloadAllFilesRequest',
	// 		created: new Date(),
	// 		createdBy: sessionStorage.getItem('userId'),
	// 		createdByName: sessionStorage.getItem('userFirstname') + ' ' + sessionStorage.getItem('userSurname'),
	// 		filesData,
	// 		entityId: this.entityId,
	// 		product: environment.product,
	// 	});
	// }
}
