import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortletCardContainerComponent } from './portlet-card.component';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';

@NgModule({
	declarations: [PortletCardContainerComponent],
	imports: [CommonModule, NgxPermissionsModule.forChild()],
	exports: [PortletCardContainerComponent],
})
export class PortletCardModule {
	permissions;
	constructor(private permissionsService: NgxPermissionsService) {}
}
