import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthenticationService } from '@auth/_services/authentication.service';
import { ManagementUser } from './management.model';
import { User } from '../users/user.model';
import { EntitiesService } from '../entities/entities.service';
import { UsersService } from '../users/users.service';
import { CommunicationsService } from '../communications/communications.service';
import { PopOverService } from '../../../../../_services/popovers.service';
import { environment } from '../../../../../../environments/environment';
import { config } from '../../../../../../../src/configs/configs';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';
import { startCase } from 'lodash';

interface Type {
	name: string;
	value: string;
}

declare const toastr: any;

@Component({
	selector: 'app-management-team',
	templateUrl: './management-team.component.html',
	styleUrls: ['./management-team.component.css'],
})
export class ManagementTeamComponent implements OnInit, OnDestroy {
	public product = environment.product;
	public enableSchemeExecutiveAndManagingAgent = true;
	public startCase = startCase;
	config = config[environment.product];

	usersCollection: AngularFirestoreCollection<ManagementUser[]>;
	userDoc: AngularFirestoreDocument<ManagementUser>;
	user: ManagementUser;
	existingUserType = '';
	newUserType = '';
	inviteUserType;
	users: ManagementUser[];
	usersList: Observable<any>;
	userProfiles: Observable<any>;
	existingUsersList: User[];
	userSubscription: Subscription;
	fetchManagementUsersSubscription: Subscription;
	managementListSubscription: Subscription;
	showAddSection = false;
	showAddProfileSection = false;
	addingSpinner = false;
	showRemoveProfileSection = false;
	canRemoveAdmin = true;
	hasUsersCells: boolean = false;
	generatingSms: boolean = false;
	inviteLoader: boolean = false;
	addExistingLoader: boolean = false;
	checkIfOthersAreSelected: boolean = false;
	viewOnlyDisabled: boolean = false;
	userAdminDisabled: boolean = false;

	otherSelected = false;
	otherNewSelected = false;
	addProfileOtherSelected = false;

	addExistingUserForm: FormGroup;
	addNewUserForm: FormGroup;
	addProfileForm: FormGroup;

	showPopover: boolean;
	plexTypes = [
		{
			name: 'Chairperson',
			value: 'Chairperson',
		},
		{
			name: 'Communications',
			value: 'Communications',
		},
		{
			name: 'Estate Manager',
			value: 'Estate Manager',
		},
		{
			name: 'Finance',
			value: 'Finance',
		},
		{
			name: 'Gardening',
			value: 'Gardening',
		},
		{
			name: 'Maintenance',
			value: 'Maintenance',
		},
		{
			name: 'Other',
			value: 'Other',
		},
		{
			name: 'Scheme Executive',
			value: 'Scheme Executive',
		},
		{
			name: 'Secretary',
			value: 'Secretary',
		},
		{
			name: 'Security',
			value: 'Security',
		},
	];

	faithTypes = [
		{
			name: 'Chairperson',
			value: 'Chairperson',
		},
		{
			name: 'Communications',
			value: 'Communications',
		},
		{
			name: 'Finance',
			value: 'Finance',
		},
		{
			name: 'Gardening',
			value: 'Gardening',
		},
		{
			name: 'Other',
			value: 'Other',
		},
		{
			name: 'Secretary',
			value: 'Secretary',
		},
		{
			name: 'Security',
			value: 'Security',
		},
		{
			name: 'Elder',
			value: 'elder',
		},
		{
			name: 'Pastor',
			value: 'pastor',
		},
		{
			name: 'Senior Leader',
			value: 'senior_leader',
		},
		{
			name: 'Staff',
			value: 'staff',
		},
	];

	sportsTypes = [
		{
			name: 'Management Team',
			value: 'Scheme Executive',
		},
	];

	fireflyTypes: Array<Type> = [
		{
			name: 'Management Team',
			value: 'Scheme Executive',
		},
		{
			name: 'Chairperson',
			value: 'Chairperson',
		},
		{
			name: 'Communications',
			value: 'Communications',
		},
		{
			name: 'Estate Manager',
			value: 'Estate Manager',
		},
		{
			name: 'Finance',
			value: 'Finance',
		},
		{
			name: 'Gardening',
			value: 'Gardening',
		},
		{
			name: 'Maintenance',
			value: 'Maintenance',
		},
		{
			name: 'Security',
			value: 'Security',
		},
		{
			name: 'Secretary',
			value: 'Secretary',
		},
		{
			name: 'Other',
			value: 'Other',
		},
	];

	envType = environment.product;
	public types = this.createManagementTypes(environment['product']);
	entityId: any;

	constructor(
		public afs: AngularFirestore,
		private entitiesService: EntitiesService,
		public usersService: UsersService,
		private auth: AuthenticationService,
		private communicationsService: CommunicationsService,
		public popOverService: PopOverService,
		public _router: Router,
		private fb: FormBuilder,
		private store: Store
	) {}

	ngOnInit() {
		this.store
			.select(selectEntityId)
			.pipe(take(1))
			.subscribe(entityId => (this.entityId = entityId));
		this.buildform();
		this.fetchManagement();
		this._router.events.subscribe(evt => {
			if (evt instanceof NavigationEnd) {
				this.fetchManagement();
				// trick the Router into believing it's last link wasn't previously loaded
				this._router.navigated = false;
				// if you need to scroll back to top, here is the right place
				window.scrollTo(0, 0);
			}
		});
	}

	createManagementTypes(product: string) {
		switch (product) {
			case 'whitfields':
				return this.fireflyTypes as Array<Type>;
			default:
				return this.plexTypes as Array<Type>;
		}
	}

	fetchManagement() {
		// FETCH MANAGEMENT USERS
		this.fetchManagementUsersSubscription = this.usersService.fetchManagementTeam().subscribe(usersData => {
			if (environment.product !== 'whitfields') {
				this.users = usersData;
			} else {
				this.users = usersData.filter((user: any) => {
					if (user.isSchemeExecutive || user.isExecutiveManagingAgent) {
						return !user.isSchemeManager;
					}
				});
			}

			this.users.forEach(user => {
				if (user.cell) {
					this.hasUsersCells = true;
				}
			});
		});

		// FETCH ENTITY USERS LIST FOR ADDING A USER TO A PROPERTY
		this.userSubscription = this.entitiesService.getUsersForEntity().subscribe(entityUsersData => {
			this.existingUsersList = entityUsersData;
			// this.existingUsersList.unshift({
			//     firstname: 'Select User',
			//     surname: '',
			//     uid: ''
			// });
		});

		// CHECK NUMBER OF ADMINS LEFT IN MANAGEMENT TEAM
		this.managementListSubscription = this.usersService.getNumberOfAdmins().subscribe(data => {
			if (data.length === 1) {
				this.canRemoveAdmin = false;
			} else {
				this.canRemoveAdmin = true;
			}
		});

		this.popOverService.showPopoverChange.subscribe(value => {
			this.showPopover = value;
		});
	}

	addSectionToggle() {
		this.showAddSection = !this.showAddSection;
		this.addExistingUserForm.reset();
	}

	showAddProfilesSection(user?: ManagementUser) {
		this.showAddProfileSection = true;
		this.user = user;
	}

	hideAddProfileSection() {
		this.showAddProfileSection = false;
	}

	showRemoveProfilesSection(user?: ManagementUser) {
		this.showRemoveProfileSection = true;
		this.user = user;
	}

	hideRemoveProfileSection() {
		this.showRemoveProfileSection = false;
	}

	addExistsingUser(form) {
		this.user = this.existingUsersList.filter((user: any) => {
			return user.uid === form.existingUserID;
		})[0];

		this.addExistingLoader = true;

		if (this.otherSelected) {
			form.existingUserType = form.existingUsereOtherType;
		}

		if (this.envType === 'whitfields') {
			form.existingUserAdmin = true;
		}

		const formData = {
			type: form.existingUserType,
			admin: form.existingUserAdmin,
			viewOnly: form.existingUserViewOnly,
		};

		this.addUserToManagementTeam(formData);
	}

	addNewUser(form) {
		this.inviteLoader = true;
		if (this.otherNewSelected) {
			form.newUserType = form.newUserOtherType;
		}

		if (this.envType === 'whitfields') {
			form.newUserAdmin = true;
		}

		const userData = {
			firstname: form.newUserFirstname,
			surname: form.newUserSurname,
			fullName: form.newUserFirstname + ' ' + form.newUserSurname,
			email: form.newUserEmail,
		};

		return this.usersService
			.addUser(userData)
			.then(userUID => {
				userData['uid'] = userUID;
				this.user = userData;

				const formData = {
					type: form.newUserType,
					admin: form.newUserAdmin,
					viewOnly: form.newUserViewOnly,
				};

				this.addUserToManagementTeam(formData);
				this.entitiesService.sendAdminAddedNotification(this.entityId, this.user);
				this.addNewUserForm.reset();
			})
			.catch(err => {
				toastr.error(err);
				this.inviteLoader = false;
			});
	}

	addUserToManagementTeam(form) {
		this.userDoc = this.afs.doc(`entities/${this.entityId}/management/users/list/${this.user.uid}`);
		let profiles = [];
		this.addingSpinner = true;
		this.showAddSection = false;

		// SET ADMIN, SCHEME EXECUTIVE AND VIEW ONLY PROFILES
		this.user.isAdmin = false;
		if (this.envType === 'whitfields') {
			this.user.isSchemeExecutive = true;
		} else {
			this.user.isSchemeExecutive = false;
		}
		this.user.isSchemeManager = false;
		this.user.isViewOnly = false;

		if (form.type === 'Scheme Executive' || form.type === null) {
			this.user.isSchemeExecutive = true;
		} else {
			profiles.push(form.type);
		}
		if (form.admin) {
			this.user.isAdmin = form.admin;
		}
		if (form.viewOnly) {
			this.user.isViewOnly = form.viewOnly;
		}
		// CHECK IF USER HAS PREVIOUS PROFILES
		this.userDoc
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(snap => {
				// IF USER HAS PREVIOUS PROFILES LOOP THROUGH THEM AND PREVENT DUPLICATE PROFILE BEING ADDED
				if (form.type !== 'Scheme Executive') {
					snap.payload.data().profiles.forEach(profile => {
						if (form.type !== profile) {
							profiles.push(profile);
						}
					});
					this.user.profiles = profiles;
				} else {
					this.user.profiles = snap.payload.data().profiles;
				}
				this.userDoc.update(this.user).then(() => {
					this.addingSpinner = false;
					this.inviteLoader = false;
					this.addExistingLoader = false;
					if (this.user.isAdmin === true) {
						this.entitiesService.sendAdminNotificationEmail(this.entityId, this.user.uid);
						if (this.envType === 'whitfields') {
							this.entitiesService.sendAdminAddedNotification(this.entityId, this.user);
						}
					}
					this.updateUserPermissions();
				});
			})
			.catch(error => {
				// ADD USER TO MANAGEMENT TEAM
				this.user.profiles = profiles;
				this.user.active = true;
				this.userDoc.set(this.user).then(() => {
					this.addingSpinner = false;
					this.inviteLoader = false;
					this.addExistingLoader = false;
					if (this.user.isAdmin === true) {
						this.entitiesService.sendAdminNotificationEmail(this.entityId, this.user.uid);
						if (this.envType === 'whitfields') {
							this.entitiesService.sendAdminAddedNotification(this.entityId, this.user);
						}
					}
					this.updateUserPermissions();
				});
			});

		this.addExistingUserForm.reset();
	}

	updateUserPermissions() {
		// BUILD PERMISSIONS
		const permissions = [];

		if (this.user.isAdmin === true) {
			permissions.push('admin');
		}
		if (this.user.isSchemeExecutive === true) {
			permissions.push('scheme_executive');
		}
		if (this.user.isViewOnly === true) {
			permissions.push('view_only');
		}

		if (this.user.profiles) {
			this.user.profiles.forEach(profile => {
				if (profile === 'Finance') {
					permissions.push('fin');
				} else {
					permissions.push(profile);
				}
			});
		}

		// UPDATE USER PERMISSIONS FOR ENTITY
		const userEntityRef = this.afs.collection('users').doc(this.user.uid).collection('entities').doc(this.entityId);

		return userEntityRef
			.set(
				{
					permissions: permissions,
				},
				{ merge: true }
			)
			.then(() => {
				if (permissions.length === 0) {
					const managementUserRef = this.afs.doc(`entities/${this.entityId}/management/users/list/${this.user.uid}`);
					managementUserRef.delete();
				}
			});
	}

	addProfile(form) {
		this.userDoc = this.afs.doc(`entities/${this.entityId}/management/users/list/${this.user.uid}`);
		let profiles = [];
		this.addingSpinner = true;
		this.showAddProfileSection = false;

		// CHECK IF USER HAS PREVIOUS PROFILES
		this.userDoc
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(snap => {
				const managementUserData = snap.payload.data();
				// IF USER HAS PREVIOUS PROFILES LOOP THROUGH THEM AND PREVENT DUPLICATE PROFILE BEING ADDED
				profiles.push(form.addProfileType);
				if (managementUserData.profiles) {
					managementUserData.profiles.forEach(profile => {
						if (form.addProfileType !== profile) {
							profiles.push(profile);
							//profiles.push(form.addProfileTypeOther);
						}
					});
				}

				this.user.profiles = profiles;
				if (form.addProfileType == 'Scheme Executive') {
					this.user.isSchemeExecutive = true;
				}
				this.userDoc.update(this.user).then(results => {
					this.addingSpinner = false;
					this.addProfileForm.reset();
					this.updateUserPermissions();
				});
			})
			.catch(error => {
				console.log('Could not update no user found', error);
			});
	}

	removeProfile(user: ManagementUser, i) {
		this.userDoc = this.afs.doc(`entities/${this.entityId}/management/users/list/${user.uid}`);

		user.profiles.splice(i, 1);

		const profiles = {
			profiles: user.profiles,
		};

		this.userDoc.update(profiles);

		if (user.profiles.length == 0) {
			this.showRemoveProfileSection = false;
		}

		this.updateUserPermissions();
	}

	setProfile(user: ManagementUser, profile: string, value: boolean) {
		const data = {};
		this.user = user;

		data[profile] = value;
		if (profile === 'isAdmin' && value === true) {
			data['isViewOnly'] = false;
			this.user.isViewOnly = false;
		}
		if (profile === 'isViewOnly' && value === true) {
			data['isAdmin'] = false;
			this.user.isAdmin = false;
		}

		this.user[profile] = value;
		const userDetails = data;

		this.userDoc = this.afs.doc(`entities/${this.entityId}/management/users/list/${user.uid}`);
		this.userDoc.update(userDetails).then(() => {
			if (profile === 'isAdmin' && value === true) {
				this.entitiesService.sendAdminNotificationEmail(this.entityId, user.uid);
			}
			if (this.envType === 'whitfields') {
				if (profile === 'isSchemeExecutive' && value === false) {
					this.entitiesService.sendAdminRemovedNotification(this.entityId, this.user);
				}
			}
			this.updateUserPermissions();
		});
	}

	emailManagment() {
		let sendData = [];
		this.communicationsService.createEmailDraft(sendData, false, true, '');
	}

	emailManager(email) {
		let sendData = [{ email: email }];
		this.communicationsService.createEmailDraft(sendData, true, false, '');
	}

	smsManagment() {
		this.generatingSms = true;
		let sendData = [];
		this.communicationsService.createSMSDraft(sendData, false, true, '');
	}

	smsManager(cell) {
		this.generatingSms = true;
		let sendData = [{ cell: cell }];
		this.communicationsService.createSMSDraft(sendData, true, false, '');
	}

	buildform() {
		this.addExistingUserForm = this.fb.group({
			existingUserID: ['', [Validators.required]],
			existingUserType: [''],
			existingUserOtherType: [''],
			existingUserAdmin: [''],
			existingUserViewOnly: [],
		});

		if (this.envType !== 'whitfields') {
			this.addExistingUserForm.get('existingUserType').setValidators(Validators.required);
		}

		this.addExistingUserForm.get('existingUserType').valueChanges.subscribe(val => {
			// console.log("TCL: ManagementTeamComponent -> buildform -> val", val);
			if (val === 'Other') {
				this.otherSelected = true;
				this.addExistingUserForm.get('existingUserOtherType').setValidators(Validators.required);
			} else {
				this.otherSelected = false;
			}
		});

		this.addNewUserForm = this.fb.group({
			newUserFirstname: ['', [Validators.required]],
			newUserSurname: ['', [Validators.required]],
			newUserEmail: ['', [Validators.required]],
			newUserType: [''],
			newUserOtherType: [''],
			newUserAdmin: [''],
			newUserViewOnly: [''],
		});

		if (this.envType !== 'whitfields') {
			this.addNewUserForm.get('newUserType').setValidators(Validators.required);
		}

		this.addNewUserForm.get('newUserType').valueChanges.subscribe(val => {
			if (val === 'Other') {
				this.otherNewSelected = true;
				this.addNewUserForm.get('newUserOtherType').setValidators(Validators.required);
			} else {
				this.otherNewSelected = false;
			}
		});

		this.addProfileForm = this.fb.group({
			addProfileType: ['', [Validators.required]],
			addProfileTypeOther: [''],
		});

		this.addProfileForm.get('addProfileType').valueChanges.subscribe(val => {
			if (val === 'Other') {
				this.addProfileOtherSelected = true;
			} else {
				this.addProfileOtherSelected = false;
			}
		});
	}

	SetDisabled(e) {
		if (e === 'admin' && this.addExistingUserForm.get('existingUserAdmin').value === true) {
			// console.log('ADMIN TRUE');

			this.addExistingUserForm.get('existingUserViewOnly').disable();
		} else {
			// console.log('ADMIN FALSE');
			this.addExistingUserForm.get('existingUserViewOnly').enable();
		}

		if (e === 'view' && this.addExistingUserForm.get('existingUserViewOnly').value === true) {
			// console.log('VIEW ONLY TRUE');

			this.addExistingUserForm.get('existingUserAdmin').disable();
		} else {
			// console.log('VIEW ONLY FALSE');

			this.addExistingUserForm.get('existingUserAdmin').enable();
		}

		if (e === 'newadmin' && this.addNewUserForm.get('newUserAdmin').value === true) {
			// console.log('NEW USER ADMIN VALUE', e);
			this.addNewUserForm.get('newUserViewOnly').disable();
		} else {
			this.addNewUserForm.get('newUserViewOnly').enable();
		}

		if (e === 'newview' && this.addNewUserForm.get('newUserViewOnly').value === true) {
			// console.log('NEW USER VIEW VALUE', e);
			this.addNewUserForm.get('newUserAdmin').disable();
		} else {
			this.addNewUserForm.get('newUserAdmin').enable();
		}
	}

	ngOnDestroy() {
		this.fetchManagementUsersSubscription.unsubscribe();
		this.userSubscription.unsubscribe();
		this.managementListSubscription.unsubscribe();
	}
}
