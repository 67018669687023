import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from './_services/script-loader.service';
import { ThemeRoutingModule } from './theme/theme-routing.module';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, USE_EMULATOR as AUTH_EMULATOR, AngularFireAuth } from '@angular/fire/auth';
import { USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { USE_EMULATOR as FUNCTIONS_EMULATOR } from '@angular/fire/functions';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppCoreModule } from './theme/pages/default/plex/app-core/app-core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EntityGuard, AuthGuard, UserEntityGuard } from './auth/_guards';
import { CanDeactivateGuard } from './theme/pages/default/plex/_services/deactivate-guard.service';
import { PopOverService } from './_services/popovers.service';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { GenerateThumbnailService } from './theme/pages/default/plex/_services/generate-thumbnail.service';
import { IgloGuard } from './auth/_guards/iglo.guard';
import { CssLoaderService } from './_services/css-loader.service';
import { PreviousRouteService } from '@services/router';

import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';

import { DragulaModule } from 'ng2-dragula';
import { CoreModule } from './_core/core.module';
import { IntroStepsService } from '@services/intro-steps.service';

import { StoreModule } from '@ngrx/store';
import { entityReducer } from './_state/entity/entity.reducer';
import { generalReducer } from './_state/general/general.reducer';
import { prsReducer } from './_state/prs/prs.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from './_shared/shared.module';
import { userEntitiesReducer } from './_state/user-entity/user-entity.reducer';

export function initializeApp(afAuth: AngularFireAuth): () => Promise<null> {
	return () => {
		return new Promise(resolve => {
			if (!environment.useEmulators) {
				return resolve(null);
			} else {
				afAuth.useEmulator(`http://${location.hostname}:9099/`).then(() => {
					resolve(null);
				});
			}
		});
	};
}

const initDeps: unknown[] = [AngularFireAuth];
if (environment.useSentry) {
	initDeps.push(Sentry.TraceService);
}

@NgModule({
	declarations: [ThemeComponent, AppComponent],
	imports: [
		CoreModule,
		LayoutModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		ThemeRoutingModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireAuthModule,
		AngularFireStorageModule,
		NgxPermissionsModule.forRoot(),
		AppCoreModule.forRoot(),
		SharedModule.forRoot(),
		Ng2TelInputModule,
		DragulaModule.forRoot(),
		AngularFireFunctionsModule,
		StoreModule.forRoot({ entity: entityReducer, general: generalReducer, prsPermissions: prsReducer, userEntities: userEntitiesReducer }),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
	],
	providers: [
		ScriptLoaderService,
		CssLoaderService,
		IntroStepsService,
		AuthGuard,
		IgloGuard,
		EntityGuard,
		CanDeactivateGuard,
		UserEntityGuard,
		PopOverService,
		GenerateThumbnailService,
		PreviousRouteService,
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: initDeps,
			useFactory: initializeApp,
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{ provide: REGION, useValue: environment.region || 'europe-west1' },
		{ provide: AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined },
		{ provide: FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9098] : undefined },
		{ provide: FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9097] : undefined },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
