import { NgModule } from '@angular/core';
import { DefaultComponent } from '../pages/default/default.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { FooterComponent } from './footer/footer.component';
import { QuickSidebarComponent } from './quick-sidebar/quick-sidebar.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { TooltipsComponent } from './tooltips/tooltips.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UnwrapTagDirective } from '../../_directives/unwrap-tag.directive';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { ManagementService } from '../pages/default/management/management.service';
import { WpmAsideNavComponent } from './aside-nav/plex-wpm-aside-nav/wpm-aside-nav.component';
import { PlexAsideNavComponent } from './aside-nav/plex-wpm-aside-nav/plex-aside-nav.component';
import { ActivitiesTabModule } from '../pages/default/management/components/activities-tab/activities-tab.module';
import { QuickSearchModule } from '../pages/default/management/components/quick-search/quick-search.module';
import { PreserveQueryParamsDirective } from '../../_directives/global-a.directive';
import { AmitiServeAsideComponent } from './aside-nav/serve-aside/amiti-serve-aside.component';
import { AmitiConnectAsideComponent } from './aside-nav/connect-aside/amiti-connect-aside.component';
import { AppCoreModule } from '../pages/default/plex/app-core/app-core.module';
import { OlympusAsideComponent } from './aside-nav/olympus-aside/olympus-aside.component.ts';
import { IntroStepsComponent } from './intro-steps/intro-steps.component';
import { SharedModule } from 'src/app/_shared/shared.module';
import { PortletCardModule } from '../../theme/pages/default/plex/components/card-container/portlet-card.module';

@NgModule({
	declarations: [
		DefaultComponent,
		HeaderNavComponent,
		AsideNavComponent,
		FooterComponent,
		QuickSidebarComponent,
		ScrollTopComponent,
		TooltipsComponent,
		IntroStepsComponent,
		UnwrapTagDirective,
		PreserveQueryParamsDirective,
		PlexAsideNavComponent,
		WpmAsideNavComponent,
		AmitiServeAsideComponent,
		AmitiConnectAsideComponent,
		OlympusAsideComponent,
	],
	exports: [
		DefaultComponent,
		HeaderNavComponent,
		AsideNavComponent,
		FooterComponent,
		QuickSidebarComponent,
		ScrollTopComponent,
		TooltipsComponent,
		IntroStepsComponent,
		RouterModule,
		PreserveQueryParamsDirective,
		SharedModule,
		PortletCardModule,
	],
	imports: [CommonModule, RouterModule, QuickSearchModule, ActivitiesTabModule, NgxPermissionsModule.forChild(), AppCoreModule, SharedModule, PortletCardModule],
	providers: [ManagementService],
})
export class LayoutModule {
	permissions;
	constructor() {}
}
