<div class="m-portlet m-portlet--mobile" *ngIf="notesTemplate === 'default'">
	<div class="m-portlet__head">
		<div class="m-portlet__head-caption">
			<div class="m-portlet__head-title">
				<h5 class="m-portlet__head-text">
					<div class="caption">
						<span class="caption-subject bold font-grey-cascade"> Internal Notes </span>
					</div>
				</h5>
			</div>
		</div>
		<div class="m-portlet__head-tools">
			<ul class="m-portlet__nav">
				<li *ngxPermissionsExcept="['view_only']" class="m-portlet__nav-item">
					<a class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill mr-2"
						(click)="print()">
						<i class="la la-print"></i>
					</a>
					<a *ngIf="!toggleNoteForm"
						class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
						ng-reflect-href="#" (click)="toggleNote()">
						<i class="la la-plus"></i>
					</a>
					<a *ngIf="toggleNoteForm"
						class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
						ng-reflect-href="#" (click)="toggleNote()">
						<i class="la la-minus"></i>
					</a>
				</li>
			</ul>
		</div>
	</div>

	<div class="m-portlet__body" *ngIf="toggleNoteForm">
		<div class="m-form__section m-form__section--first">
			<form (ngSubmit)="sendNoteDetails(noteDetails.value); noteDetails.reset()" [formGroup]="noteDetails">
				<div class="form-group m-form__group">
					<textarea class="form-control m-input" id="m_maxlength_other" placeholder="Enter content" required
						minlength="5" maxlength="500" type="text" content formControlName="content"></textarea>
					<span *ngIf="noteDetails.get('content').invalid && noteDetails.get('content').touched"
						class="m-form__help m--font-danger">
						Please enter 5 or more characters
					</span>
				</div>
				<div class="m-form__actions m-form__actions">
					<div class="row align-items-center">
						<div class="col-lg-12 m--valign-middle">
							<button *ngIf="!addingNote" class="btn btn-brand btn-sm"
								[disabled]="!noteDetails.valid">Save</button>
							<button *ngIf="addingNote"
								class="btn btn-info btn-sm m-loader m-loader--light m-loader--right"
								disabled>Saving</button>
							&nbsp;
							<button class="btn btn-default btn-sm"
								(click)="toggleNote(); noteDetails.reset()">Cancel</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>

	<div id="notes-component" class="m-portlet__body" [ngClass]="notes?.length > 0 ? '' : 'm--hide'">
		<perfect-scrollbar style="max-width: 97%; max-height: 200px; overflow: hidden"
			[perfectScrollbar]="perfectScrollbarConfig">
			<div id="mCSB_58" class="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside"
				style="max-height: none" tabindex="0">
				<div id="mCSB_58_container" class="mCSB_container" style="position: relative; top: 0px; left: 0px"
					dir="ltr">
					<div *ngIf="loadingNotes; else isLoadingSpinner">
						<div *ngIf="notes?.length > 0; else noNotes">
							<div *ngIf="!toggleNoteForm">
								<div *ngFor="let note of notes">
									<div class="m-widget3">
										<div class="m-widget3__item"
											style="border: solid thin; border-color: #e7ecf1; padding: 0%; margin-bottom: 2%">
											<div class="m-widget3__header">
												<div class="m-widget3__info">
													<span class="m-widget3__username">
														{{ note.subject ? note.subject + ': ' : '' }}
													</span>
													<br>
													<span class="m-widget3__time">
														<label>{{note.created |
															date: 'yyyy/MM/dd'}}</label> - {{ note.createdBy }}
													</span>
												</div>
												<span class="m-widget3__status m--font-info">
													{{note.type || ''}}
												</span>
											</div>
											<div class="m-widget3__body mx-3 my-1">
												<p *ngIf="note?.note">{{note?.note}}</p>
												<p class="m-widget3__text" [innerHTML]="note.content"></p>
											</div>
										</div>
									</div>
									<!-- <div class="col-md-12 overflow-wrap"
										style="border: solid thin; border-color: #e7ecf1; padding: 2% 3% 2% 3%; margin-bottom: 2%">
										<span class="uppercase font-grey-mint m--font-bolder small">{{ note.created |
											date: 'yyyy/MM/dd' }}</span>
										<span class="pull-right small" style="color: #82949a">{{ note.created_firstname
											}} {{ note.created_surname }}</span>
										<br />
										<small>{{ note.subject ? note.subject + ': ' : '' }} {{ note.content }}</small>
									</div> -->
								</div>
							</div>
						</div>
						<ng-template #noNotes>
							<div *ngIf="!toggleNoteForm" class="text-center col-md-12"
								style="border: solid thin; border-color: #e7ecf1; padding: 2% 3% 2% 3%; margin-bottom: 2%">
								<span class="uppercase font-grey-mint m--font-bolder small">No Notes Found</span>
							</div>
						</ng-template>
					</div>
				</div>
			</div>
			<div id="mCSB_58_scrollbar_vertical" [ngClass]="notes?.length > 0 ? '' : 'm--hide'"
				class="mCSB_scrollTools mCSB_58_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical"
				style="display: block">
				<div class="mCSB_draggerContainer">
					<div id="mCSB_58_dragger_vertical" class="mCSB_dragger"
						style="position: absolute; min-height: 50px; display: block; height: 90px; top: 0px">
						<div class="mCSB_dragger_bar" style="line-height: 50px"></div>
					</div>
					<div class="mCSB_draggerRail"></div>
				</div>
			</div>
		</perfect-scrollbar>
	</div>
</div>

<div *ngIf="notesTemplate === 'inline'">
	<hr />
	<div class="m-messenger m-messenger--message-arrow m-messenger--skin-light">
		<perfect-scrollbar style="max-width: 97%; max-height: 646px; overflow: hidden"
			[config]="perfectScrollbarConfig">
			<div class="m-messenger__wrapper" *ngIf="loadingNotes; else isLoadingSpinner">
				<div class="m-messenger__message" style="width: 100%" *ngFor="let note of notes"
					[ngClass]="note.createdby === currentUser.uid ? 'm-messenger__message--out' : 'm-messenger__message--in'">
					<div class="m-messenger__message-body" style="width: 100%">
						<div class="m-messenger__message-arrow"></div>
						<div class="m-messenger__message-content">
							<div class="m-messenger__message-username">
								<div class="row">
									<div class="col">{{ note.created_firstname }} {{ note.created_surname }}</div>
									<div class="col text-right">
										{{ note.created | date: 'dd MMM yyyy hh:mm' }}
									</div>
								</div>
							</div>
							<div class="m-messenger__message-text">
								{{ note.content | shorten: 200:'...' }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ps__rail-x" style="left: 0px; bottom: 0px">
				<div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
			</div>
			<div class="ps__rail-y" style="top: 0px; height: 646px; right: 4px">
				<div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 425px"></div>
			</div>
		</perfect-scrollbar>
		<div class="m-messenger__seperator"></div>
		<form (ngSubmit)="sendNoteDetails(noteDetails.value); noteDetails.reset()" [formGroup]="noteDetails">
			<div class="m-messenger__form">
				<div class="m-messenger__form-controls">
					<textarea type="text" formControlName="content" name="content" placeholder="Type here..."
						class="m-messenger__form-input"> </textarea>
				</div>
				<div class="m-messenger__form-tools">
					<button href="#"
						class="btn btn-outline-success m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill m-btn--air">
						<i class="la la-send"></i>
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="notesTemplate === 'full'">
	<div class="m-messenger m-messenger--message-arrow m-messenger--skin-light">
		<perfect-scrollbar style="max-width: 97%; max-height: 400px; overflow: hidden"
			[config]="perfectScrollbarConfig">
			<div class="m-messenger__wrapper" *ngIf="loadingNotes; else isLoadingSpinner">
				<div class="m-messenger__message" style="width: 100%" *ngFor="let note of notes"
					[ngClass]="note.createdby === currentUser.uid ? 'm-messenger__message--out' : 'm-messenger__message--in'">
					<div class="m-messenger__message-body" style="width: 100%">
						<div class="m-messenger__message-arrow"></div>
						<div class="m-messenger__message-content">
							<div class="m-messenger__message-username">
								<div class="row">
									<div class="col text-left">{{ note.created_firstname }} {{ note.created_surname }}
									</div>
									<div class="col text-right">
										{{ note.created | date: 'dd MMM yyyy hh:mm' }}
									</div>
								</div>
							</div>
							<div class="m-messenger__message-text text-left">
								<p [innerHTML]="note.content"></p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ps__rail-x" style="left: 0px; bottom: 0px">
				<div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
			</div>
			<div class="ps__rail-y" style="top: 0px; height: 646px; right: 4px">
				<div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 425px"></div>
			</div>
		</perfect-scrollbar>
		<div class="m-messenger__seperator"></div>
		<form (ngSubmit)="sendNoteDetails(noteDetails.value); noteDetails.reset()" [formGroup]="noteDetails">
			<div class="m-messenger__form">
				<div class="m-messenger__form-controls">
					<textarea type="text" formControlName="content" name="content" placeholder="Type here..."
						class="m-messenger__form-input"> </textarea>
				</div>
				<div class="m-messenger__form-tools">
					<button href="#"
						class="btn btn-outline-success m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill m-btn--air">
						<i class="la la-send"></i>
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<ng-template #isLoadingSpinner>
	<h4 class="text-center">
		<span class="m-loader m-loader--brand"></span>
	</h4>
</ng-template>